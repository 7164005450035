import { fetchWithToken } from '../helpers/fetch'
import { types } from '../types/types'

const urlByDate = 'report/byDate/'
const urlByProject = 'report/byProject/'

export const byDateUnassignedExpensesStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByDate}unassignedExpenses`, data, 'POST')
      const body = await resp.json()
      dispatch(byDateUnassignedExpensesLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const byDateExpensesProjectStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByDate}expensesProject`, data, 'POST')
      const body = await resp.json()
      if (body) {
        dispatch(byDateExpensesProjectLoadedPerMonth(body.monthlyExpenses))
        dispatch(byDateExpensesProjectLoadedPerDay(body.dailyExpenses))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const byDateExpensesAccountStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByDate}expensesAccount`, data, 'POST')
      const body = await resp.json()
      dispatch(byDateExpensesAccountLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const byDateExpensesCategoriesStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByDate}expensesCategories`, data, 'POST')
      const body = await resp.json()
      dispatch(byDateExpensesCategoriesLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const byDateExpensesAndPercentilProjectsStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByDate}expensesAndPercentilProjects`, data, 'POST')
      const body = await resp.json()
      dispatch(byDateExpensesAndPercentilProjectsLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const byDateTotalAmountsStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByDate}getTotalAmounts`, data, 'POST')
      const body = await resp.json()
      dispatch(byDateTotalAmountsLoaded(body))
      //espero 1 seg y llamo al resto de los endpoints del grafico
      setTimeout(() => {
        dispatch(byDateUnassignedExpensesStartLoading(data))
        dispatch(byDateExpensesProjectStartLoading(data))
        dispatch(byDateExpensesAccountStartLoading(data))
        dispatch(byDateExpensesCategoriesStartLoading(data))
        dispatch(byDateExpensesAndPercentilProjectsStartLoading(data))
      }, 1000)
    } catch (error) {
      console.log(error)
    }
  }
}

const byDateUnassignedExpensesLoaded = (body) => {
  return {
    type: types.byDateUnassignedExpensesLoaded,
    payload: body
  }
}

const byDateExpensesProjectLoadedPerMonth = (body) => {
  return {
    type: types.byDateExpensesProjectLoadedPerMonth,
    payload: body
  }
}
const byDateExpensesProjectLoadedPerDay = (body) => {
  return {
    type: types.byDateExpensesProjectLoadedPerDay,
    payload: body
  }
}

const byDateExpensesAccountLoaded = (body) => {
  return {
    type: types.byDateExpensesAccountLoaded,
    payload: body
  }
}

const byDateExpensesCategoriesLoaded = (body) => {
  return {
    type: types.byDateExpensesCategoriesLoaded,
    payload: body
  }
}

const byDateExpensesAndPercentilProjectsLoaded = (body) => {
  return {
    type: types.byDateExpensesAndPercentilProjectsLoaded,
    payload: body
  }
}

const byDateTotalAmountsLoaded = (body) => {
  return {
    type: types.byDateTotalAmountsLoaded,
    payload: body
  }
}

//Projects
export const byProjectTotalStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByProject}total`, data, 'POST')
      const body = await resp.json()
      if (body) dispatch(byProjectTotalLoaded(body))
      //espero 1 segs y llamo endpoints del resto graficos
      setTimeout(() => {
        dispatch(byProjectTotalPeriodsStartLoading(data))
        dispatch(byProjectAccountsStartLoading(data))
        dispatch(byProjectCategoriesStartLoading(data))
        dispatch(byProjectResourceTypesStartLoading(data))
        dispatch(byProjectInfrastructureTypeStartLoading(data))
        dispatch(byProjectAssignedStartLoading(data))
      }, 2000)
    } catch (error) {
      console.log(error)
    }
  }
}

export const byProjectTotalPeriodsStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByProject}periods`, data, 'POST')
      const body = await resp.json()
      dispatch(byProjectTotalPeriodsPerDayLoaded(body.dailyExpenses))
      dispatch(byProjectTotalPeriodsPerMonthLoaded(body.monthlyExpenses))
    } catch (error) {
      console.log(error)
    }
  }
}

export const byProjectAccountsStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByProject}accounts`, data, 'POST')
      const body = await resp.json()
      dispatch(byProjectAccountsLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const byProjectCategoriesStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByProject}categories`, data, 'POST')
      const body = await resp.json()
      dispatch(byProjectCategoriesLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const byProjectResourceTypesStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByProject}resourceTypes`, data, 'POST')
      const body = await resp.json()
      dispatch(byProjectResourceTypesLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const byProjectInfrastructureTypeStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByProject}infrastructureTypes`, data, 'POST')
      const body = await resp.json()
      dispatch(byProjectInfrastructureTypeLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const byProjectAssignedStartLoading = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`${urlByProject}assigned`, data, 'POST')
      const body = await resp.json()
      dispatch(byProjectAssignedLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

const byProjectTotalLoaded = (body) => {
  return {
    type: types.byProjectTotalLoaded,
    payload: body
  }
}

const byProjectTotalPeriodsPerDayLoaded = (body) => {
  return {
    type: types.byProjectTotalPeriodsPerDayLoaded,
    payload: body
  }
}

const byProjectTotalPeriodsPerMonthLoaded = (body) => {
  return {
    type: types.byProjectTotalPeriodsPerMonthLoaded,
    payload: body
  }
}

const byProjectAccountsLoaded = (body) => {
  return {
    type: types.byProjectAccountsLoaded,
    payload: body
  }
}

const byProjectCategoriesLoaded = (body) => {
  return {
    type: types.byProjectCategoriesLoaded,
    payload: body
  }
}

const byProjectResourceTypesLoaded = (body) => {
  return {
    type: types.byProjectResourceTypesLoaded,
    payload: body
  }
}

const byProjectInfrastructureTypeLoaded = (body) => {
  return {
    type: types.byProjectInfrastructureTypeLoaded,
    payload: body
  }
}

const byProjectAssignedLoaded = (body) => {
  return {
    type: types.byProjectAssignedLoaded,
    payload: body
  }
}

export {
  byProjectTotalLoaded,
  byProjectTotalPeriodsPerDayLoaded,
  byProjectTotalPeriodsPerMonthLoaded,
  byProjectAccountsLoaded,
  byProjectCategoriesLoaded,
  byProjectResourceTypesLoaded,
  byProjectInfrastructureTypeLoaded,
  byProjectAssignedLoaded,
  byDateUnassignedExpensesLoaded,
  byDateExpensesProjectLoadedPerMonth,
  byDateExpensesProjectLoadedPerDay,
  byDateExpensesAccountLoaded,
  byDateExpensesCategoriesLoaded,
  byDateExpensesAndPercentilProjectsLoaded,
  byDateTotalAmountsLoaded
}
