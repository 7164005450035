export enum ROUTE {
  LOGIN = '/login',
  ACCOUNTS = '/accounts',
  DASHBOARD_BY_DATE = 'dashboard/date',
  DASHBOARD_BY_PROJECTS = 'dashboard/projects',
  PROJECTS = '/projects',
  ON_PREMISE = '/onpremise',
  ON_CLOUD = '/cloud',
  USERS = '/users'
}
