import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import { AccountSteps } from '../accountModal/accountSteps'

export const AccountModal = ({ isOpenModal, toggle, account }) => {
  return (
    <Modal data-test="component-account-modal" isOpen={isOpenModal} size="lg">
      <ModalHeader toggle={toggle}></ModalHeader>
      <h2 className="text-center">{account ? 'Editar cuenta' : 'Nueva cuenta'}</h2>
      <AccountSteps toggle={toggle} account={account} isOpenModal={isOpenModal}></AccountSteps>
    </Modal>
  )
}
