export const types = {
  resourceLoaded: '[resource] Resource loaded',
  noResources: '[resource] No resources available',
  isResourceDragging: '[resource] Resource is being dragged',
  resourceUpdated: '[resource] Resource updated',
  resourcePeriodsLoaded: '[resource] Resource periods loaded',
  resourcePeriodCostLoaded: '[resource] Resource period cost loaded',
  resourceDeleted: '[resource] Resource deleted',

  selectedResource: '[resource] Single resource selected',
  datesLoaded: '[resource] Dates selected',

  onPremiseStartAddNew: '[onpremise] Start add new',
  onPremiseAddNew: '[onpremise] Add new',

  projectLoaded: '[project] Projects loaded',
  projectUpdated: '[project] Projects updated',
  projectAddNew: '[project] Project added',
  projectDeleted: '[project] Project deleted',
  noProject: '[project] No projects available',

  accountLoaded: '[account] Accounts loaded',
  accountUpdated: '[account] Account updated',
  accountAddNew: '[account] Account added',
  accountDeleted: '[account] Account deleted',
  accountChecked: '[account] Account checked',
  accountMessage: '[account] Message error',
  noAccount: '[account] No accounts available',

  categoriesLoaded: '[categories] resource categories loaded',

  authLogin: '[auth] Login',

  userLoaded: '[user] User loaded',
  userAddNew: '[user] User added',
  userUpdated: '[user] User updated',
  userDeleted: '[user] User deleted',

  rolLoaded: '[rol] Rol loaded',

  byDateUnassignedExpensesLoaded: '[dashboard] Dashboard by date unassigned expenses',
  byDateExpensesProjectLoadedPerMonth: '[dashboard] Dashboard by date expenses project per month',
  byDateExpensesProjectLoadedPerDay: '[dashboard] Dashboard by date expenses project per day',
  byDateExpensesAccountLoaded: '[dashboard] Dashboard by date expenses account',
  byDateExpensesCategoriesLoaded: '[dashboard] Dashboard by date expenses categories',
  byDateExpensesAndPercentilProjectsLoaded:
    '[dashboard] Dashboard by date expenses and percent projects',
  byDateTotalAmountsLoaded: '[dashboard] Dashboard by date total amount',

  byProjectTotalLoaded: '[dashboard] Dashboard loaded by project total',
  byProjectTotalPeriodsPerDayLoaded:
    '[dashboard] Dashboard loaded by project total periods per day',
  byProjectTotalPeriodsPerMonthLoaded:
    '[dashboard] Dashboard loaded by project total periods per monthF',
  byProjectAccountsLoaded: '[dashboard] Dashboard loaded by project accounts',
  byProjectCategoriesLoaded: '[dashboard] Dashboard loaded by project categories',
  byProjectResourceTypesLoaded: '[dashboard] Dashboard loaded by project resource types',
  byProjectInfrastructureTypeLoaded: '[dashboard] Dashboard loaded by project infraestructure type',
  byProjectAssignedLoaded: '[dashboard] Dashboard loaded by project assigned',

  currencyLoaded: '[currency] Currency loaded',

  notificationLoaded: '[notification] Notification loaded',

  addResourcesProject: '[resource] Add Resource Project',
}
