import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { Filter32 } from '@carbon/icons-react'
import '@carbon/charts/styles.css'
import { DonutsCharts } from '../DonutsCharts'
import { ExpensesNumbers } from '../ExpensesNumbers'
import { LineCharts } from './LineCharts'
import { RadarCharts } from './RadarCharts'
import { TableResult } from './TableResult'
import { FiltersMenu } from '../FiltersMenu/FiltersMenu'
import { UseByDateReports } from '../../../hooks/UseByDateReports'

const ReportByDate = ({
  getCommonData,
  getSpecificData,
  currentTab,
  setMustTrigger
}): JSX.Element => {
  const {
    accounts,
    currency,
    projects,
    categories,
    expensesProjectPerDay,
    expensesProjectPerMonth,
    unassignedExpenses,
    expensesAccount,
    expensesCategories,
    expensesAndPercentilProjects,
    totalAmounts
  } = UseByDateReports()
  const [displayedCurrency, setDisplayedCurrency] = useState<string>('')
  const tableRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const [perDay, setPerDay] = useState<boolean>(false)
  const [showFilters, setShowFilters] = useState<boolean>(true)
  const scrollToUnassignedTable = () => {
    tableRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (currency) {
      currency?.length > 1 ? setDisplayedCurrency('') : setDisplayedCurrency(currency?.currency)
    }
  }, [currency])

  return (
    <div data-test="component-main-dashboard-bydate" className="container">
      <Row>
        <Col md="10" className="border-col-right">
          <h2 className="mb-3 mt-4">Reporte por fecha</h2>
        </Col>
        <Col md="2" className="border-col-right">
          <p className="hide-filter cursor-pointer" onClick={() => setShowFilters(!showFilters)}>
            {showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
            <Filter32 />
          </p>
        </Col>
      </Row>
      <Row>
        {/* /////////////// CHARTS ///////////////////////////////////// */}
        <Col md={showFilters ? '9' : '12'} className="border-col-right pr-4">
          <ExpensesNumbers
            currentTab={currentTab}
            data={totalAmounts}
            toTable={scrollToUnassignedTable}
            currency={displayedCurrency}
            byDate={true}
          />
          <LineCharts
            data={perDay ? expensesProjectPerDay : expensesProjectPerMonth}
            setPerDay={setPerDay}
            perDay={perDay}
          />
          <DonutsCharts
            dataLeft={expensesAccount}
            dataRight={expensesCategories}
            titleLeft={'Gastos por nube / on premise en un rango de períodos'}
            titleRight={'Gastos por categorías en un rango de períodos'}
          />
          <RadarCharts data={expensesAndPercentilProjects} />
          <div ref={tableRef}>
            <TableResult data={unassignedExpenses} />
          </div>
          {/* ////////////////////////////////////////////////////////////////// */}
        </Col>
        {showFilters && (
          <Col md="3" className="filters-background pb-3">
            <FiltersMenu
              setMustTrigger={setMustTrigger}
              setDisplayedCurrency={setDisplayedCurrency}
              currentTab={currentTab}
              getCommonData={getCommonData}
              getSpecificData={getSpecificData}
              projects={projects}
              accounts={accounts}
              categories={categories}
              currencies={currency}
            />
          </Col>
        )}
      </Row>
    </div>
  )
}

export { ReportByDate }
