import {
  getAllActiveAccounts,
  getAllActiveProjects,
  getAllResourceCategories,
  getTotalAmountsByDate,
  getCurrencies,
  getUnassignedExpenses,
  getProjectExpenses,
  getExpensesAcocunt,
  getExpensesCategories,
  getExpensesAndPercentilProjects,
  getPeriodsTotal,
  getProjectAccounts,
  getProjectCategories,
  getProjectResourceTypes,
  getProjectInfrastructureTypes,
  getAssignedExpenses,
  getTotalAmountsByProject
} from '../../../services'
import * as actions from '../../../actions'

// concatenated common data requests
export const getCommonData = () => (dispatch) => {
  return Promise.allSettled([
    getCurrencies().then((data) => {
      dispatch(actions.currencyLoaded(data))
    }),
    getAllActiveProjects().then((data) => {
      dispatch(actions.projectLoaded(data))
    }),
    getAllActiveAccounts().then((data) => {
      dispatch(actions.accountLoaded(data))
    }),
    getAllResourceCategories().then((data) => {
      dispatch(actions.categoriesLoaded(data))
    })
  ])
}

export const getByDateData = (filters) => (dispatch) =>
  Promise.all([
    getTotalAmountsByDate(filters).then((data) => {
      dispatch(actions.byDateTotalAmountsLoaded(data))
    }),
    getUnassignedExpenses(filters).then((data) => {
      dispatch(actions.byDateUnassignedExpensesLoaded(data))
    }),
    getProjectExpenses(filters).then((data) => {
      dispatch(actions.byDateExpensesProjectLoadedPerDay(data.dailyExpenses))
      dispatch(actions.byDateExpensesProjectLoadedPerMonth(data.monthlyExpenses))
    }),
    getExpensesAcocunt(filters).then((data) => {
      dispatch(actions.byDateExpensesAccountLoaded(data))
    }),
    getExpensesCategories(filters).then((data) => {
      dispatch(actions.byDateExpensesCategoriesLoaded(data))
    }),
    getExpensesAndPercentilProjects(filters).then((data) => {
      dispatch(actions.byDateExpensesAndPercentilProjectsLoaded(data))
    })
  ])

export const getByProjectsData = (filters) => (dispatch) => {
  return Promise.all([
    getTotalAmountsByProject(filters).then((data) => {
      dispatch(actions.byProjectTotalLoaded(data))
    }),
    getPeriodsTotal(filters).then((data) => {
      dispatch(actions.byProjectTotalPeriodsPerDayLoaded(data.dailyExpenses))
      dispatch(actions.byProjectTotalPeriodsPerMonthLoaded(data.monthlyExpenses))
    }),
    getProjectAccounts(filters).then((data) => {
      dispatch(actions.byProjectAccountsLoaded(data))
    }),
    getProjectCategories(filters).then((data) => {
      dispatch(actions.byProjectCategoriesLoaded(data))
    }),
    getProjectResourceTypes(filters).then((data) => {
      dispatch(actions.byProjectResourceTypesLoaded(data))
    }),
    getProjectInfrastructureTypes(filters).then((data) => {
      dispatch(actions.byProjectInfrastructureTypeLoaded(data))
    }),
    getAssignedExpenses(filters).then((data) => {
      dispatch(actions.byProjectAssignedLoaded(data))
    })
  ])
}
