import React from 'react'
import { Col, Row, FormGroup, CustomInput } from 'reactstrap'
import { checkInputValidity, validations } from '../../../../utils/Validations'
import { ResourceReview } from '../resourceReview'
import { ProgressIndicator, ProgressStep, Button } from 'carbon-components-react'
import { ArrowRight32, ArrowLeft32, Warning32 } from '@carbon/icons-react'
import { RootState } from '../../../../reducers/rootReducer'
import { useSelector } from 'react-redux'
import moment from 'moment'

export const OnPremisePeriod = ({
  setForm,
  formData,
  navigation,
  statePeriodicityName,
  setStatePeriodicityName,
  title
}) => {
  const { cost_type, frecuency_number, frecuency_string, id } = formData
  const { next, previous } = navigation
  const { dates, account }: any = useSelector((store: RootState) => store.resources)

  const actualMonth = moment(new Date()).format('YYYY-MM-01')

  const updatePeriod = (event) => {
    setForm({
      target: {
        name: event.target.name,
        value: event.target.value
      }
    })

    if (event.target.value === 'NOT RECURRENT') {
      setForm({
        target: {
          name: 'frecuency_number',
          value: 0
        }
      })
      setForm({
        target: {
          name: 'frecuency_string',
          value: ''
        }
      })
    }
  }

  const updateOther = (event) => {
    setForm({
      target: {
        name: event.target.name,
        value: Number(event.target.value)
      }
    })
    if (event.target.labels[0].innerHTML === 'Anual') {
      setForm({
        target: {
          name: 'frecuency_string',
          value: 'years'
        }
      })
    } else {
      setForm({
        target: {
          name: 'frecuency_string',
          value: 'months'
        }
      })
    }
    setStatePeriodicityName(event.target.labels[0].innerHTML)
  }

  const handleSubmit = () => {
    if (id || dates.fronm == actualMonth) {
      validations(null, {
        value: cost_type,
        required: true,
        id: 'cost_type'
      })

      validations(null, {
        value: cost_type,
        required: true,
        id: 'cost_type2'
      })

      if (cost_type === 'RECURRENT') {
        validations(null, {
          value: frecuency_number,
          required: true,
          id: 'frecuency_number-1'
        })
        validations(null, {
          value: frecuency_number,
          required: true,
          id: 'frecuency_number-2'
        })
        validations(null, {
          value: frecuency_number,
          required: true,
          id: 'frecuency_number-3'
        })
        validations(null, {
          value: frecuency_number,
          required: true,
          id: 'frecuency_number-4'
        })
      } else {
        setForm({
          target: {
            name: 'frecuency_number',
            value: 0
          }
        })
      }
    } else {
      validations(null, {
        value: cost_type,
        required: true,
        id: 'cost_type'
      })
    }

    if (checkInputValidity() === 0) {
      next()
    }
  }

  const funcPrevious = () => {
    previous()
  }

  return (
    <div data-test="component-onpremise-period" className="container">
      <Row>
        <Col md="7">
          <h2 className="text-center">{title}</h2>

          <Row className="mr-2 ml-2">
            {!id && moment(dates.from).format('YYYY-MM-01') !== actualMonth ? (
              <>
                <Col md="6">
                  <FormGroup>
                    <h5>¿Qué tipo de gasto es?</h5>
                    <CustomInput
                      type="radio"
                      id="cost_type"
                      name="cost_type"
                      value="NOT RECURRENT"
                      label="Único"
                      checked={cost_type === 'NOT RECURRENT'}
                      // onChange={(event) => updatePeriod(event)}
                    />
                  </FormGroup>
                </Col>
                <p className="message black mt-5">
                  <Warning32 /> Si deseas añadir un gasto recurrente debes seleccionar el mes en
                  curso.
                </p>
              </>
            ) : (
              <>
                <Col md="6">
                  <FormGroup>
                    <h5>¿Qué tipo de gasto es?</h5>
                    <CustomInput
                      type="radio"
                      id="cost_type"
                      name="cost_type"
                      value="NOT RECURRENT"
                      label="Único"
                      checked={cost_type === 'NOT RECURRENT'}
                      onChange={(event) => updatePeriod(event)}
                    />
                    <CustomInput
                      type="radio"
                      id="cost_type2"
                      name="cost_type"
                      label="Recurrente"
                      value="RECURRENT"
                      checked={cost_type === 'RECURRENT'}
                      onChange={(event) => updatePeriod(event)}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  {cost_type === 'RECURRENT' && (
                    <FormGroup>
                      <h5>¿Qué periodicidad tiene?</h5>
                      <CustomInput
                        type="radio"
                        id="frecuency_number-1"
                        name="frecuency_number"
                        label="Mensual"
                        value={1}
                        checked={frecuency_number === 1 && frecuency_string === 'months'}
                        onChange={(event) => updateOther(event)}
                      />
                      <CustomInput
                        type="radio"
                        id="frecuency_number-2"
                        name="frecuency_number"
                        label="Trimestral"
                        value={3}
                        checked={frecuency_number === 3 && frecuency_string === 'months'}
                        onChange={(event) => updateOther(event)}
                      />
                      <CustomInput
                        type="radio"
                        id="frecuency_number-3"
                        name="frecuency_number"
                        label="Semestral"
                        value={6}
                        checked={frecuency_number === 6 && frecuency_string === 'months'}
                        onChange={(event) => updateOther(event)}
                      />
                      <CustomInput
                        type="radio"
                        id="frecuency_number-4"
                        name="frecuency_number"
                        label="Anual"
                        value={1}
                        checked={frecuency_number === 1 && frecuency_string === 'years'}
                        onChange={(event) => updateOther(event)}
                      />
                    </FormGroup>
                  )}
                </Col>
              </>
            )}
          </Row>
          <Row className="mt-3 progress-background">
            <Col md="12">
              <ProgressIndicator
                vertical={false}
                currentIndex={1}
                spaceEqually={true}
                className="mr-3 ml-3"
              >
                <ProgressStep label="Tipo y cantidad" secondaryLabel="Paso 1" />
                <ProgressStep label="Periodicidad" secondaryLabel="Paso 2" />
                <ProgressStep label="Proyecto" secondaryLabel="Paso 3" />
              </ProgressIndicator>
            </Col>
          </Row>
          <Row className="buttons">
            <Col md="6" className="p-0">
              <Button
                renderIcon={ArrowLeft32}
                iconDescription="Icon Description"
                onClick={() => funcPrevious()}
                className="button-previous"
              >
                Anterior{' '}
              </Button>
            </Col>
            <Col md="6" className="p-0">
              <Button
                renderIcon={ArrowRight32}
                iconDescription="Icon Description"
                onClick={() => handleSubmit()}
                className="button-next"
              >
                Siguiente{' '}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col className="review" md="5">
          <ResourceReview
            formData={formData}
            statePeriodicityName={statePeriodicityName}
            account={account}
          ></ResourceReview>
        </Col>
      </Row>
    </div>
  )
}
