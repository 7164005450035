import React from 'react'
import { OverflowMenu, OverflowMenuItem } from 'carbon-components-react'
import dragIcon from '../../../assets/icons/drag_flick.svg'

export const ListServiceEditDelete = ({ account, toggle, service, toggleModalDelete }) => {
  const deleteResource = (data) => {
    toggleModalDelete(data)
  }

  return (
    <div data-test="component-list-service-edit-delete" className="div-contents">
      <OverflowMenu className="mr-2" iconDescription="Ver opciones">
        <OverflowMenuItem itemText="Editar" onClick={(e) => toggle(e, service)} />
        {account.type === 'ONPREMISE' && (
          <OverflowMenuItem itemText="Eliminar" onClick={() => deleteResource(service)} />
        )}
      </OverflowMenu>
      <img src={dragIcon} alt="drag-icon" />
    </div>
  )
}

export default ListServiceEditDelete
