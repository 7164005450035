import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { TrashCan32, RequestQuote32 } from '@carbon/icons-react'
import { projectStartDeleting } from '../../actions/projects'
import { useDispatch } from 'react-redux'
import { DeleteModal } from '../global/confirmationDeleteModal/confirmationDeleteModal'
import ActiveIcon from '../../assets/img/icon-active-itspend.svg'
import InactiveIcon from '../../assets/img/icon-active-itspend-grey.svg'

export const ListProjectMain = ({
  project,
  toggle,
  activeProject,
  deactiveProject,
  setDisableExpand
}) => {
  const dispatch = useDispatch()
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  const toggleDelete = () => {
    setIsOpenModalDelete(!isOpenModalDelete)
  }

  useEffect(() => {
    if (confirmDelete) {
      dispatch(projectStartDeleting(project.id))
    }
  }, [confirmDelete])

  return (
    <div data-test="component-list-project-main">
      <Row className={`border-row ${project.is_active === 0 ? 'disabled-color' : 'enabled-color'}`}>
        <Col md="1">
          <span style={{ backgroundColor: project.color }} className="dot list"></span>
        </Col>
        <Col className="center-div" md="9">
          <p>{project.name}</p>
        </Col>
        <Col
          className="center-div icon-action"
          onPointerEnter={() => setDisableExpand(true)}
          onPointerLeave={() => setDisableExpand(false)}
          md="2"
        >
          <RequestQuote32 onClick={() => toggle(project)} />
          {project.is_active === 1 ? (
            <img src={ActiveIcon} alt="ActiveIcon" onClick={() => deactiveProject(project.id)} />
          ) : (
            <img src={InactiveIcon} alt="InactiveIcon" onClick={() => activeProject(project.id)} />
          )}
          <TrashCan32 className="trash" onClick={() => toggleDelete()} />
        </Col>
      </Row>
      <DeleteModal
        isOpenModalDelete={isOpenModalDelete}
        toggleDelete={toggleDelete}
        text={'este proyecto'}
        setConfirmDelete={setConfirmDelete}
        bodyText={
          project.quantityTotal &&
          `Este proyecto contiene ${project.quantityTotal} gastos asociados.`
        }
      ></DeleteModal>
    </div>
  )
}
