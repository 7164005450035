import React, { useContext, useEffect, useState } from 'react'
import '@carbon/charts/styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { Filter32 } from '@carbon/icons-react'
import { DonutsCharts } from '../DonutsCharts'
import { ExpensesNumbers } from '../ExpensesNumbers'
import { BarCharts } from './BarCharts'
import { UseByProjectReports } from '../../../hooks/UseByProjectReports'
import { MeterCharts } from './MeterCharts'
import { PieCharts } from './PieCharts'
import { TableResult } from './TableResult'
import { FiltersMenu } from '../FiltersMenu/FiltersMenu'
import { Spinner } from '../../../components/atoms/Navigation'
import { FilterContext } from 'context/filters/FiltersContext'

export const ReportByProject = ({ getCommonData, getSpecificData, currentTab, setMustTrigger }) => {
  const {
    accounts,
    currency,
    projects,
    categories,
    total,
    totalPeriodsPerDay,
    totalPeriodsPerMonth,
    resourceTypes,
    infrastructureType,
    assigned
  } = UseByProjectReports()
  const { state } = useContext(FilterContext)
  const [showFilters, setShowFilters] = useState<boolean>(true)
  const [perDay, setPerDay] = useState<boolean>(false)
  const tableRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const [displayedCurrency, setDisplayedCurrency] = useState<string>('')
  const scrollToUnassignedTable = () => {
    tableRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div data-test="component-main-dashboard-byproject" className="container">
      <Row>
        <Col md="10" className="border-col-right">
          <h2 className="mb-3 mt-4">Reporte por proyecto</h2>
        </Col>
        <Col md="2" className="border-col-right">
          <p className="hide-filter cursor-pointer" onClick={() => setShowFilters(!showFilters)}>
            {showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
            <Filter32 />
          </p>
        </Col>
      </Row>
      <Row>
        {/* /////////////   CHARTS   ///////////////////// */}
        <Col md={showFilters ? '9' : '12'} className="border-col-right pr-4">
          <ExpensesNumbers
            currentTab={currentTab}
            data={total}
            toTable={scrollToUnassignedTable}
            currency={displayedCurrency}
            byDate={false}
          />
          <BarCharts
            data={perDay ? totalPeriodsPerDay : totalPeriodsPerMonth}
            setPerDay={setPerDay}
            perDay={perDay}
          />
          <DonutsCharts
            dataLeft={accounts}
            dataRight={categories}
            titleLeft={'Historial de gastos segmentado por Nubes / on premise'}
            titleRight={'Historial de gastos segmentado por categorías'}
          />
          <Row className="mb-5">
            <PieCharts data={resourceTypes} />
            <MeterCharts data={infrastructureType} />
          </Row>
          <div ref={tableRef}>
            <TableResult data={assigned} />
          </div>
        </Col>
        {/* ///////////////////////////////////////////////////////////////// */}
        {showFilters && (
          <Col md="3" className="filters-background pb-3">
            <FiltersMenu
              setMustTrigger={setMustTrigger}
              setDisplayedCurrency={setDisplayedCurrency}
              currentTab={currentTab}
              projects={projects}
              accounts={accounts}
              categories={categories}
              currencies={currency}
              getCommonData={getCommonData}
              getSpecificData={getSpecificData}
            />
          </Col>
        )}
      </Row>
    </div>
  )
}
