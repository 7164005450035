import React from 'react'

const TopBar = ({ setMustTrigger, getCommonData, setDisplayedCurrency, currencyState }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
      <div
        style={{
          width: '50%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          padding: '10px'
        }}
      >
        <p style={{ fontWeight: 'bold' }}>Filtros</p>
      </div>
      <div
        style={{
          width: '70%',
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          padding: '10px'
        }}
      >
        <a
          href=""
          onClick={() => getCommonData()}
          style={{
            color: 'blue',
            textDecoration: 'none',
            paddingLeft: '10px',
            paddingRight: '10px'
          }}
        >
          Reset
        </a>
        <a
          href=""
          onClick={(e) => {
            e.preventDefault()
            setMustTrigger(true)
            setDisplayedCurrency(!currencyState ? 'USD' : currencyState)
          }}
          style={{ color: 'blue', textDecoration: 'none' }}
        >
          Aplicar
        </a>
      </div>
    </div>
  )
}

export { TopBar }
