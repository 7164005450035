import React, { useState } from 'react'
import { Modal, ModalHeader, Col, Row, FormGroup, CustomInput } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { resourceStartDeleting, resourceStartUpdatingCostType } from '../../../actions/resource'
import './deleteModal.scss'

import { RootState } from '../../../reducers/rootReducer'
import moment from 'moment'

import { ModalButtons } from '../modalButtons'

export const DeleteModal = ({ isOpenModalDelete, toggleModalDelete, data }) => {
  const dispatch = useDispatch()
  const { dates }: any = useSelector((store: RootState) => store.resources)
  const [deleteAll, setDeleteAll] = useState([] as any)

  const handleSubmit = () => {
    if (data.period === moment(new Date()).format('YYYY-MM-01')) {
      if (deleteAll) {
        dispatch(resourceStartDeleting(dates.from, dates.to, data.id, true))
      } else {
        dispatch(resourceStartUpdatingCostType(data.id))
      }
    } else {
      dispatch(resourceStartDeleting(dates.from, dates.to, data.id, false))
    }
    toggleModalDelete()
  }
  return (
    <Modal data-test="component-confirmation-modal-period" isOpen={isOpenModalDelete} size="lg">
      <ModalHeader toggle={toggleModalDelete}></ModalHeader>
      <h2 className="text-center mb-3">¿Desea eliminar este periodo?</h2>
      <div className="container">
        <Row>
          <Col className="periods">
            {data && data.period && data.period === moment(new Date()).format('YYYY-MM-01') && (
              <FormGroup>
                <CustomInput
                  type="radio"
                  id="delete"
                  name="delete"
                  value={deleteAll}
                  label="Eliminar recurso completo (incluye el histórico del mismo)"
                  checked={deleteAll}
                  onChange={() => setDeleteAll(true)}
                />

                <CustomInput
                  type="radio"
                  id="delete-1"
                  name="delete"
                  label="Eliminar periodicidad (elimina este recurso desde este mes en adelante manteniendo su historial)"
                  value={deleteAll}
                  checked={!deleteAll}
                  onChange={() => setDeleteAll(false)}
                />
              </FormGroup>
            )}
          </Col>
        </Row>
        <ModalButtons
          textPrev={'Cancelar'}
          actionPrev={() => toggleModalDelete()}
          textNext={'Aceptar'}
          actionNext={() => handleSubmit()}
          icons={true}
        />
      </div>
    </Modal>
  )
}
