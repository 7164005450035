enum ROUTE {
  LOGIN = '/login',
  REGISTER = '/register',
  PASS_RESET = '/password-recovery',
  PASS_RECOVERY = '/password-recovery',
  REPORT_BY_DATE = '/dashboard/date',
  REPORT_BY_PROJECTS = '/dashboard/projects',
  ON_CLOUD = '/cloud',
  ON_PREMISE = '/onpremise',
  PROJECTS = '/projects',
  ACCOUNTS = '/accounts',
  USERS = '/users'
}

const publicRoutes = [ROUTE.LOGIN, ROUTE.REGISTER, ROUTE.PASS_RECOVERY, ROUTE.PASS_RESET]

enum ALERT_TYPE {
  RECOVER_PASSWORD = 'RECOVER_PASSWORD',
  PROJECT_UPDATED = 'PROJECT_UPDATED',
  PROJECT_DELETED = 'PROJECT_DELETED',
  USER_DELETED = 'USER_DELETED',
  USER_UPDATED = 'USER_UPDATED',
  USER_ADDED = 'USER_ADDED',
  LOGIN_ERROR = 'LOGIN_ERROR',
  EXPENSE_ADDED = 'EXPENSE_ADDED',
  EXPENSE_DELETED = 'EXPENSE_DELETED',
  PASSWORD_UPDATED = 'PASSWORD_UPDATED',
  LOGOUT_CONFIRMATION = 'LOGOUT_CONFIRMATION',
  PROJECT_ADDED = 'PROJECT_ADDED',
  ACCOUNT_CHECK_FAILED = 'ACCOUNT_CHECK_FAILED',
  ACTIVE_ACCOUNTS_FETCH_ERROR = 'ACTIVE_ACCOUNTS_FETCH_ERROR',
  RESOURCES_UPDATED = 'RESOURCES_UPDATED'
}

enum IMAGE {
  SEND_EMAIL = 'src/assets/img/send-email.svg',
  CHECKED = 'src/assets/img/checkmark--outline.svg',
  ERROR = 'src/assets/img/warning.svg'
}

const DEFAULT_PERIOD_EXTENSION = 5

enum ACCOUNT_TYPE {
  ONPREMISE = 'onpremise',
  CLOUD = 'cloud'
}

enum COLOR {
  IT_SPEND_BLUE = '#0091ff',
  IT_SPEND_GREY = '#959bae'
}

enum CHECK_TYPE {
  RADIO = 'radio',
  BOX = ''
}

enum ROLE {
  DEFAULT = '',
  ADMIN = 'administrator',
  OPERATOR = 'operator',
  MANAGER = 'manager'
}

enum GROUP_BY {
  ACCOUNT = 'account',
  CATEGORIES = 'category'
}

export {
  ALERT_TYPE,
  IMAGE,
  DEFAULT_PERIOD_EXTENSION,
  ACCOUNT_TYPE,
  COLOR,
  ROUTE,
  CHECK_TYPE,
  ROLE,
  GROUP_BY,
  publicRoutes
}
