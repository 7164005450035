import React from 'react'
import { Col, Row } from 'reactstrap'
import { Button, InlineLoading } from 'carbon-components-react'
import { ArrowRight32, ArrowLeft32 } from '@carbon/icons-react'

export const ModalButtons = ({
  textPrev,
  actionPrev,
  textNext,
  actionNext,
  icons,
  isSubmitting = false
}) => {
  const icon = icons ? ArrowRight32 : null
  return (
    <Row
      data-test="component-modal-buttons"
      className={!icons ? 'buttons margin-without-icon' : 'buttons modal-button'}
    >
      <Col md="6" className="p-0">
        <Button
          renderIcon={icons ? ArrowLeft32 : null}
          iconDescription="Icon Description"
          onClick={() => actionPrev()}
          className="button-previous"
          disabled={isSubmitting}
        >
          {textPrev}
        </Button>
      </Col>
      <Col md="6" className="p-0">
        {isSubmitting ? (
          <InlineLoading description={'Cargando...'} status={'active'} />
        ) : (
          <Button renderIcon={icon} iconDescription="Icon Description" onClick={() => actionNext()}>
            {textNext}
          </Button>
        )}
      </Col>
    </Row>
  )
}
