import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { TextInput, Select, SelectItem } from 'carbon-components-react'
import { AWSAccountModal } from './AWSAccountModal'
import { AzureAccountModal } from './AzureAccountModal'
import { IBMAccountModal } from './IBMAccountModal'
import {
  accountStartAddNew,
  accountStartUpdating,
  accountStartChecking,
  setFile,
  getFile
} from '../../../actions/account'
import { ModalButtons } from '../../global/modalButtons'
import {
  checkInputValidity,
  validations,
  setInvalid,
  setNoneValidation
} from '../../../utils/Validations'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../reducers/rootReducer'

import { Warning32 } from '@carbon/icons-react'
import { GoogleAccountModal } from './GoogleAccountModal'

export const AccountStepOne = ({
  formValues,
  setFormValues,
  toggle,
  isEdit,
  navigation,
  isOpenModal
}) => {
  const dispatch = useDispatch()

  const { next } = navigation

  const [isMessage, setIsMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  // const [file, setFile] = useState({})
  const { message }: any = useSelector((store: RootState) => store.accounts)

  useEffect(() => {
    if (isOpenModal === true) {
      setIsMessage('')
      setIsSubmitting(false)
    }
  }, [isOpenModal])

  useEffect(() => {
    if (message && formValues.name) {
      setIsMessage(message)
      if (message === 'La cuenta ya existe con ese nombre') {
        setInvalid('name')
      } else if (message) {
        if (formValues.cloudType === 'AWS') {
          setNoneValidation('aws_access_key')
          setNoneValidation('aws_secret_key')
          setNoneValidation('aws_region')
        } else if (formValues.cloudType === 'AZURE') {
          setNoneValidation('azure_client_secret')
          setNoneValidation('azure_client_id')
          setNoneValidation('azure_tenant_id')
        } else if (formValues.cloudType === 'IBM') {
          setNoneValidation('ibm_cloud_id')
          setNoneValidation('ibm_api_key')
        } else if (formValues.cloudType === 'GOOGLE') {
          setNoneValidation('google_credential')
          setNoneValidation('google_dataset')
        }
      }
    }
  }, [message])

  const handleInputChange = (event) => {
    setFormValues({
      target: {
        name: event.target.name,
        value: event.target.value
      }
    })
  }

  const handleNext = () => {
    setIsSubmitting(true)
    const {
      name,
      accountType,
      cloudType,
      aws_access_key,
      aws_secret_key,
      aws_region,
      azure_client_secret,
      azure_client_id,
      azure_tenant_id,
      ibm_cloud_id,
      ibm_api_key,
      google_credential,
      google_dataset
    } = formValues
    validations(null, {
      value: name,
      required: true,
      id: 'name'
    })
    validations(null, {
      value: accountType,
      required: true,
      id: 'accountType'
    })
    if (accountType === 'CLOUD') {
      validations(null, {
        value: cloudType,
        required: true,
        id: 'cloudType'
      })
      if (cloudType === 'AWS') {
        validations(null, {
          value: aws_access_key,
          required: true,
          id: 'aws_access_key'
        })
        validations(null, {
          value: aws_secret_key,
          required: true,
          id: 'aws_secret_key'
        })
        validations(null, {
          value: aws_region,
          required: true,
          id: 'aws_region'
        })
      }
      if (cloudType === 'AZURE') {
        validations(null, {
          value: azure_client_secret,
          required: true,
          id: 'azure_client_secret'
        })
        validations(null, {
          value: azure_client_id,
          required: true,
          id: 'azure_client_id'
        })
        validations(null, {
          value: azure_tenant_id,
          required: true,
          id: 'azure_tenant_id'
        })
      }
      if (cloudType === 'IBM') {
        validations(null, {
          value: ibm_cloud_id,
          required: true,
          id: 'ibm_cloud_id'
        })
        validations(null, {
          value: ibm_api_key,
          required: true,
          id: 'ibm_api_key'
        })
      }
      if (cloudType === 'GOOGLE') {
        validations(null, {
          value: google_credential,
          required: true,
          id: 'google_credential'
        })
        validations(null, {
          value: google_dataset,
          required: true,
          id: 'google_dataset'
        })
      }
    }
    if (checkInputValidity() === 0) {
      const newData = {
        ...formValues,
        type: formValues.accountType === 'ONPREMISE' ? formValues.accountType : formValues.cloudType
      }
      if (accountType === 'CLOUD' && !isEdit) {
        dispatch(accountStartChecking(newData, next, setIsSubmitting, getFile()))
      } else if (isEdit) {
        dispatch(accountStartUpdating(newData, toggle,  getFile(), setIsSubmitting))
      } else {
        dispatch(accountStartAddNew(newData, toggle, setIsSubmitting))
      }
    } else {
      setIsSubmitting(false)
    }
  }

  const onFileChange = (event) => {
    setFile(event.target.files[0])
    setFormValues({
      target: {
        name: event.target.name,
        value: event.target.files[0].name
      }
    })
    setFormValues({
      target: {
        name: 'file',
        value: event.target.files[0]
      }
    })
  }

  const onFileDelete = () => {
    setFormValues({
      target: {
        name: 'google_credential',
        value: ''
      }
    })
  }

  return (
    <div data-test="component-account-step-one" className="container">
      <Row className="mt-4">
        <Col md="1"></Col>
        <Col md="10">
          <TextInput
            placeholder="Nombre"
            name="name"
            id="name"
            labelText={''}
            value={formValues.name}
            onChange={handleInputChange}
          />
          <Select
            name="accountType"
            id="accountType"
            onChange={handleInputChange}
            defaultValue={formValues.accountType}
            labelText={false}
            disabled={isEdit}
          >
            <SelectItem disabled hidden value="" text="Tipo" />
            <SelectItem value="ONPREMISE" text="On Premise" />
            <SelectItem value="CLOUD" text="On Cloud" />
          </Select>
          {formValues.accountType === 'CLOUD' && (
            <Select
              name="cloudType"
              id="cloudType"
              onChange={handleInputChange}
              defaultValue={formValues.cloudType}
              disabled={isEdit}
              labelText={false}
            >
              <SelectItem disabled hidden value="" text="Cuenta Cloud" />
              <SelectItem value="AWS" text="AWS" />
              <SelectItem value="AZURE" text="Azure" />
              <SelectItem value="IBM" text="IBM" />
              <SelectItem value="GOOGLE" text="Google" />
            </Select>
          )}
          {formValues.cloudType === 'AWS' && formValues.accountType === 'CLOUD' && (
            <AWSAccountModal formValues={formValues} handleInputChange={handleInputChange} />
          )}
          {formValues.cloudType === 'AZURE' && formValues.accountType === 'CLOUD' && (
            <AzureAccountModal
              formValues={formValues}
              handleInputChange={handleInputChange}
              isEdit={isEdit}
            />
          )}
          {formValues.cloudType === 'IBM' && formValues.accountType === 'CLOUD' && (
            <IBMAccountModal
              formValues={formValues}
              handleInputChange={handleInputChange}
              isEdit={isEdit}
            />
          )}
          {formValues.cloudType === 'GOOGLE' && formValues.accountType === 'CLOUD' && (
            <GoogleAccountModal
              formValues={formValues}
              handleInputChange={handleInputChange}
              onFileChange={onFileChange}
              onFileDelete={onFileDelete}
              isEdit={isEdit}
            />
          )}
          {isMessage && (
            <p className="message">
              <Warning32 /> {isMessage}
            </p>
          )}
        </Col>
      </Row>
      <ModalButtons
        textPrev={'Cancelar'}
        actionPrev={toggle}
        textNext={formValues.accountType !== 'ONPREMISE' && !isEdit ? 'Siguiente' : 'Guardar'}
        actionNext={() => handleNext()}
        icons={false}
        isSubmitting={isSubmitting}
      />
    </div>
  )
}
