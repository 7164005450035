import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../reducers/rootReducer'
import { setSelectedResource, resourceStartUpdating } from '../../../actions/resource'

const insideStyle = {
  opacity: 1,
  transform: 'scale(1.05)'
}

const DropTarget = (props) => {
  const dispatch = useDispatch()
  const [isOver, setIsOver] = useState(false)
  const { isResourceDragging, dates } = useSelector((store: RootState) => store.resources)

  useEffect(() => {
    if (!isResourceDragging) {
      setIsOver(false)
    }
  }, [isResourceDragging])

  const dragOver = (ev) => {
    ev.preventDefault()
    setIsOver(true)
  }

  const drop = (ev) => {
    const droppedItem = ev.dataTransfer.getData('drag-item')
    const completeData = JSON.parse(droppedItem)
    const item = props.projects.find((v) => v.id === props.id)
    if (completeData.projects && completeData.projects.length > 0) {
      const isProject = completeData.projects.find((v) => v.id === item.id)
      if (!isProject) {
        completeData.projects.push({
          id: item.id,
          name: item.name,
          company_id: item.company_id,
          quota: 0
        })
      }

      dispatch(setSelectedResource(completeData))
      props.toggle('', completeData, 'project')
    } else {
      completeData.projects = [
        {
          id: item.id,
          name: item.name,
          company_id: item.company_id,
          quota: 100
        }
      ]

      delete completeData['unassociatedPercentage']
      dispatch(resourceStartUpdating(completeData, dates.from, dates.to))
    }
  }

  const dragEnter = () => {
    setIsOver(true)
  }

  const dragLeave = () => {
    setIsOver(false)
  }

  return (
    <div
      onDragOver={dragOver}
      onDrop={drop}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      style={{
        ...(isResourceDragging && isOver ? insideStyle : {})
      }}
    >
      {props.children}
    </div>
  )
}

DropTarget.propTypes = {
  onItemDropped: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  id: PropTypes.number,
  projects: PropTypes.any,
  toggle: PropTypes.any
}

export default DropTarget
