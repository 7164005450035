import { api } from './api'

export const getResourceCategoriesByType = async (type?): Promise<any> => {
  const { data } = await api.categories.getResourceCategoriesByType(type)
  return data
}

export const getAllResourceCategories = async (): Promise<any> => {
  const { data } = await api.categories.getAllResourceCategories()
  return data
}
