import React, { useState, useEffect, useRef } from 'react'
import {
  Header,
  HeaderContainer,
  HeaderMenuButton,
  SideNav,
  SideNavItems,
  SideNavLink,
  Breadcrumb,
  BreadcrumbItem
} from 'carbon-components-react'
import {
  VirtualMachine32,
  ChartLineData32,
  Categories32,
  Account32,
  UserMultiple32,
  ReportData32,
  Notification32
} from '@carbon/icons-react'
import logo from '../../assets/img/itspend-logo-blue.svg'
import { startLogout } from '../../actions/auth'
import { notificationStartLoading, singleNotificationDeactivate } from '../../actions/notification'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../reducers/rootReducer'
import './sidebar.scss'
import './global.scss'
import userIcon from '../../assets/icons/user.svg'
import { INotification, NotificationContainer } from '../notification/NotificationContainer'
import { COLOR } from '../../utils/constants/styles'
import { Modal } from 'reactstrap'
import { getLanguageFullName, getUserData } from '../../utils/Auth'

export const Sidebar = () => {
  const ref = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const [openNotification, setOpenNotification] = useState(false)
  const [fixedOpen, setFixedOpen] = useState(false)
  const logout = () => {
    dispatch(startLogout())
  }
  const page = window.location.pathname.slice(1)
  const rol = localStorage.getItem('rol')
  const { notifications }: any = useSelector((store: RootState) => store.notifications)
  const [openUserMenu, setOpenUserMenu] = useState<boolean>(false)
  const { email, name, company, language, role } = getUserData()
  const interval = Number(process.env.REACT_APP_INTERVAL_NOTIFICATION)
  
  useEffect(() => {
    dispatch(notificationStartLoading())
    setInterval(() => {dispatch(notificationStartLoading())},interval)
  }, [notificationStartLoading])

  const deactivateNotification = (notifId) => {
    dispatch(singleNotificationDeactivate(notifId))
  }

  const handleOpen = () => {
    setOpenNotification(true)
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (fixedOpen && ref.current && !ref.current.contains(e.target)) {
        setOpenNotification(false)
        setFixedOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openNotification, fixedOpen])

  useEffect(() => {
    const checkIfClickedOutsideModal = (e) => {
      if (openUserMenu && modalRef.current && !modalRef.current.contains(e.target)) {
        setOpenUserMenu(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutsideModal)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutsideModal)
    }
  }, [openUserMenu])

  const handleClose = () => {
    setOpenNotification(false)
  }
  const handleCloseWithEsc = (e) => {
    if (e.target && e.target.value) {
    }
    return setOpenUserMenu(false)
  }
  return (
    <HeaderContainer
      data-test="component-sidebar"
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <div>
          <Header aria-label="Expandir menu">
            <HeaderMenuButton
              isCollapsible
              aria-label="Expandir menu"
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
            />
            <img className="logo" src={logo}></img>
            <SideNav aria-label="Side navigation" isRail expanded={isSideNavExpanded}>
              <SideNavItems>
                <SideNavLink
                  className={page === 'dashboard' ? 'btn-menu' : null}
                  renderIcon={ReportData32}
                  href="/dashboard/date"
                >
                  Reportes{' '}
                </SideNavLink>
                <SideNavLink
                  className={page === 'cloud' ? 'btn-menu' : null}
                  renderIcon={VirtualMachine32}
                  href="/cloud"
                >
                  Cuentas Cloud
                </SideNavLink>
                <SideNavLink
                  className={page === 'onpremise' ? 'btn-menu' : null}
                  renderIcon={ChartLineData32}
                  href="/onpremise"
                >
                  Gastos on premise
                </SideNavLink>
                {(rol === 'ROLE_administrator' || rol === 'ROLE_manager') && (
                  <>
                    <SideNavLink
                      className={page === 'projects' ? 'btn-menu' : null}
                      renderIcon={Categories32}
                      href="/projects"
                    >
                      Mis proyectos
                    </SideNavLink>

                    <SideNavLink
                      className={page === 'accounts' ? 'btn-menu' : null}
                      renderIcon={Account32}
                      href="/accounts"
                    >
                      Mis cuentas
                    </SideNavLink>
                  </>
                )}
                {rol === 'ROLE_administrator' && (
                  <SideNavLink
                    className={page === 'users' ? 'btn-menu' : null}
                    renderIcon={UserMultiple32}
                    href="/users"
                  >
                    Usuarios
                  </SideNavLink>
                )}
              </SideNavItems>
            </SideNav>

            <Breadcrumb className="breadcrum-custom" noTrailingSlash={true}>
              <BreadcrumbItem>
                <a href="/#">Home</a>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage={true} href="#">
                {page}
              </BreadcrumbItem>
            </Breadcrumb>
            <span className="logout notification">
              <Notification32
                style={{ marginTop: '1px' }}
                onMouseEnter={handleOpen}
                onClick={() => {
                  setFixedOpen(!fixedOpen)
                  setOpenNotification(false)
                }}
              />

              {notifications && notifications.length > 0 && (
                <span className="number-notif">{notifications.length}</span>
              )}
              <span
                style={{
                  height: '31px',
                  borderRadius: '50%',
                  width: '31px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: COLOR.IT_SPEND_BLUE,
                  float: 'right',
                  marginLeft: '13px'
                }}
                onClick={() => setOpenUserMenu(!openUserMenu)}
                onMouseEnter={() => setOpenNotification(false)}
              >
                <img
                  src={userIcon}
                  style={{
                    height: '20px',
                    filter: 'invert(100%) brightness(100%)',
                    paddingRight: '0.5px'
                  }}
                />
              </span>
            </span>
            <Modal
              data-test="component-user-modal"
              isOpen={openUserMenu}
              size="lg"
              style={{ width: '602px' }}
              onKeyDown={(e) => handleCloseWithEsc(e)}
              checkIfClickedOutside
            >
              <div ref={modalRef}>
                <h2
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '6%'
                  }}
                >
                  Mi perfil
                </h2>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '5%'
                    /* paddingBottom: '5%' */
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      width: '300px'
                    }}
                  >
                    <p style={{ fontWeight: 1000, width: '50%' }}>Usuario</p>
                    <p style={{ paddingLeft: '10px', width: '50%' }}>{name}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      width: '300px'
                    }}
                  >
                    <p style={{ fontWeight: 1000, width: '50%' }}>Empresa</p>
                    <p style={{ paddingLeft: '10px', width: '50%' }}>{company}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      width: '300px'
                    }}
                  >
                    <p style={{ fontWeight: 1000, width: '50%' }}>Permisos</p>
                    <p style={{ paddingLeft: '10px', width: '50%' }}>{role}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      width: '300px'
                    }}
                  >
                    <p style={{ fontWeight: 1000, width: '50%' }}>Email</p>
                    <p style={{ paddingLeft: '10px', width: '50%' }}>{email}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      width: '300px'
                    }}
                  >
                    <p style={{ fontWeight: 1000, width: '50%' }}>Idioma</p>
                    <p style={{ paddingLeft: '10px', width: '50%' }}>{language}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      bottom: 0,
                      paddingBottom: '0px',
                      paddingTop: '10px',
                      marginTop: '10px'
                    }}
                  >
                    <button
                      style={{ border: 'none', padding: '5%', width: '300px' }}
                      onClick={() => setOpenUserMenu(false)}
                    >
                      Volver
                    </button>
                    <button
                      style={{
                        background: COLOR.IT_SPEND_BLUE,
                        color: '#ffff',
                        border: 'none',
                        padding: '5%',
                        width: '300px'
                      }}
                      onClick={() => logout()}
                    >
                      Cerrar sesión
                    </button>
                  </div>
                </div>
              </div>
            </Modal>

            {(openNotification || fixedOpen) && notifications && notifications.length > 0 && (
              <div
                className="notifications"
                onMouseLeave={handleClose}
                style={{ top: -200 }}
                ref={ref}
              >
                <NotificationContainer
                  notifications={notifications}
                  deactivateNotification={deactivateNotification}
                  openNotification={openNotification}
                />
              </div>
            )}
          </Header>
        </div>
      )}
    />
  )
}
