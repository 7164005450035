import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { RadarChart, StackedBarChart } from '@carbon/charts-react'
import { sumEquals, radarChartMapper } from '../../../utils/Functions'

import '@carbon/charts/styles.css'

export const RadarCharts = ({ data }) => {
  const [reportFour, setReportFour] = useState([] as any)
  const [otherChart, setOtherChart] = useState(false)

  const [optionsReport, setOptionsReport] = useState({
    title:
      'Gastos por nube + porcentaje de asignación a proyectos de cada nube en un rango de períodos',
    radar: {
      axes: {
        angle: 'feature',
        value: 'score'
      },
      alignment: 'center' as any
    },
    data: {
      groupMapsTo: 'product'
    },
    height: '550px',
    color: {}
  })

  const [optionsOtherReport, setOptionsOtherReport] = useState({
    title:
      'Gastos por nube + porcentaje de asignación a proyectos de cada nube en un rango de períodos',
    axes: {
      left: {
        scaleType: 'labels' as any
      },
      bottom: {
        stacked: true
      }
    },
    height: '400px',
    color: {},
    tooltip: {
      customHTML: (item) => {
        return `<p> Porcentaje: ${item[0].value}% <br> Monto: $${item[0].amount} <br> Proyecto: ${item[0].group}`
      }
    }
  })

  useEffect(() => {
    const helper = {}

    const resultAccounts = data.reduce((r, o) => {
      const key = o.feature || o.key
      return sumEquals(helper, key, o, r)
    }, [])

    if (resultAccounts.length > 2) {
      const obj = data.reduce((o, key) => ({ ...o, [key.product]: key.color }), {})
      data = radarChartMapper(data)
      setOptionsReport({
        ...optionsReport,
        color: {
          scale: obj
        }
      })
      setOtherChart(false)
    } else {
      const obj = data.reduce((o, key) => ({ ...o, [key.group]: key.color }), {})

      setOptionsOtherReport({
        ...optionsOtherReport,
        color: {
          scale: obj
        }
      })
      setOtherChart(true)
    }
    setReportFour(data)
  }, [data])

  return (
    <Row data-test="component-radar-charts" className="mb-5">
      <Col md="12">
        {otherChart ? (
          <StackedBarChart data={reportFour} options={optionsOtherReport} />
        ) : (
          <RadarChart data={reportFour} options={optionsReport} />
        )}
      </Col>
    </Row>
  )
}
