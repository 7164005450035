import React, { useState } from 'react'
import { TextInput, Button } from 'carbon-components-react'
import { checkInputValidity, validations } from '../../utils/Validations'
import { useDispatch } from 'react-redux'
import { startPasswordRecovery } from '../../actions/auth'
import { ArrowLeft32 } from '@carbon/icons-react'
import { redirect } from '../../utils/Functions'

export const ForgotPassword = () => {
  const dispatch = useDispatch()

  const defaultValues = { email: '' }
  const [formValues, setFormValues] = useState(defaultValues)

  const handleChange = (e) => {
    validations(e)

    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    })
  }

  const handleLogin = (e) => {
    e.preventDefault()
    validations(null, {
      value: formValues.email,
      required: true,
      id: 'email',
      type: 'email'
    })
    if (checkInputValidity() === 0) {
      dispatch(startPasswordRecovery(formValues))
    }
  }

  const goLogin = () => {
    redirect('/login')
  }

  return (
    <div data-test="component-forgot-password">
      <ArrowLeft32 onClick={() => goLogin()} className="back-icon cursor-pointer" />
      <h1 className="text-center margin-bottom">Restablecer tu contraseña </h1>
      <TextInput
        placeholder="Correo"
        name="email"
        id="email"
        type="email"
        value={formValues.email}
        onChange={handleChange}
        labelText={''}
      />
      <h4 className="text">Te enviaremos instrucciones para restablecer tu contraseña.</h4>

      <Button onClick={(e) => handleLogin(e)}>Envíame ese email</Button>
    </div>
  )
}
