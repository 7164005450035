import React from 'react'
import PropTypes from 'prop-types'
import { isLogin } from '../utils/Functions'

import { Route, Redirect } from 'react-router-dom'

export const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={(props) =>
        isLogin() ? <Redirect to="/dashboard/date" /> : <Component {...props} {...rest} />
      }
    />
  )
}

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired
}
