export enum Color {
  blue = '#007bff',
  indigo = '#6610f2',
  purple = '#6f42c1',
  pink = '#e83e8c',
  red = '#dc3545',
  orange = '#fd7e14',
  yellow = '#ffc107',
  green = '#28a745',
  teal = '#20c997',
  cyan = '#17a2b8',
  white = '#fff',
  gray = '#6c757d',
  gray_dark = '#343a40',
  gray_light = '#959bae',
  primary = '#007bff',
  secondary = '#6c757d',
  success = '#28a745',
  info = '#17a2b8',
  warning = '#ffc107',
  danger = '#dc3545',
  light = '#f8f9fa',
  dark = '#343a40'
}

export enum Font {
  sans_serif = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  monospace = 'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace'
}
