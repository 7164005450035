import React from 'react'
import { Row, Col } from 'reactstrap'
import logo from '../../../assets/img/itspend-logo-white.svg'
import { Login } from '../../../components/auth/Login'
import { ForgotPassword } from '../../../components/auth/ForgotPassword'
import { NewPassword } from '../../../components/auth/NewPassword'
import { Register } from '../../../components/auth/Register'

import './auth.scss'

export const LoginMain = (props) => {
  return (
    <div data-test="component-login-main" className="container login-container">
      <img className="logo-login" src={logo} />
      <Row>
        <Col md="4"></Col>
        <Col md="4" className="back-login">
          {props.isLoginMain && <Login />}
          {props.isForgotPassword && <ForgotPassword />}
          {props.isNewPassword && <NewPassword />}
          {props.isRegister && <Register />}
        </Col>
        <Col md="4"></Col>
      </Row>
      <Row className="footer">
        <Col md="6">
          <p className="text-left">Copyright IT SPEND</p>
        </Col>
        <Col md="6">
          <p>Powered by POSSUMUS KOIRON</p>
        </Col>
      </Row>
    </div>
  )
}
