import { types } from '../types/types'
import { fetchWithToken, fetchWithoutToken } from '../helpers/fetch'
import Swal from 'sweetalert2'
import correct from '../assets/img/checkmark--outline.svg'

export const allUserStartLoading = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken('users?isActive=all', {}, 'GET')
      const body = await resp.json()
      dispatch(userLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const userStartLoading = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken('users', {}, 'GET')
      const body = await resp.json()
      dispatch(userLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const userStartAddNew = (formData, toggle) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken('users', formData, 'POST')
      const body = await resp.json()
      if (resp.status === 201) {
        await dispatch(userAddNew(body))
        toggle()
        Swal.fire({
          imageUrl: correct,
          title: 'Su usuario ha sido añadida con éxito!',
          customClass: {
            image: 'image-success image-size'
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const userStartUpdating = (formData, toggle) => {
  return async (dispatch) => {
    try {
      formData.role_id = Number(formData.role_id)
      const resp = await fetchWithToken(`users/${formData.id}`, formData, 'PUT')
      const body = await resp.json()
      if (resp.status === 200) {
        await dispatch(userUpdated(body))
        toggle()
        Swal.fire({
          imageUrl: correct,
          title: 'Su usuario ha sido actualizado con éxito!',
          customClass: {
            image: 'image-success image-size'
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const userStartDeleting = (id) => {
  return async (dispatch) => {
    try {
      const data: IData = {}
      if (id) Object.assign(data, { id })
      const resp = await fetchWithToken(`users/${data.id}`, {}, 'DELETE')
      const body = await resp.json()
      if (resp.status === 200) {
        await dispatch(userDeleted(body))

        Swal.fire({
          imageUrl: correct,
          title: 'Su usuario ha sido eliminado con éxito!',
          customClass: {
            image: 'image-success image-size'
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const userStartDeactivate = (id, getAll?) => {
  return async (dispatch) => {
    try {
      const data: IData = {}
      if (id) Object.assign(data, { id })
      const resp = await fetchWithToken(`users/${data.id}/deactivate`, {}, 'PUT')
      const body = await resp.json()
      if (getAll) {
        await dispatch(userUpdated(body))
      } else {
        await dispatch(userDeleted(body))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const userStartActivate = (id) => {
  return async (dispatch) => {
    try {
      const data: IData = {}
      if (id) Object.assign(data, { id })
      const resp = await fetchWithToken(`users/${data.id}/activate`, {}, 'PUT')
      const body = await resp.json()
      await dispatch(userUpdated(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const rolesStartLoading = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`roles`, {}, 'GET')
      const body = await resp.json()
      dispatch(rolLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

const userLoaded = (users) => {
  return { type: types.userLoaded, payload: users }
}

const userAddNew = (body) => ({
  type: types.userAddNew,
  payload: body
})

const userUpdated = (body) => ({ type: types.userUpdated, payload: body })

const userDeleted = (body) => ({ type: types.userDeleted, payload: body })

const rolLoaded = (body) => {
  return { type: types.rolLoaded, payload: body }
}
interface IData {
  id?: number | string
}

export { userLoaded, userAddNew, userUpdated, userDeleted, rolLoaded }
