import React, { useState, useEffect } from 'react'
import Drag from './dragAndDrop/Drag'
import { Row, Col } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../reducers/rootReducer'
import { categoriesStartLoading, resourceStartLoading } from '../../actions/resource'
import {
  Dropdown,
  ExpandableTile,
  TileAboveTheFoldContent,
  TileBelowTheFoldContent,
  TooltipDefinition
} from 'carbon-components-react'
import { ChevronUp32, ChevronDown32 } from '@carbon/icons-react'
import moment from 'moment'
import ListServiceMain from './listServiceAccountComp/listServiceMain'

export const ListServiceResource = ({ account, toggle, currentDate, toggleModalDelete }) => {
  const { resources }: any = useSelector((store: RootState) => store.resources)
  const { projects }: any = useSelector((store: RootState) => store.projects)

  const { resourceCategories, dates }: any = useSelector((store: RootState) => store.resources)
  const [stateServices, setStateServices] = useState(resources.resources)
  const [filterProject, setFilterProject] = useState()
  const [filterCategory, setFilterCategory] = useState()
  const [listCategories, setListCategories] = useState(resourceCategories ? resourceCategories : [])
  const [listProjects, setListProjects] = useState(projects)

  const [disableExpand, setDisableExpand] = useState(false)

  const showMore = (index, periods?) => {
    const servicesList = [...stateServices]
    let serv = {}
    if (periods >= 0) {
      serv = {
        ...servicesList[index].periods[periods]
      }
      serv = {
        ...serv,
        isOpen: !servicesList[index].periods[periods].isOpen
      }
      servicesList[index].periods[periods] = serv
    } else {
      serv = {
        ...servicesList[index]
      }
      serv = {
        ...serv,
        isOpen: !servicesList[index].isOpen
      }
      servicesList[index] = serv
    }
    setStateServices(servicesList)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    setStateServices(resources)
  }, [resources])

  useEffect(() => {
    if (account.id) {
      const accountType = account.type && account.type === 'ONPREMISE' ? account.type : 'CLOUD'
      dispatch(categoriesStartLoading(accountType))
    }
  }, [account.id])

  useEffect(() => {
    const categories = resourceCategories
    categories.unshift({
      name: 'Todos'
    })
    setListCategories(categories)
  }, [resourceCategories])

  useEffect(() => {
    if (projects) {
      const list = projects
      list.unshift({
        name: 'Todos'
      })
      setListProjects(list)
    }
  }, [projects])

  const onProjectChange = (e) => {
    const accountId = account.id
    let billingMonth = currentDate
    let billingMonthTo = currentDate

    if (dates.from) {
      billingMonth = dates.from
      billingMonthTo = dates.to
    }
    const projectId = e.selectedItem.id
    setFilterProject(projectId !== 'Todos' ? projectId : null)
    if (projectId === 'Todos') {
      dispatch(resourceStartLoading(accountId, billingMonth, billingMonthTo, null, filterCategory))
    } else {
      dispatch(
        resourceStartLoading(accountId, billingMonth, billingMonthTo, projectId, filterCategory)
      )
    }
  }

  const onCategoryChange = (e) => {
    const accountId = account.id
    let billingMonth = currentDate
    let billingMonthTo = currentDate

    if (dates.from) {
      billingMonth = dates.from
      billingMonthTo = dates.to
    }

    const category = e.selectedItem.id
    setFilterCategory(category !== 'Todos' ? category : null)
    if (category === 'Todos') {
      dispatch(resourceStartLoading(accountId, billingMonth, billingMonthTo, filterProject))
    } else {
      dispatch(
        resourceStartLoading(accountId, billingMonth, billingMonthTo, filterProject, category)
      )
    }
  }

  return (
    <div data-test="component-list-service-resource" className="container list list-service">
      {dates.from !== dates.to && (
        <p>
          Has seleccionado un periodo de tiempo. Si deseas editar datos o asignar gastos a un
          proyecto selecciona un mes específico.{' '}
        </p>
      )}
      <Row className="mt-4 mb-3">
        <Col md="3">
          <div>
            <Dropdown
              id="0"
              titleText="Categorías:"
              label="Todos"
              type="inline"
              items={listCategories}
              itemToString={(item) => (item ? item.name : '')}
              onChange={(e) => onCategoryChange(e)}
            />
          </div>
        </Col>
        <Col md="1"></Col>

        <Col md="4">
          <Dropdown
            id="0"
            titleText="Proyectos:"
            label="Todos"
            type="inline"
            items={listProjects}
            itemToString={(item) => (item ? item.name : '')}
            onChange={(e) => onProjectChange(e)}
          />
        </Col>
        <Col md="1">
          <p className="table-title">Monto</p>
        </Col>
        <Col md="1">
          <p className="table-title">Moneda</p>
        </Col>
        <Col md="1">
          <div>
            <TooltipDefinition
              className="table-title"
              tooltipText="Porcentaje del gasto no asociado a proyectos"
            >
              NPP
            </TooltipDefinition>
          </div>
        </Col>
        <Col md="1"></Col>
      </Row>

      {stateServices &&
        stateServices.map((service, i) => (
          <div key={`service-${i}`}>
            {service.periods ? (
              <ExpandableTile
                tileCollapsedIconText="Ver detalle"
                tileExpandedIconText="Ocultar detalle"
                disabled={disableExpand}
              >
                <TileAboveTheFoldContent>
                  <ListServiceMain
                    account={account}
                    service={service}
                    i={i}
                    showMore={showMore}
                    setDisableExpand={setDisableExpand}
                  ></ListServiceMain>
                </TileAboveTheFoldContent>
                <TileBelowTheFoldContent>
                  {service.periods &&
                    service.periods.map((period, index) => (
                      <Row className="border-row-son" key={`period-${index}`} id={index}>
                        <Col className="center-div" md="3">
                          {period.period && moment(period.period.split(' - ')[0]).format('YYYY-MM')}

                          {period.period.split(' - ')[1] &&
                            ' - ' + moment(period.period.split(' - ')[1]).format('YYYY-MM')}
                        </Col>
                        <Col md="1"></Col>

                        <Col className="center-div pl-0" md="4">
                          {period.projects &&
                            period.projects.slice(0, 1).map((project) => (
                              <React.Fragment key={`serviceProject-${project.id}`}>
                                <p
                                  className="service-project project"
                                  style={{ backgroundColor: project.color }}
                                >
                                  {project.name}
                                </p>
                                <span
                                  className="project-percentage"
                                  style={{ color: project.color, borderColor: project.color }}
                                >
                                  {project.quota}%
                                </span>
                              </React.Fragment>
                            ))}
                          {period.projects.length > 1 && (
                            <a
                              className="btn-proyects ml-3"
                              onClick={() => showMore(i, index)}
                              onPointerEnter={() => setDisableExpand(true)}
                              onPointerLeave={() => setDisableExpand(false)}
                            >
                              <span>
                                + {period.projects.length - 1}
                                {period.isOpen ? <ChevronUp32 /> : <ChevronDown32 />}
                              </span>
                            </a>
                          )}
                          <div className="part-proyects">
                            {period.projects &&
                              period.isOpen &&
                              period.projects.slice(1, period.projects.length).map((project) => (
                                <React.Fragment key={`serviceProject-${project.id}`}>
                                  <p
                                    className="service-project project"
                                    style={{ backgroundColor: project.color }}
                                  >
                                    {project.name}
                                  </p>
                                  <span
                                    className="project-percentage"
                                    style={{ color: project.color, borderColor: project.color }}
                                  >
                                    {project.quota}%
                                  </span>
                                </React.Fragment>
                              ))}
                          </div>
                        </Col>
                        <Col className="center-div" md="1">
                          <p>{parseFloat(period.amount).toFixed(2)}</p>
                        </Col>
                        <Col className="center-div" md="1">
                          <p>{period.currency}</p>
                        </Col>
                        <Col md="1">
                          {!period.unassociatedPercentage ||
                            (period.unassociatedPercentage !== 0 && (
                              <p>{period.unassociatedPercentage}%</p>
                            ))}
                        </Col>
                        <Col className="center-div icons" md="1"></Col>
                      </Row>
                    ))}
                </TileBelowTheFoldContent>
              </ExpandableTile>
            ) : dates.from === dates.to ? (
              <Drag key={`service-${i}`} dataItem={service}>
                <ListServiceMain
                  account={account}
                  service={service}
                  i={i}
                  showMore={showMore}
                  setDisableExpand={setDisableExpand}
                  toggle={toggle}
                  toggleModalDelete={toggleModalDelete}
                ></ListServiceMain>
              </Drag>
            ) : (
              <ListServiceMain
                account={account}
                service={service}
                i={i}
                showMore={showMore}
                setDisableExpand={setDisableExpand}
              ></ListServiceMain>
            )}
          </div>
        ))}
    </div>
  )
}

export default ListServiceResource
