import { types } from '../types/types'

const initialState = {
  users: [] as any,
  roles: [] as any
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.userLoaded:
      return {
        ...state,
        users: [...action.payload]
      }

    case types.userAddNew:
      return {
        ...state,
        users: [...state.users, action.payload]
      }

    case types.userUpdated:
      return {
        ...state,
        users: state.users.map((e) => (e.id === action.payload.id ? action.payload : e))
      }

    case types.userDeleted:
      return {
        ...state,
        users: state.users.filter((e) => e.id !== action.payload.id)
      }

    case types.rolLoaded:
      return {
        ...state,
        roles: [...action.payload]
      }

    default:
      return state
  }
}
