import { fetchWithoutToken } from '../helpers/fetch'
import { types } from '../types/types'
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'
import sendEmail from '../assets/img/send-email.svg'
import errorImg from '../assets/img/warning.svg'
import correct from '../assets/img/checkmark--outline.svg'
import { clearToken, setToken, clearBrowserData } from 'utils/Auth'

const cookies = new Cookies()

const redirectLogin = () => {
  setTimeout(() => {
    redirect('/login')
  }, 4000)
}

export const startLogin = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithoutToken('auth/signin', data, 'POST')
      const body = await resp.json()

      if (resp.status === 200) {
        console.log(resp)
        clearToken()
        cookies.set('access_token', body.access_token)
        cookies.set('renew_token', body.renew_token)
        localStorage.setItem('language', body.language)
        localStorage.setItem('user_name', body.username)
        localStorage.setItem('company_name', body.company_name)
        localStorage.setItem('email', body.email)
        localStorage.setItem('company_id', body.company_id)
        localStorage.setItem('rol', body.role)

        dispatch(login(body))
      }
    } catch (error) {
      return error
      // console.log(error)
    }
  }
}

export const startSignUp = (data) => {
  return async () => {
    try {
      const resp = await fetchWithoutToken('auth/signup', data, 'POST')
      // const body = await resp.json()
      if (resp.status === 200) {
        Swal.fire({
          imageUrl: correct,
          title: 'Usuario agregado correctamente.',
          text: 'Se redireccionará en un momento.',
          customClass: {
            image: 'image-success image-size'
          }
        }).then(() => {
          redirectLogin()
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const startChecking = () => {
  const email = localStorage.getItem('email')

  return async (dispatch) => {
    try {
      const resp = await fetchWithoutToken(
        'auth/check',
        { access_token: cookies.get('access_token') },
        'POST'
      )
      const status = resp.status
      const body = await resp.json()
      if (status === 401 || !body.valid) {
        dispatch(startRenew())
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const startRenew = () => {
  const email = localStorage.getItem('email')

  return async () => {
    try {
      const resp = await fetchWithoutToken(
        'auth/renew',
        {
          email: email,
          access_token: cookies.get('access_token'),
          renew_token: cookies.get('renew_token')
        },
        'POST'
      )
      const body = await resp.json()
      const status = resp.status
      if (status === 401) {
        localStorage.clear()
        cookies.remove('access_token')
        cookies.remove('renew_token')
        redirect('/login')
      } else {
        setToken(body.access_token)
        redirect('/dashboard/date')
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const redirect = (redirectUrl) => {
  window.location = redirectUrl
}

export const startLogout = () => {
  return () => {
    Swal.fire({
      imageUrl: errorImg,
      title: '¿Estás seguro que quieres salir?',
      customClass: {
        image: 'image-error'
      },
      confirmButtonText: 'Si, quiero salir',
      footer: '<a href="/dashboard/date">Volver al Inicio</a>',
      preConfirm: () => {
        clearBrowserData()
        redirect('/login')
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }
}

export const startPasswordRecovery = (data) => {
  return async () => {
    try {
      const resp = await fetchWithoutToken('auth/password-recovery', data, 'POST')
      // const body = await resp.json()
      if (resp.status === 200) {
        Swal.fire({
          imageUrl: sendEmail,
          title: 'Email enviado con éxito!',
          text: 'Revisa tu bandeja de entrada.',
          customClass: {
            image: 'image-success'
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const startPasswordReset = (url, data) => {
  return async () => {
    try {
      const resp = await fetchWithoutToken(`auth/password-reset${url}`, data, 'PUT')
      // const body = await resp.json()
      if (resp.status === 200) {
        Swal.fire({
          imageUrl: correct,
          title: 'Contraseña actualizada correctamente.',
          text: 'Se redireccionará en un momento.',
          customClass: {
            image: 'image-success image-size'
          }
        }).then(() => {
          redirectLogin()
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const login = (body) => ({
  type: types.authLogin,
  payload: body
})

export { login, redirectLogin }
