import React from 'react'
import { TextInput } from 'carbon-components-react'

export const IBMAccountModal = ({ formValues, handleInputChange, isEdit }) => {
  return (
    <div data-test="component-ibm-account-modal">
      <TextInput
        placeholder="IBM Cloud ID"
        name="ibm_cloud_id"
        id="ibm_cloud_id"
        labelText="IBM Cloud ID"
        value={formValues.ibm_cloud_id}
        onChange={handleInputChange}
      />
      <TextInput
        placeholder="IBM Api Key"
        name="ibm_api_key"
        id="ibm_api_key"
        labelText="IBM Api Key"
        value={formValues.ibm_api_key}
        onChange={handleInputChange}
        disabled={isEdit}
      />
    </div>
  )
}
