import { FILTER } from './constants'

const updateCurrencies = (payload) => {
  return {
    type: FILTER.UPDATE_CURRENCIES,
    payload: payload
  }
}
const updateCategories = (payload) => {
  return {
    type: FILTER.UPDATE_CATEGORIES,
    payload: payload
  }
}
const updateAccounts = (payload) => {
  return { type: FILTER.UPDATE_ACCOUNTS, payload: payload }
}
const updateProjects = (payload) => {
  return {
    type: FILTER.UPDATE_PROJECTS,
    payload: payload
  }
}
const updateProject = (payload) => {
  return {
    type: FILTER.UPDATE_PROJECT,
    payload: payload
  }
}
const updateFromDate = (payload) => {
  return { type: FILTER.UPDATE_FROM_DATE, payload: payload }
}
const updateToDate = (payload) => {
  return { type: FILTER.UPDATE_TO_DATE, payload: payload }
}

export {
  updateCurrencies,
  updateCategories,
  updateAccounts,
  updateProjects,
  updateFromDate,
  updateToDate,
  updateProject
}
