import moment from 'moment'
import { IDashboardFiltersState, IContextValue } from '../../common/models'

const initialState: IDashboardFiltersState = {
  from: '',
  to: '',
  projects: [],
  accounts: [],
  categories: [],
  currency: '',
  project:0
}

const initialValue: IContextValue = {
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
}

enum FILTER {
  UPDATE_CURRENCIES = 'UPDATE_CURRENCIES',
  UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS',
  UPDATE_FROM_DATE = 'UPDATE_FROM_DATE',
  UPDATE_TO_DATE = 'UPDATE_TO_DATE',
  UPDATE_PROJECTS = 'UPDATE_PROJECTS',
  UPDATE_CATEGORIES = 'UPDATE_CATEGORIES',
  UPDATE_PROJECT = 'UPDATE_PROJECT'
  
}

export { initialState, initialValue, FILTER }
