import React, { useState, useEffect } from 'react'
import { TextInput, Button } from 'carbon-components-react'
import { useDispatch } from 'react-redux'
import { validations, checkInputValidity } from '../../utils/Validations'
import { redirect } from '../../utils/Functions'
import { startSignUp } from '../../actions/auth'
import { ArrowLeft32 } from '@carbon/icons-react'

export const Register = () => {
  const dispatch = useDispatch()

  const defaultValues = {
    name: '',
    company: '',
    email: '',
    password: '',
    invitation_code: '',
    role: 'administrator'
  }
  const [formValues, setFormValues] = useState(defaultValues)
  const [message, setMessage] = useState([] as any)

  useEffect(() => {
    if (message === 0) {
      setMessage('')
    }
  }, [message])

  const goLogin = () => {
    redirect('/login')
  }

  const handleChange = (event) => {
    if (event.target.name === 'password') {
      setMessage(validations(event))
    } else {
      validations(event)
    }
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    })
  }

  const handleLogin = (e) => {
    e.preventDefault()
    validations(null, {
      value: formValues.name,
      required: true,
      id: 'name'
    })
    validations(null, {
      value: formValues.company,
      required: true,
      id: 'company'
    })
    validations(null, {
      value: formValues.email,
      required: true,
      id: 'email',
      type: 'email'
    })
    validations(null, {
      value: formValues.password,
      required: true,
      id: 'password',
      type: 'password'
    })
    validations(null, {
      value: formValues.invitation_code,
      required: true,
      id: 'invitation_code'
    })

    if (checkInputValidity() === 0) {
      dispatch(startSignUp(formValues))
    }
  }

  return (
    <div data-test="component-register">
      <ArrowLeft32 onClick={() => goLogin()} className="back-icon cursor-pointer" />

      <h1 className="text-center margin-bottom">Crear cuenta</h1>

      <TextInput
        placeholder="Nombre"
        name="name"
        id="name"
        value={formValues.name}
        onChange={handleChange}
        labelText={''}
      />
      <TextInput
        placeholder="Empresa"
        name="company"
        id="company"
        value={formValues.company}
        onChange={handleChange}
        labelText={''}
      />
      <TextInput
        placeholder="Correo"
        name="email"
        id="email"
        type="email"
        value={formValues.email}
        onChange={handleChange}
        labelText={''}
      />
      <TextInput
        required
        type="password"
        className="form-control"
        placeholder="Contraseña"
        name="password"
        id="password"
        value={formValues.password}
        onChange={handleChange}
        labelText={''}
      />
      {message && <span className="password-message">{message}</span>}
      <TextInput
        placeholder="Código de invitación"
        name="invitation_code"
        id="invitation_code"
        value={formValues.invitation_code}
        onChange={handleChange}
        labelText={''}
      />
      <Button className="mt-3" onClick={(e) => handleLogin(e)}>
        Registrarme
      </Button>
      {/* <p className="cursor-pointer text-left mt-4" onClick={() => goLogin()}>
        Iniciar sesión
      </p> */}
    </div>
  )
}
