import axios from 'axios'
import Swal from 'sweetalert2'
import errorImg from '../assets/img/warning.svg'
import Cookies from 'universal-cookie'
import { getToken, clearBrowserData } from '../utils/Auth'
import { renewToken, redirect } from './auth'

const cookies = new Cookies()
const baseUrl = process.env.REACT_APP_API_URL

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
  responseType: 'json',
  headers: {
    'Content-type': 'application/json',
    'accept-language': 'es_ES',
    'x-access-token': getToken()
  }
})

apiClient.interceptors.response.use(
  (response) => response,
  async (error: any) => {
    if (!error.response) {
      if(error.message)
      {
        return Swal.fire({
          imageUrl: errorImg,
          title: 'Ups!',
          text: error.message,
          customClass: {
            image: 'image-error'
          }
        })
      }else
      {
        return Promise.reject(error)
      }
    }
    switch (error.response.status) {
      case 500:
        return Swal.fire({
          imageUrl: errorImg,
          title: 'Ups!',
          text: 'Ocurrió un error, contactese con soporte',
          customClass: {
            image: 'image-error'
          }
        })
      case 404:
        return Swal.fire({
          imageUrl: errorImg,
          title: 'Ups!',
          text: error.response.data.message,
          customClass: {
            image: 'image-error'
          }
        })
      case 401:
        if(error.response.data.invalid_token){
          await renewToken()
          return redirect('/dashboard/date')
        }else{
          return Swal.fire({
            imageUrl: errorImg,
            title: 'Ups!',
            text: error.response.data.message,
            customClass: {
              image: 'image-error'
            },
            confirmButtonText: 'Ok',
            preConfirm: () => {
              clearBrowserData()
              redirect('/login')
            },
            allowOutsideClick: () => !Swal.isLoading()
          })
        }
      case 400 :
        return Swal.fire({
          imageUrl: errorImg,
          title: 'Ups!',
          text: error.response.data.message,
          customClass: {
            image: 'image-error'
          }
        })
      case 204:
        return  console.log('no content')
      default:
        return Swal.fire({
          imageUrl: errorImg,
          title: 'Ups!',
          text: error.response.data.message,
          customClass: {
            image: 'image-error'
          }
        })
    }
  }
)

const fecthFileWithToken = (endpoint, fileData, data, method = 'GET') => {
  const formData = new FormData()
  Object.entries(data).forEach(([key]) => {
    formData.append(key, data[key])
  })
  if (fileData.name) {
    formData.append('google_credential', fileData, data)
  }
  const url = `${baseUrl}/${endpoint}`
  const token = cookies.get('access_token')
  return fetch(url, {
    headers: {
      Accept: 'application/json',
      'x-access-token': token,
      'accept-language': 'es_ES'
    },
    method,
    body: formData
  })
}

export { apiClient, fecthFileWithToken }
