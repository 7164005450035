import React from 'react'
import { Row, Col } from 'reactstrap'
import { Currency32, ArrowDown32, VirtualMachine32 } from '@carbon/icons-react'
import '@carbon/charts/styles.css'

export const ExpensesNumbers = ({ data, toTable, currency, byDate = false, currentTab }) => {
  const setClass = (total) => {
    return total ? 'currency total' : 'currency npp-total'
  }
  const NumberTag = ({ title, amount, total = false, byProjects = false }) => (
    <Col className="numbers" md="6">
      <Row className={total || byProjects ? 'white-background' : 'white-background color-border '}>
        <Col md="2">
          {byProjects ? (
            <VirtualMachine32 className={'currency byproject'} />
          ) : (
            <Currency32 className={setClass(total)} />
          )}
        </Col>
        <Col md="6">
          <h5>{title}</h5>
          <h1>
            {amount}
            {!byProjects && <span>{!currency ? 'USD' : currency}</span>}
          </h1>
        </Col>
        <Col md="4">
          {!total && (
            <p className="see-all" onClick={toTable}>
              Ver todos <ArrowDown32 />
            </p>
          )}
        </Col>
      </Row>
    </Col>
  )

  return (
    <Row data-test="component-expenses-numbers">
      <NumberTag title={'Gasto total'} amount={data.amount} total={true} />
      {byDate ? (
        <NumberTag title={'Gastos sin asignar'} amount={data.unassigned} />
      ) : (
        <NumberTag title={'Servicios asociados'} amount={data.services} byProjects={true} />
      )}
    </Row>
  )
}
