import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import { ModalButtons } from '../modalButtons'

export const DeleteModal = ({
  isOpenModalDelete,
  toggleDelete,
  text,
  setConfirmDelete,
  bodyText = ''
}) => {
  const handleSubmit = () => {
    setConfirmDelete(true)
    toggleDelete()
  }
  return (
    <Modal data-test="component-delete-modal" isOpen={isOpenModalDelete} size="lg">
      <ModalHeader toggle={toggleDelete}></ModalHeader>
      <h2 className="text-center">¿Deseas eliminar {text}?</h2>
      <div className="container">
        <p className="text-center">{bodyText}</p>
        <ModalButtons
          textPrev={'Cancelar'}
          actionPrev={toggleDelete}
          textNext={'Aceptar'}
          actionNext={handleSubmit}
          icons={true}
        />
      </div>
    </Modal>
  )
}
