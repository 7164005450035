import { types } from '../types/types'

const initialState = {
  notifications: [] as any
}

export const notificationReducer = (state = initialState, action) => {
  if (action.type === types.notificationLoaded) {
    return {
      ...state,
      notifications: [...action.payload]
    }
  } else {
    return state
  }
}
