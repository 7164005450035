import { combineReducers } from 'redux'
import { authReducer } from './authReducer'
import { resourceReducer } from './resourceReducer'
import { projectsReducer } from './projectsReducer'
import { accountReducer } from './accountReducer'
import { userReducer } from './userReducer'
import { dashboardByDateReducer } from './dashboardByDateReducer'
import { dashboardByProjectReducer } from './dashboardByProjectReducer'
import { currencyReducer } from './currencyReducer'
import { notificationReducer } from './notificationReducer'

export const rootReducer = combineReducers({
  auth: authReducer,
  resources: resourceReducer,
  projects: projectsReducer,
  accounts: accountReducer,
  users: userReducer,
  reportsByDate: dashboardByDateReducer,
  reportsByProject: dashboardByProjectReducer,
  currency: currencyReducer,
  notifications: notificationReducer
})

export type RootState = ReturnType<typeof rootReducer>
