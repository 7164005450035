import { IUid } from '../common/models'
import { api } from './api'
import { fireAlert } from '../utils/Alert'
import { ALERT_TYPE } from '../common/constants'
import { getUserData } from '../utils/Auth'

const { companyId } = getUserData()

export const getProjectsByPeriod = async (from, to): Promise<any | unknown> => {
  const { data } = await api.projects.getProjectsByPeriod(from, to)
  return data
}

export const getAllProjects = async (): Promise<any> => {
  const { data } = await api.projects.getAllProjects()
  return 
}

export const getAllActiveProjects = async () => {
  const { data } = await api.projects.getAllActiveProjects()
  return data
}

export const addNewProject = async (formData) => {
  const { data } = await api.projects.addProject(formData)
  return data
}

export const updateProject = async (id, formData): Promise<any> => {
  const { data } = await api.projects.updateProject(id, formData)
  return data
}

export const deactivateProject = async (id): Promise<any> => {
  const { data } = await api.projects.deactivateProject(id)
  return data
}

export const deleteProject = async (id): Promise<any> => {
  const payload: IUid = {}
  if (id) Object.assign(payload, { id })
  const { data } = await api.projects.deleteProject(id)
  return data
}

export const activateProject = async (id): Promise<any> => {
  const { data } = await api.projects.activateProject(id)
  return data
}

export const updateResourceByPeriod = async (payload, from, to): Promise<any> => {
  const { data } = await api.resources.updateResourceByPeriod(from, to, payload)
  fireAlert(ALERT_TYPE.RESOURCES_UPDATED)
  return data
}
