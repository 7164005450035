import { IAccountData, IUid } from '../common/models'
import { api } from './api'

export const getAccountsByType = async (type): Promise<any> => {
  const { data } = await api.accounts.getAccountsByType(type)
  return data
}

export const getAllActiveAccounts = async (): Promise<any> => {
  const { data } = await api.accounts.getActiveAccounts()
  return data
}

export const getAllCompanyAccounts = async (): Promise<any> => {
  const { data } = await api.accounts.getAllAccounts()
  return data
}

export const addNewAcount = async (formData, toggle, setIsSubmitting?): Promise<any> => {
  const data = await api.accounts.addAcount(formData)
  if (data.status === 200) {
    toggle()
  } else setIsSubmitting(false)
}

export const updateAccount = async (formData: IAccountData, file?: File): Promise<any> => {
  const { data, status } = await api.accounts.updateAccount(formData, file)
  if (status === 200) {
  }
  return data
}

export const deleteAccount = async (id) => {
  const { data } = await api.accounts.deleteAccount(id)
  return data
}

export const deactivateAccount = async (id, getAll?) => {
  const payload: IUid = {}
  if (id) Object.assign(payload, { id })
  const data = await api.accounts.deactivateAccount(payload)
  return data
}

export const activateAccount = async (id) => {
  const payload: IUid = {}
  if (id) Object.assign(payload, { id })
  const { data } = await api.accounts.activateAccount(payload)
  return data
}

export const checkAccount = async (formData: IAccountData, file?: File) => {
  const fileParam = file ? file : null
  const { data, status } = await api.accounts.checkAccount(fileParam, formData)
  return data
}

export const getResourcesByAccountAndPeriod = async (
  accountId,
  from,
  to
): Promise<any | unknown[]> => {
  const { data } = await api.accounts.getResourcesByAccountAndPeriod(accountId, from, to)
  return data
}
