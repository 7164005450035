import React from 'react'
import { publicRoutes, ROUTE } from '../common/constants'
import Cookies from 'universal-cookie'
import { getToken } from './Auth'
const cookies = new Cookies()

export const objToOptions = (listObj = [] as any[], name = 'name', value = 'value') =>
  listObj.reduce(
    (listOption, obj) =>
      listOption.concat(
        <option key={obj[value]} value={obj[value]}>
          {obj[name]}
        </option>
      ),
    []
  )

export const isLogin = () => {
  if (localStorage.getItem('email') && cookies.get('access_token')) {
    return true
  }
  return false
}

export const redirect = (redirectUrl) => {
  window.location = redirectUrl
}

export const colors = [
  '#db9c00',
  '#e4c400',
  '#619e83',
  '#72ba9a',
  '#7a44af',
  '#6c5ce7',
  '#b89eed',
  '#1e3799',
  '#b55600',
  '#cb7e0c',
  '#6dad5b',
  '#80cb6b',
  '#1e9bd9',
  '#60a3bc',
  '#aaa69d',
  '#99a3a5',
  '#a23d2a',
  '#c54f39',
  '#517eba',
  '#6195dd'
]

export const getRandomColor = (min, max) => {
  const byteArray = new Uint8Array(1)
  window.crypto.getRandomValues(byteArray)
  const range = max - min + 1
  const max_range = 256
  if (byteArray[0] >= Math.floor(max_range / range) * range) return getRandomColor(min, max)
  return min + (byteArray[0] % range)
}

export const sumEquals = (helper, key, o, r) => {
  if (!helper[key]) {
    helper[key] = Object.assign({}, o)
    r.push(helper[key])
  } else {
    helper[key].amount += o.amount
  }

  return r
}

export const getIdsListed = (body: any): number[] => {
  const idsArray: number[] = []

  body?.forEach((item) => idsArray.push(item.id))

  return idsArray
}

export const filtersAtURL = (filters) => {
  let params = ''
  for (let i = 0; i < Object.keys(filters).length; i++) {
    if(Array.isArray(filters[Object.keys(filters)[i]])){
    	params+= splitParameter(filters[Object.keys(filters)[i]],Object.keys(filters)[i], (i === 0))
    } else {
			params+= (i !== 0?'&':'') + Object.keys(filters)[i] + '=' + filters[Object.keys(filters)[i]]
    }
  }
  return params
}

export const isPublicRoute = (route: string): boolean => {
  return publicRoutes.indexOf(route as ROUTE) !== -1
}

export const splitParameter = (filter, param, first ) => {
  let queryParam = ''
  for (let i = 0; i < filter.length; i++) {
    queryParam+= (first && i === 0 ? '':'&') + param + '=' + filter[i]
  }
  return queryParam
}
export const radarChartMapper = (response) => {
  let result : { feature: string, product: string, amount:number,currency:string,color:string,score:number } [] = []
  const features = response.map((i) => i.feature);
  const labelsFeature = features.filter((i, idx) => {
    return features.indexOf(i) === idx;
  });
  const products = response.map((i) => i.product);
  const productsLabels = products.filter((i, idx) => {
    return products.indexOf(i) === idx;
  });
  
  productsLabels.map((i) => {
    let colores = response.filter(j => j.product === i).map(x => x.color)[0]
   return labelsFeature.map((r) => {
     const amount =response.filter(j => j.feature === r && j.product === i).map(x => x.amount)[0]
     const currency =response.filter(j => j.feature === r && j.product === i).map(x => x.currency)[0]
     const score =response.filter(j => j.feature === r && j.product === i).map(x => x.score)[0]
     const item = {
      feature: r,
      product: i,
      amount: amount ? amount: 0, 
      currency: currency ? currency: 'USD',
      color: colores,
      score: score ? score: 0
    }
    result.push(item)
   })
  })
  return result
}


export const loggedIn = () => getToken() !== '' && getToken() !== undefined
