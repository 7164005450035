import React from 'react'
import { Row, Col, Table } from 'reactstrap'

import '@carbon/charts/styles.css'
import moment from 'moment'

export const TableResult = ({ data }) => {
  return (
    <div data-test="component-table-result">
      <Row className="white-background pr-0 pl-0">
        <Col md="12">
          <h4 className="table-title">Servicios asociados</h4>
          <Table striped>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Cuenta</th>
                <th>Período</th>
                <th>Porcentaje de apropiación</th>
                <th>Monto</th>
                <th>Moneda</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => (
                <tr key={i}>
                  <td>{item.name}</td>
                  <td>{item.account}</td>
                  <td>{moment(item.period).format('MM/YY')}</td>
                  <td>{item.quota}</td>
                  <td>{Number(item.amount.toFixed(2))}</td>
                  <td>{item.currency}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  )
}
