import { types } from '../types/types'
import { fetchWithToken } from '../helpers/fetch'
import Swal from 'sweetalert2'
import { projectStartLoading } from './projects'
import correct from '../assets/img/checkmark--outline.svg'
import { getResourcesByAccountAndPeriod,getProjectsByPeriod } from 'services'

export const resourceStartLoading = (accountId, from, to, projectId?, categoryId?) => (dispatch) => {
  return Promise.allSettled([
    getResourcesByAccountAndPeriod(accountId, from, to)
    .then((body) => {
      dispatch(resourceLoaded(body))
      dispatch(datesLoaded(from, to))
    }),
    getProjectsByPeriod(from, to).then((body) => {
      dispatch(addResourcesProject(body))
    })
  ]).catch(err=> console.log(err))
}

export const resourceStartUpdating = (data, from, to) => {
  return async (dispatch) => {
    data.from = from
    data.to = to
    const url = `resources/${data.id}`
    try {
      const resp = await fetchWithToken(url, data, 'PUT')
      const body = await resp.json()
      if (resp.status === 200) {
        dispatch(resourceUpdated(body))
        dispatch(projectStartLoading(from, to))
        if (body.category) {
          dispatch(categoriesStartLoading(body.category.type))
        }
        Swal.fire({
          imageUrl: correct,
          title: 'Su gasto ha sido actualizado con éxito!',
          customClass: {
            image: 'image-success image-size'
          }
        })
      }
    } catch (error) {
      console.log(error)
      Swal.fire('Error', 'Error al cargar los datos', 'error')
    }
  }
}

export const categoriesStartLoading = (type?) => {
  return async (dispatch) => {
    const url = `categories?type=${type}`
    try {
      const resp = await fetchWithToken(url, {}, 'GET')
      const body = await resp.json()
      dispatch(categoriesLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const allCategoriesStartLoading = () => {
  return async (dispatch) => {
    const url = `categories`
    try {
      const resp = await fetchWithToken(url, {}, 'GET')
      const body = await resp.json()
      dispatch(categoriesLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const resourcePeriodCost = (periodCostResourceId) => {
  return async (dispatch) => {
    const url = `resources/periodCostResource/${periodCostResourceId}`
    try {
      const resp = await fetchWithToken(url, null, 'GET')
      const body = await resp.json()
      dispatch(resourcePeriodCostLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const resourceStartDeleting = (from, to, id, deleteAll) => {
  return async (dispatch) => {
    const url = `resources/${id}`
    try {
      const data: IData = {}
      if (id) Object.assign(data, { id })
      if (from) Object.assign(data, { from })
      if (to) Object.assign(data, { to })
      if (deleteAll) Object.assign(data, { deleteAll })
      const resp = await fetchWithToken(url, data, 'DELETE')
      const body = await resp.json()
      if (resp.status === 200) {
        await dispatch(resourceDeleted(data))
        await dispatch(projectStartLoading(from, to))

        Swal.fire({
          imageUrl: correct,
          title: 'Su gasto ha sido eliminado con éxito!',
          customClass: {
            image: 'image-success image-size'
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const resourceStartUpdatingCostType = (id) => {
  return async () => {
    const url = `resources/${id}`
    try {
      const resp = await fetchWithToken(url, {}, 'PUT')
      if (resp.status === 200) {
        Swal.fire({
          imageUrl: correct,
          title: 'Su gasto ha sido eliminado con éxito!',
          customClass: {
            image: 'image-success image-size'
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const resourceLoaded = (resources) => {
  return { type: types.resourceLoaded, payload: resources }
}

const datesLoaded = (from, to) => {
  return { type: types.datesLoaded, payload: { from, to } }
}

const resourceUpdated = (resource) => {
  return { type: types.resourceUpdated, payload: resource }
}

const resourcePeriodCostLoaded = (resource) => {
  return { type: types.resourcePeriodCostLoaded, payload: resource }
}

const categoriesLoaded = (categories) => ({
  type: types.categoriesLoaded,
  payload: categories
})

const setSelectedResource = (selectedResource) => ({
  type: types.selectedResource,
  selectedResource
})

const setResourceDragging = (isResourceDragging) => ({
  type: types.isResourceDragging,
  isResourceDragging
})

const resourceDeleted = (resource) => {
  return { type: types.resourceDeleted, payload: resource }
}

const addResourcesProject = (payload) => ({
  type: types.addResourcesProject,
  payload:payload
})

interface IData {
  projectId?: number | string
  category?: string
}

export {
  resourceLoaded,
  datesLoaded,
  resourceUpdated,
  resourcePeriodCostLoaded,
  categoriesLoaded,
  setSelectedResource,
  setResourceDragging,
  resourceDeleted,
  addResourcesProject
}
