import React from 'react'
import { TextInput, FileUploader } from 'carbon-components-react'

export const GoogleAccountModal = ({
  formValues,
  handleInputChange,
  onFileChange,
  onFileDelete,
  isEdit
}) => {
  return (
    <div data-test="component-google-account-modal">
      <TextInput
        placeholder="Google Dataset"
        name="google_dataset"
        id="google_dataset"
        labelText="Google Dataset"
        value={formValues.google_dataset}
        onChange={handleInputChange}
        disabled={isEdit}
      />
      <FileUploader
        buttonLabel="Agregar archivo"
        labelDescription="Google Credential"
        onChange={onFileChange}
        value={formValues.google_credential}
        name="google_credential"
        id="google_credential"
        accept={['.json']}
        filenameStatus="edit"
        className="file-upload"
        onDelete={onFileDelete}
      />
    </div>
  )
}
