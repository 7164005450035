import { IUid, IUserPayload } from '../common/models'
import { api } from './api'
import { getUserData } from '../utils/Auth'
import { fireAlert } from '../utils/Alert'
import { ALERT_TYPE } from '../common/constants'

const { companyId } = getUserData()

const getAllUser = async (): Promise<any> => {
  const { data } = await api.user.getAllUsers(companyId)
  return data
}

const getUser = async () => {
  const { data } = await api.user.getUser(companyId)
  return data
}

/*  const userStartAddNew = (formData, toggle) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithoutToken('auth/signup', formData, 'POST')
      const body = await resp.json()
      if (resp.status === 200) {
        await dispatch(addUser(body))
        toggle()
        Swal.fire({
          imageUrl: correct,
          title: 'Su usuario ha sido añadida con éxito!',
          customClass: {
            image: 'image-success image-size'
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
} */

const updateUser = async (formData): Promise<any> => {
  const { data, status } = await api.user.updateUser(formData)
  if (status === 200) {
    fireAlert(ALERT_TYPE.USER_UPDATED)
  }
  return data
}

const deleteUser = async (id): Promise<any> => {
  const payload: IUid = {}
  if (id) Object.assign(payload, { id })
  const { data, status } = await api.user.deleteUser(payload)
  if (status === 200) {
    fireAlert(ALERT_TYPE.USER_DELETED)
  }
  return data
}

const deactivateUser = async (payload: IUserPayload): Promise<any> => {
  const { data } = await api.user.deleteUser(payload)
  return data
}

const activateUser = async (id) => {
  const payload: IUid = {}
  if (id) Object.assign(payload, { id })
  const { data } = await api.user.activateUser(payload)
  return data
}

const getRoles = async (): Promise<any> => {
  const { data } = await api.user.getRoles()
  return data
}

export { getUser, getAllUser, updateUser, deleteUser, deactivateUser, activateUser, getRoles }
