import React, { useContext } from 'react'
import { FilterContext } from 'context/filters/FiltersContext'
import { DropdownDatePicker } from './DropdownDatepicker'
import { DropdownSection } from './DropdownSection'
import { TopBar } from './TopBar'
import {
  updateCurrencies,
  updateCategories,
  updateAccounts,
  updateProjects,
  updateFromDate,
  updateToDate,
  updateProject
} from 'context/filters/actions'

const FiltersMenu = (
  {
    projects,
    accounts,
    categories,
    currencies,
    getCommonData,
    getSpecificData,
    currentTab,
    setDisplayedCurrency,
    setMustTrigger,
  } /* : IFiltersMenuProps */
) => {
  const { state, dispatch } = useContext(FilterContext)
  const currencyState = state.currency
  
  return (
    <div
      style={{
        background: '#ffff',
        width: '100%',
        paddingLeft: '0.5%',
        paddingRight: '2%',
        paddingBottom: '20px',
        marginBottom: '0.5px'
      }}
    >
      <TopBar
        setMustTrigger={setMustTrigger}
        getCommonData={getCommonData}
        setDisplayedCurrency={setDisplayedCurrency}
        currencyState={currencyState}
      />
      {/* DATEPICKER */}
      <DropdownDatePicker
        title={'Rango de tiempo'}
        subTitle={''}
        dispatch={dispatch}
        updateEnd={updateToDate}
        updateStart={updateFromDate}
      />
      {/* CURRENCY RADIO-BUTTONS */}
      <DropdownSection
        title={'Seleccionar moneda'}
        section={'currency'}
        subTitle={'La opción "Todas" convertirá los gastos a USD según la cotización oficial'}
        itemList={currencies}
        checkType="radio"
        dispatch={dispatch}
        action={updateCurrencies}
        withCheckAll
        currentTab={currentTab}
      />
      {/* PROJECTS RADIO-BUTTONS */}
      {currentTab === 1 ? (
        <DropdownSection
          title={'Seleccionar proyectos'}
          section={'projects'}
          subTitle={''}
          itemList={projects
            }
          checkType={'radio'}
          dispatch={dispatch}
          action={updateProject}
          withCheckAll={false}
          currentTab={currentTab}
        />
      ) : (
        <DropdownSection
          title={'Seleccionar proyectos'}
          section={'projects'}
          subTitle={''}
          itemList={projects}
          checkType={''}
          dispatch={dispatch}
          action={updateProjects}
          withCheckAll
          currentTab={currentTab}
        />
      )}
      {/* <RenderProjectOptions /> */}
      {/*ACCOUNT CHECK-BOXES */}
      <DropdownSection
        title={'Nubes / On premise'}
        section={'accounts'}
        subTitle={''}
        itemList={accounts}
        checkType=""
        dispatch={dispatch}
        action={updateAccounts}
        withCheckAll
        currentTab={currentTab}
      />
      {/*CATEGORIES CHECK-BOXES */}
      <DropdownSection
        title={'Categorías'}
        section={'categories'}
        subTitle={''}
        itemList={categories}
        checkType=""
        dispatch={dispatch}
        action={updateCategories}
        withCheckAll
        currentTab={currentTab}
      />
    </div>
  )
}

export { FiltersMenu }
