import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/rootReducer'
import { Navbar, NavbarText, Row, Col } from 'reactstrap'
import { Button, Dropdown } from 'carbon-components-react'
import { Add16 } from '@carbon/icons-react'
import { AccountDatePicker } from './datePicker/AccountDatePicker'
import { redirect } from '../../utils/Functions'

export const HeaderResource = ({ account, changeValue, toggle, displayedSelected }) => {
  const { accounts }: any = useSelector((store: RootState) => store.accounts)
  const { dates }: any = useSelector((store: RootState) => store.resources)

  const [accountsState, setAccountsState] = useState(accounts)
  const [filterProject] = useState()
  const [filterCategory] = useState()
  const rol = localStorage.getItem('rol')

  useEffect(() => {
    setAccountsState(accounts)
  }, [accounts])

  const goAccounts = () => {
    redirect('/accounts')
  }

  useEffect(() => {
    if (account) console.log(account)
  }, [account])
  const isOnPremise = () : boolean => {
    return window.location.pathname === "/onpremise"
  }
  const buttonName = account && isOnPremise() ? 'Añadir gasto' : 'Añadir cuenta'
  return (
    <div data-test="component-header-resource">
      <Navbar expand="md">
        <Row>
          <Col className="center-div" md="8">
            <NavbarText>
              <h2>
                {displayedSelected}
                {account.number && account.type === 'ONPREMISE' && `${'| ' + account.number}`}
              </h2>
            </NavbarText>
            <Dropdown
              className="only-arrow"
              id="0"
              label=""
              type="inline"
              items={accountsState}
              itemToString={(item) => `${item ? item.name + ' | ' + item.type : ''}`}
              onChange={(e) => changeValue(e)}
            />
          </Col>
          <Col className="center-div pl-1" md="2">
            {rol !== 'ROLE_operator' && dates.from === dates.to ? (
              <Button
                renderIcon={Add16}
                iconDescription="Icon Description"
                onClick={() => (account && isOnPremise() ? toggle() : goAccounts())}
                className="add-btn"
              >
                {buttonName}
              </Button>
            ) : (
              account.type == 'ONPREMISE' && (
                <Button
                  renderIcon={Add16}
                  iconDescription="Icon Description"
                  onClick={() => toggle()}
                  className="add-btn"
                >
                  Añadir gasto
                </Button>
              )
            )}
          </Col>
          <Col className="center-div pl-1" md="2">
            <AccountDatePicker
              accountId={account.id}
              filterCategory={filterCategory}
              filterProject={filterProject}
            />
          </Col>
        </Row>
      </Navbar>
    </div>
  )
}
