import React, { useContext, useState } from 'react'
import { TextInput, Button } from 'carbon-components-react'
import { Col, Row } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { redirect } from '../../utils/Functions'
import { checkInputValidity, validations } from '../../utils/Validations'
import { authenticateUser } from 'modules/login/services/login'

export const Login = () => {
  const dispatch = useDispatch()
  const defaultValues = { email: '', password: '' }
  const [formValues, setFormValues] = useState(defaultValues)

  const handleChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value
    })
  }

  const handleLogin = (e) => {
    e.preventDefault()
    validations(null, {
      value: formValues.email,
      required: true,
      id: 'email',
      type: 'email'
    })
    validations(null, {
      value: formValues.password,
      required: true,
      id: 'passwordLogin'
    })
    if (checkInputValidity() === 0) {
      authenticateUser(formValues)
    }
  }

  const goPasswordRecovery = () => {
    redirect('/password-recovery')
  }

  const goRegister = () => {
    redirect('/register')
  }

  return (
    <div data-test="component-login">
      <h1 className="text-center">Iniciar sesión </h1>
      <h4 className="text">A continuación, completa con tus datos.</h4>
      <TextInput
        placeholder="Correo"
        name="email"
        id="email"
        type="email"
        value={formValues.email}
        onChange={handleChange}
        labelText={''}
      />
      <TextInput
        required
        type="password"
        className="form-control"
        placeholder="Contraseña"
        name="password"
        id="passwordLogin"
        value={formValues.password}
        onChange={handleChange}
        labelText={''}
      />
      <Button className="mt-3" onClick={(e) => handleLogin(e)}>
        Ingresar
      </Button>
      <Row>
        <Col md="6">
          <p onClick={() => goRegister()} className="cursor-pointer">
            Registrarme
          </p>
          <div className="blue-dot"></div>
        </Col>
        <Col md="6">
          <p onClick={() => goPasswordRecovery()} className="cursor-pointer">
            He olvidado mi contraseña
          </p>
        </Col>
      </Row>
    </div>
  )
}
