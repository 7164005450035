import React, { useEffect, useState } from 'react'
import { Sidebar } from '../global/sidebar'
import { Footer } from '../global/footer'
import { useDispatch, useSelector } from 'react-redux'
import {
  userStartLoading,
  userStartActivate,
  userStartDeactivate,
  allUserStartLoading,
  rolesStartLoading
} from '../../actions/users'
import { RootState } from '../../reducers/rootReducer'
import { Col, Row, NavbarText, Navbar } from 'reactstrap'
import { Button, FormGroup, Checkbox } from 'carbon-components-react'
import { Add16 } from '@carbon/icons-react'
import { ListUser } from './ListUser'
import { UserModal } from './userModal/userModal'

export const User = () => {
  const dispatch = useDispatch()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [user, setUser] = useState()
  const [getAll, setGetAll] = useState(false)

  const { roles }: any = useSelector((store: RootState) => store.users)

  useEffect(() => {
    dispatch(userStartLoading())
    dispatch(rolesStartLoading())
  }, [dispatch])

  const { users }: any = useSelector((store: RootState) => store.users)

  const toggle = (userItem?) => {
    setIsOpenModal(!isOpenModal)
    setUser(userItem ? userItem : null)
  }

  const activeUser = (id) => {
    dispatch(userStartActivate(id))
  }

  const deactiveUser = (id) => {
    dispatch(userStartDeactivate(id, getAll))
  }

  const getAllUsers = (getAllItems) => {
    if (getAllItems) {
      dispatch(allUserStartLoading())
    } else {
      dispatch(userStartLoading())
    }
    setGetAll(getAllItems)
  }

  return (
    <div data-test="component-user">
      <Sidebar />
      <div className="container mb-5">
        <Row>
          <Col className="border-col-right">
            <div className="list">
              <Navbar expand="md">
                <Row>
                  <Col className="center-div pl-0" md="2">
                    <NavbarText>
                      <h2>Usuarios</h2>
                    </NavbarText>
                  </Col>
                  <Col md="6"></Col>

                  <Col className="center-div pl-1" md="2">
                    <Button
                      renderIcon={Add16}
                      iconDescription="Icon Description"
                      onClick={() => toggle()}
                      className="add-btn"
                    >
                      Añadir usuario
                    </Button>
                  </Col>
                  <Col md="2">
                    <FormGroup legendText={false} className="mt-3 mb-0">
                      <Checkbox
                        onChange={(e) => getAllUsers(e)}
                        labelText="Mostrar desactivados"
                        id="getAll"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Navbar>
              <div className="margin-list account-list">
                <ListUser
                  users={users}
                  toggle={toggle}
                  activeUser={activeUser}
                  deactiveUser={deactiveUser}
                  roles={roles}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <UserModal isOpenModal={isOpenModal} toggle={toggle} user={user} roles={roles}></UserModal>

      <Footer />
    </div>
  )
}
