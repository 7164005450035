import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { isLogin } from '../utils/Functions'

const grantPermission = (requestedRoles) => {
  const rol = localStorage.getItem('rol')
  return requestedRoles.find((elem) => {
    return elem === rol
  })
}

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <>
      {isLogin() === false && <Route {...rest} component={() => <Redirect to="/login" />} />}
      {grantPermission(roles) && (
        <Route
          {...rest}
          component={(props) => (isLogin() ? <Component {...props} /> : <Redirect to="/login" />)}
        />
      )}
    </>
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired
}
