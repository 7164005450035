import React, { useEffect } from 'react'
import { useForm, useStep } from 'react-hooks-helper'
import { ResourceType } from '../resourceType'
import { ResourceProject } from '../resourceProject'
import '../resourceModal.scss'
import { resourceStartUpdating } from '../../../../actions/resource'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../reducers/rootReducer'

const steps = [
  {
    id: 'typeQuantity'
  },
  {
    id: 'project'
  }
]

export const OnCloud = ({ toggle, service, categories, fromDrag }) => {
  const dispatch = useDispatch()
  const { dates, account }: any = useSelector((store: RootState) => store.resources)
  const title = service.id && 'Editar gasto Cloud'

  const defaultData = {
    account_id: 1,
    alias: '',
    amount: 0,
    category: '',
    cost_type: '',
    description: '',
    name: '',
    period_cost: '',
    periodicity: '',
    projects: '',
    period: ''
  }

  useEffect(() => {
    if (service.id) {
      Object.entries(service).forEach(([key, value]) => {
        setForm({
          target: {
            name: key,
            value: value
          }
        })
      })
    }
  }, [service])

  const [formData, setForm] = useForm(defaultData)

  const toStep = fromDrag === true ? 1 : 0
  const { step, navigation } = useStep({
    initialStep: service ? toStep : 0,
    steps
  })

  const { id } = step
  const onSubmitForm = () => {
    dispatch(resourceStartUpdating(formData, dates.from, dates.to))
    toggle()
  }
  const props = {
    formData,
    setForm,
    navigation,
    toggle,
    onSubmitForm,
    type: 'cloud',
    title
  }

  switch (id) {
    case 'typeQuantity':
      return <ResourceType {...props} categories={categories} />
    case 'project':
      return <ResourceProject {...props} statePeriodicityName={''} account={account} />
    default:
      return null
  }
}
