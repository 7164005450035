import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab } from 'carbon-components-react'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { ReportByDate } from '../../../components/dashboards/byDate/ReportByDate'
import { ReportByProject } from '../../../components/dashboards/byProject/ReportByProject'
import './Dashboard.scss'
import Footer from '../../../components/global/footer'
import { Sidebar } from '../../../components/global/sidebar'
import { getCommonData, getByDateData, getByProjectsData } from '../services/dashboards'
import { FilterContext } from '../../../context/filters/FiltersContext'
import { FiltersProvider } from '../../../context/filters/FiltersProvider'
import { ROUTE } from 'common/constants'
import { getUserData } from 'utils/Auth'
import Cookies from 'universal-cookie'

const DashboardsComponent = ({ setCurrentTab, currentTab }) => {
  const cookies = new Cookies()
  const { state } = useContext(FilterContext)
  const { companyId } = getUserData()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [mustTrigger, setMustTrigger] = useState<boolean>(true)
  const basicParamsReady = () =>
    localStorage.getItem('company_id') !== undefined && cookies.get('access_token') !== undefined

  const filtersByDateReady = () =>
    state?.currency !== undefined &&
    state?.projects?.length &&
    state?.accounts?.length &&
    state?.categories?.length &&
    currentTab === 0

  const filtersByProjectsReady = () =>
    state?.currency !== undefined &&
    state?.projects?.length === 1 &&
    state?.accounts?.length &&
    state?.categories?.length &&
    currentTab === 1

  useEffect(() => {
    if (location?.pathname === '/dashboard/date') setCurrentTab(0)
    if (location?.pathname === '/dashboard/projects') setCurrentTab(1)
  }, [location])

  const tabSelectionHandler = (e) => {
    if (e === 0) {
      setCurrentTab(0)
      history.push(ROUTE.REPORT_BY_DATE)
    } else {
      setCurrentTab(1)
      history.push(ROUTE.REPORT_BY_PROJECTS)
    }
  }

  const fetchCommonData = async () => {
    await dispatch(getCommonData())
  }
  const fetchByDateData = async () => {
    await dispatch(getByDateData(state))
  }
  const fetchByProjectsData = async () => {
    await dispatch(getByProjectsData(state))
  }

  useEffect(() => {
    basicParamsReady() && fetchCommonData()
  }, [])

  useEffect(() => {
    if (filtersByProjectsReady() && mustTrigger) {
      fetchByProjectsData()
      setMustTrigger(false)
    }
  }, [filtersByProjectsReady, currentTab, mustTrigger])

  useEffect(() => {
    if (filtersByDateReady() && mustTrigger) {
      fetchByDateData()
      setMustTrigger(false)
    }
  }, [filtersByDateReady, currentTab, companyId, mustTrigger])

  const dashboardType = window.location.pathname

  return (
    <div data-test="component-dashboards">
      <Sidebar />
      <div className="container mb-5 dashboard">
        <Tabs
          onSelectionChange={(e) => tabSelectionHandler(e)}
          selected={dashboardType === '/dashboard/date' ? 0 : 1}
        >
          <Tab id="tab-1" label="Reporte por fecha ">
            {dashboardType === '/dashboard/date' && (
              <ReportByDate
                currentTab={currentTab}
                getCommonData={fetchCommonData}
                getSpecificData={fetchByDateData}
                setMustTrigger={setMustTrigger}
              />
            )}
          </Tab>
          <Tab id="tab-2" label="Reporte por proyecto">
            {dashboardType === '/dashboard/projects' && (
              <ReportByProject
                currentTab={currentTab}
                getCommonData={fetchCommonData}
                getSpecificData={fetchByProjectsData}
                setMustTrigger={setMustTrigger}
              />
            )}
          </Tab>
        </Tabs>
      </div>
      <Footer />
    </div>
  )
}

const Dashboards = () => {
  const [currentTab, setCurrentTab] = useState<number>(0)

  return (
    <FiltersProvider currentTab={currentTab}>
      <DashboardsComponent setCurrentTab={setCurrentTab} currentTab={currentTab} />
    </FiltersProvider>
  )
}

export { Dashboards }
