import React, { useEffect, useState } from 'react'
import { Col, Row, NavbarText, Navbar } from 'reactstrap'
import { Button, FormGroup, Checkbox } from 'carbon-components-react'

import './Project.scss'
import { Sidebar } from '../global/sidebar'
import { Add16 } from '@carbon/icons-react'
import { ListProjects } from './ListProjects'
import {
  allProjectStartLoading,
  activeProjectStartLoading,
  projectStartActivate,
  projectStartDeactivate
} from '../../actions/projects'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../reducers/rootReducer'
import { ProjectModal } from './projectModal/projectModal'
import { Footer } from '../global/footer'

export const Project = () => {
  const dispatch = useDispatch()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [project, setProject] = useState()
  const [getAll, setGetAll] = useState(false)

  useEffect(() => {
    dispatch(activeProjectStartLoading())
  }, [])

  const projects: any = useSelector((store: RootState) => store.projects)

  const toggle = (projectItem?) => {
    setIsOpenModal(!isOpenModal)
    setProject(projectItem ? projectItem : null)
  }

  const getAllProject = (getAllItems) => {
    if (getAllItems) {
      dispatch(allProjectStartLoading())
    } else {
      dispatch(activeProjectStartLoading())
    }
    setGetAll(getAllItems)
  }

  const activeProject = (id) => {
    dispatch(projectStartActivate(id))
  }

  const deactiveProject = (id) => {
    dispatch(projectStartDeactivate(id, getAll))
  }

  return (
    <div data-test="component-project">
      <Sidebar />
      <div className="container mb-5">
        <Row>
          <Col className="border-col-right">
            <div className="list">
              <Navbar expand="md">
                <Row>
                  <Col className="center-div pl-0" md="2">
                    <NavbarText>
                      <h2>Mis proyectos</h2>
                    </NavbarText>
                  </Col>
                  <Col md="6"></Col>

                  <Col className="center-div pl-1" md="2">
                    <Button
                      renderIcon={Add16}
                      iconDescription="Icon Description"
                      onClick={() => toggle()}
                      className="add-btn"
                    >
                      Añadir proyecto
                    </Button>
                  </Col>
                  <Col md="2">
                    <FormGroup legendText={false} className="mt-3 mb-0">
                      <Checkbox
                        onChange={(e) => getAllProject(e)}
                        labelText="Mostrar desactivados"
                        id="getAll"
                        value={getAll}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Navbar>
              <div className="margin-list">
                <ListProjects
                  projects={projects}
                  toggle={toggle}
                  activeProject={activeProject}
                  deactiveProject={deactiveProject}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <ProjectModal isOpenModal={isOpenModal} toggle={toggle} project={project}></ProjectModal>
      <Footer />
    </div>
  )
}
