import React, { useState, useEffect } from 'react'
import { Col, Row, Modal } from 'reactstrap'
import { Select, SelectItem, Loading } from 'carbon-components-react'
import { accountStartAddNew, accountStartAddNewGoogle, getFile } from '../../../actions/account'
import { ModalButtons } from '../../global/modalButtons'
import { checkInputValidity, validations } from '../../../utils/Validations'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker, { registerLocale } from 'react-datepicker'
import { RootState } from '../../../reducers/rootReducer'
import '../../resource/datePicker/DatePicker.scss'
import es from 'date-fns/locale/es'
import moment from 'moment'
registerLocale('es', es)

export const AccountStepTwo = ({ formValues, setFormValues, toggle, isEdit, navigation }) => {
  const dispatch = useDispatch()

  const { previous } = navigation

  const [startDate, setStartDate] = useState(new Date())
  const [dateFromCalendar, setDateFromCalendar] = useState('' as any)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { date }: any = useSelector((store: RootState) => store.accounts)

  useEffect(() => {
    if (date) {
      setFormValues({
        target: {
          name: 'from',
          value: date.from
        }
      })

      if (formValues.dateFrom === 'date') {
        const parts = date.from.split('-')
        const mydate = new Date(parts[0], parts[1] - 1, parts[2])
        setDateFromCalendar(mydate)
      }
    }
  }, [date])

  const handleInputChangeDate = (event) => {
    validations(event)
    const value = event.target.value
    if (value === 'fromToday') {
      setFormValues({
        target: {
          name: 'from',
          value: moment(new Date()).format('YYYY-MM-01')
        }
      })
    } else if (value === 'all') {
      setFormValues({
        target: {
          name: 'from',
          value: date.from
        }
      })
    }
    setFormValues({
      target: {
        name: 'dateFrom',
        value: value
      }
    })
  }

  const handleChangeDateCalendar = (newDate) => {
    setStartDate(newDate)
    setFormValues({
      target: {
        name: 'from',
        value: moment(new Date(newDate)).format('YYYY-MM-01')
      }
    })
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    const { dateFrom, cloudType } = formValues

    validations(null, {
      value: dateFrom,
      required: true,
      id: 'dateFrom'
    })

    if (checkInputValidity() === 0) {
      const newData = {
        ...formValues,
        type: formValues.accountType === 'ONPREMISE' ? formValues.accountType : formValues.cloudType
      }
      if (cloudType === 'GOOGLE') {
        dispatch(accountStartAddNewGoogle(newData, toggle, getFile()))
      } else {
        dispatch(accountStartAddNew(newData, toggle))
      } 
    }
  }

  return (
    <div data-test="component-account-step-two">
      <div className="container">
        <Row className="mt-4">
          <Col md="1"></Col>
          <Col md="10">
            {formValues.cloudType && (
              <Select
                name="dateFrom"
                id="dateFrom"
                onChange={handleInputChangeDate}
                defaultValue={formValues.dateFrom}
                disabled={isEdit}
                labelText={false}
              >
                <SelectItem disabled hidden value="" text="Selecciona la fecha de sincronización" />
                <SelectItem value="all" text="Sincronizar todo" />
                <SelectItem value="date" text="Elegir fecha..." />
                <SelectItem value="fromToday" text="Sincronizar a partir de hoy" />
              </Select>
            )}
            {formValues.dateFrom === 'date' && (
              <DatePicker
                className="date-form date-picker-custom"
                selected={startDate}
                onChange={(e) => handleChangeDateCalendar(e)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                locale="es"
                shouldCloseOnSelect={false}
                minDate={dateFromCalendar}
                maxDate={new Date()}
              />
            )}
          </Col>
        </Row>
        <ModalButtons
          textPrev={'Anterior'}
          actionPrev={previous}
          textNext={'Crear cuenta'}
          actionNext={() => handleSubmit()}
          icons={false}
        />
      </div>
      <Modal isOpen={isSubmitting} size="xs">
        <h2 className="text-center p-5">Estamos procesando sus datos. Por favor espere...</h2>
        <Loading className="loading mb-5" withOverlay={false} small={false} />
      </Modal>
    </div>
  )
}
