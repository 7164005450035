import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import { SimpleBarChart } from '@carbon/charts-react'
import '@carbon/charts/styles.css'
import moment from 'moment'
import { TimeToggle } from '../../atoms/TimeToggle'
import { COLOR } from '../../../utils/constants/styles'

export const BarCharts = ({ data, setPerDay, perDay }) => {
  const [reportOne, setReportOne] = useState([] as any)
  let colorsOption: any = {}

  const colors = [COLOR.IT_SPEND_GREY, COLOR.IT_SPEND_BLUE]

  useEffect(() => {
    if (data) {
      setReportOne(() => {
        return data?.map((item) => ({
          group: moment(item.group).format('DD/MM'),
          value: item.value
        }))
      })
      if (perDay) {
        data?.forEach(
          (item) =>
            (colorsOption = {
              ...colorsOption,
              [moment(item.group).format('DD/MM').toString()]: COLOR.IT_SPEND_BLUE
            } as never)
        )
        setOptionsReport({ ...optionsReport, color: { scale: colorsOption } })
      }
    }
  }, [data])

  const [optionsReport, setOptionsReport] = useState({
    title: 'Historial de gastos por rango de tiempo',
    axes: {
      left: {
        mapsTo: 'value',
        title: 'Monto'
      },
      bottom: {
        title: 'Fecha',
        mapsTo: 'group',
        scaleType: 'labels'
      }
    },
    color: {
      scale: {}
    },
    tooltip: {
      customHTML: (item) => {
        return `<p>${item[0].group} $${item[0].value}</p>`
      }
    },
    height: '400px'
  } as any)

  return (
    <Row data-test="component-bar-charts" className="mb-5">
      <Col className={perDay ? 'perday-style' : 'custom-bar'} md="12">
        <TimeToggle colors={colors} perDay={perDay} setPerDay={setPerDay} />
        <SimpleBarChart data={reportOne} options={optionsReport} />
      </Col>
    </Row>
  )
}
