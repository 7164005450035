import React, { useState } from 'react'
import {
  ExpandableTile,
  TileAboveTheFoldContent
  // TileBelowTheFoldContent
} from 'carbon-components-react'
import { ListProjectMain } from './ListProjectMain'
// import { ListSubproject } from './ListSubproject'

export const ListProjects = ({ projects, toggle, activeProject, deactiveProject }) => {
  const [disableExpand, setDisableExpand] = useState(false)

  // const subproyect = {
  //   color: '#99a3a5',
  //   company_id: 1,
  //   description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
  //   id: 4,
  //   is_active: 1,
  //   name: 'Subproyecto'
  // }
  return (
    <div data-test="component-list-projects">
      {projects.projects &&
        projects.projects.map((project, i) => (
          <div className="project-background list-project" key={i}>
            <ExpandableTile
              tileCollapsedIconText="Ver detalle"
              tileExpandedIconText="Ocultar detalle"
              disabled={disableExpand}
            >
              <TileAboveTheFoldContent>
                <ListProjectMain
                  project={project}
                  toggle={toggle}
                  activeProject={activeProject}
                  deactiveProject={deactiveProject}
                  setDisableExpand={setDisableExpand}
                />
              </TileAboveTheFoldContent>
              {/* <div className="ml-5">
                <TileBelowTheFoldContent>
                  <ListSubproject
                    project={subproyect}
                    toggle={toggle}
                    activeProject={activeProject}
                    deactiveProject={deactiveProject}
                    setDisableExpand={setDisableExpand}
                  />
                </TileBelowTheFoldContent>
              </div> */}
            </ExpandableTile>
          </div>
        ))}
    </div>
  )
}
