import { types } from '../types/types'
import { fetchWithToken } from '../helpers/fetch'
import Swal from 'sweetalert2'
import { projectStartLoading } from './projects'
import { categoriesStartLoading } from './resource'
import correct from '../assets/img/checkmark--outline.svg'

export const onPremiseStartAddNew = (formData, from, to) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken('resources/on-premise', formData, 'POST')
      const body = await resp.json()

      dispatch(projectStartLoading(from, to))
      if (resp.status === 200) {
        await dispatch(onPremiseAddNew(body))
        dispatch(categoriesStartLoading('ONPREMISE'))
        Swal.fire({
          imageUrl: correct,
          title: 'Su gasto ha sido añadido con éxito!',
          customClass: {
            image: 'image-success image-size'
          },
          showConfirmButton: false,
          footer: '<a href="/onpremise">Volver a Gastos on premise</a>'
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const onPremiseAddNew = (body) => {
  return {
    type: types.onPremiseAddNew,
    payload: body
  }
}

export { onPremiseAddNew }
