import React from 'react'
import { Row, Col } from 'reactstrap'
import { ChevronUp32, ChevronDown32, Warning32, Repeat32 } from '@carbon/icons-react'
import ListServiceEditDelete from './listServiceEditDelete'
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducers/rootReducer'
import { TooltipDefinition } from 'carbon-components-react'
export const ListServiceMain = ({
  account,
  service,
  i,
  showMore,
  setDisableExpand,
  toggle = null,
  toggleModalDelete = null
}) => {
  const { dates }: any = useSelector((store: RootState) => store.resources)
  const title = account.type !== 'ONPREMISE' ? service.alias : service.name

  return (
    <Row
      data-test="component-list-service-main"
      className="border-row"
      id={i}
      style={{
        backgroundColor: service.unassociatedPercentage === 100 ? '#FDF9DE' : '#fff'
      }}
    >
      <Col md="3">
        {title.length >= 30 ? (
          <TooltipDefinition className="resource-title" tooltipText={title}>
            <p className="title-expand">{title}</p>
          </TooltipDefinition>
        ) : (
          <p className="title-expand">{title}</p>
        )}
      </Col>
      <Col md="1">
        {service.cost_type === 'RECURRENT' && (
          <TooltipDefinition tooltipText="Gasto recurrente">
            <Repeat32 className="icon-expand" />
          </TooltipDefinition>
        )}
      </Col>

      <Col className="center-div pl-0" md="4">
        {service.projects &&
          service.projects.slice(0, 1).map((project) => (
            <React.Fragment key={`${i}`}>
              <p
                key={`serviceProject-${project.id}`}
                className="service-project project"
                style={{ backgroundColor: project.color }}
              >
                {project.name}
              </p>
              <span
                className="project-percentage"
                style={{ color: project.color, borderColor: project.color }}
              >
                {project.quota}%
              </span>
            </React.Fragment>
          ))}
        {service.projects && service.projects.length > 1 && (
          <a
            className="btn-proyects ml-3"
            onClick={() => showMore(i)}
            onPointerEnter={() => setDisableExpand(true)}
            onPointerLeave={() => setDisableExpand(false)}
          >
            <span>
              + {service.projects.length - 1}
              {service.isOpen ? <ChevronUp32 /> : <ChevronDown32 />}
            </span>
          </a>
        )}
        <div className="part-proyects">
          {service.projects &&
            service.isOpen &&
            service.projects.slice(1, service.projects.length).map((project, indexP) => (
              <React.Fragment key={`proj-${indexP}`}>
                <p
                  key={`serviceProject-${project.id}`}
                  className="service-project project mb-2"
                  style={{ backgroundColor: project.color }}
                >
                  {project.name}
                </p>
                <span
                  className="project-percentage"
                  style={{ color: project.color, borderColor: project.color }}
                >
                  {project.quota}%
                </span>
              </React.Fragment>
            ))}
        </div>
      </Col>
      <Col className="center-div" md="1">
        <p>{parseFloat(service.amount).toFixed(2)}</p>
      </Col>
      <Col className="center-div" md="1">
        <p>{service.currency}</p>
      </Col>
      <Col md="1">
        {service.periods && service.npp && <Warning32 className="icon-expand" />}
        {!service.unassociatedPercentage ||
          (service.unassociatedPercentage !== 0 && <p>{service.unassociatedPercentage}%</p>)}
      </Col>
      <Col className="center-div icons pl-0" md="1">
        {dates.from === dates.to && (
          <ListServiceEditDelete
            account={account}
            toggle={toggle}
            service={service}
            toggleModalDelete={toggleModalDelete}
          ></ListServiceEditDelete>
        )}
      </Col>
    </Row>
  )
}

export default ListServiceMain
