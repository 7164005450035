import React, { useState, useEffect } from 'react'
import { Row, Col, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { ResourceReview } from './resourceReview'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import chroma from 'chroma-js'
import { RootState } from '../../../reducers/rootReducer'
import { useSelector } from 'react-redux'
import { checkInputValidity, validations } from '../../../utils/Validations'
import { ProgressIndicator, ProgressStep, Button, NumberInput } from 'carbon-components-react'
import { ArrowRight32, ArrowLeft32, Warning32 } from '@carbon/icons-react'

const animatedComponents = makeAnimated()

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    borderWidth: 0
  }),
  option: (styles, { data, isFocused, isSelected }) => {
    const color = chroma(data.color)
    const focusColor = isFocused ? color.alpha(0.1).css() : null
    const selectedColor = chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
    return {
      ...styles,
      backgroundColor: isSelected ? data.color : focusColor,
      color: isSelected ? selectedColor : data.color,
      cursor: 'default',
      ':active': {
        ...styles[':active']
      }
    }
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color)
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css()
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white'
    }
  })
}

export const ResourceProject = ({
  formData,
  navigation,
  setForm,
  onSubmitForm,
  statePeriodicityName,
  title,
  account
}) => {
  const { previous } = navigation
  const [selectedState, setSelectedState] = useState<any[]>([])

  useEffect(() => {
    if (formData && formData?.projects) {
      setSelectedState(() => {
        return formData.projects.map((item) => ({
          value: item.name,
          label: item.name,
          color: '#ccc',
          quota: item.quota,
          name: item.name,
          id: item.id,
          company_id: item.company_id
        }))
      })
    }
  }, [])

  let listProyects = [] as any
  formData.projects &&
    formData.projects.map((item) =>
      listProyects.push({
        value: item.name,
        label: item.name,
        color: '#ccc',
        quota: item.quota,
        name: item.name,
        id: item.id,
        company_id: item.company_id
      })
    )

  const [messageState, setMessageState] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)

  const [projectsSelected, setProjectsSelected] = useState(0)

  const projects: any = useSelector((store: RootState) => store.projects)
  const listOptions = [] as any
  projects.projects
    .filter((project) => project.id)
    .map((item) =>
      listOptions.push({
        value: item.name,
        label: item.name,
        color: '#828aa0',
        name: item.name,
        id: item.id,
        company_id: item.company_id
        // quota: '',
        // pAmount: ''
      })
    )

  const setSelectedOption = (e) => {
    setSelectedState(e)
    setProjectsSelected(e?.length)
    if (!e) {
      setMessageState('')
      setSelectedState([])
    } else {
      setSelectedState((state) => {
        if (e.length > projectsSelected) {
          restPercentaje(e)
        }
        getTotalPorcentaje(state)
        return state
      })
    }
  }

  const restPercentaje = (list) => {
    const totalPercentaje = list.reduce(
      (accum, item) => accum + (item.quota ? Number(item.quota) : 0),
      0
    )
    if (totalPercentaje < 100) {
      const missingPercentaje = 100 - totalPercentaje
      onChangePercentaje(list.length - 1, missingPercentaje, list)
    }
  }

  const onChangePercentaje = (index, e, list?) => {
    const percentaje = e.target ? Number(e.target.value) : e
    const amount = formData.amount
    const pAmount = (percentaje * amount) / 100
    const listAll = list ? list : [...selectedState]
    let sProject = {
      ...listAll[index]
    }
    sProject = {
      ...sProject,
      pAmount: percentaje ? pAmount.toFixed(2) : '',
      quota: percentaje ? percentaje : ''
    }
    listAll[index] = sProject
    setSelectedState(listAll)
    getTotalPorcentaje(listAll)
  }

  useEffect(() => {
    if (listProyects.length > 0) {
      listProyects.map((item, i) => {
        let percentaje = 0
        let amount = 0
        let pAmount = 0
        if (item.quota) {
          percentaje = Number(item.quota)
          amount = formData.amount
          pAmount = (percentaje * amount) / 100
        }

        const list = [...listProyects]
        let sProject = {
          ...list[i]
        }
        sProject = {
          ...sProject,
          pAmount: pAmount.toFixed(2),
          quota: percentaje
        }
        list[i] = sProject
        listProyects = list
      })
      setSelectedState(listProyects)
      getTotalPorcentaje(listProyects)
    }
  }, [listProyects.length > 0])

  const onChangeAmount = (index) => (e) => {
    const pAmount = Number(e.target.value)
    const amount = formData.amount
    const quota = Math.round((100 * pAmount) / amount)
    const list = [...selectedState]
    let sProject = {
      ...list[index]
    }
    sProject = {
      ...sProject,
      pAmount: e.target.value ? pAmount : '',
      quota: e.target.value ? quota : ''
    }
    list[index] = sProject
    setSelectedState(list)
    getTotalPorcentaje(list)
  }

  const getTotalPorcentaje = (list) => {
    const totalPercentaje = list.reduce(
      (accum, item) => accum + (item.quota ? Number(item.quota) : 0),
      0
    )
    if (totalPercentaje > 100) {
      const excessPercentaje = totalPercentaje - 100
      setMessageState(`${excessPercentaje.toFixed(0)}% de este gasto supera el porcentaje total.`)
      setIsDisabled(true)
    } else if (totalPercentaje === 100) {
      setMessageState('')
      setIsDisabled(false)
    } else {
      const missingPercentaje = 100 - totalPercentaje
      setMessageState(
        `${missingPercentaje.toFixed(0)}% de este gasto no corresponde a ningún proyecto.`
      )
      setIsDisabled(false)
    }
  }

  useEffect(() => {
    setForm({
      target: {
        name: 'projects',
        value: selectedState
      }
    })
  }, [selectedState])

  const handleSubmit = () => {
    selectedState &&
      selectedState.map((item, i) => {
        validations(null, {
          value: item.quota !== 0 ? item.quota : null,
          required: true,
          id: `percentaje-${i}`
        })
        {
          account.type === 'ONPREMISE' &&
            validations(null, {
              value: item.pAmount !== '0.00' ? item.quota : null,
              required: true,
              id: `amount-${i}`
            })
        }
      })

    if (checkInputValidity() === 0) {
      onSubmitForm()
    }
  }

  return (
    <div data-test="component-resource-project" className="container">
      <Row>
        <Col md="7">
          <h2 className="text-center">{title}</h2>

          <div className="mr-2 ml-2">
            <div className="text-center mb-4">
              <h5>¿A qué proyectos está asociado y en qué cantidad?</h5>
            </div>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={listOptions}
              onChange={(e) => setSelectedOption(e)}
              styles={colourStyles}
              placeholder="Escribe el nombre del proyecto"
              className="bx--text-input mb-4"
              value={selectedState}
              noOptionsMessage={() => 'No hay más proyectos'}
            />
            {selectedState &&
              selectedState.map((selectedP, i) => (
                <Row key={i}>
                  <Col md="4">
                    <p
                      className="list-projects"
                      // style={{
                      //   backgroundColor: selectedP.color
                      // }}
                    >
                      {selectedP.label}
                    </p>
                  </Col>
                  <Col md="4" className="pl-0">
                    <InputGroup>
                      <NumberInput
                        min={0}
                        name="percentaje"
                        id={`percentaje-${i}`}
                        value={selectedP.quota}
                        onChange={(e) => onChangePercentaje(i, e)}
                        onInput={(e: any) => {
                          e.target.value = e.target.value.toString().slice(0, 3)
                        }}
                        // required
                        // hideLabel={true}
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>%</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col md="4" className="pl-0">
                    <InputGroup>
                      <NumberInput
                        // min={0}
                        name="amount"
                        id={`amount-${i}`}
                        value={selectedP.pAmount}
                        onChange={onChangeAmount(i)}
                        // required
                        className="input-amount"
                        // hideLabel={true}
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              ))}
            {messageState && (
              <p className="message">
                <Warning32 /> {messageState}
              </p>
            )}
          </div>

          <Row className="mt-3 progress-background">
            <Col md="12">
              {account.type === 'ONPREMISE' ? (
                <ProgressIndicator
                  className="mt-4 mr-2 ml-2"
                  vertical={false}
                  currentIndex={2}
                  spaceEqually={true}
                >
                  <ProgressStep label="Tipo y cantidad" secondaryLabel="Paso 1" />
                  <ProgressStep label="Periodicidad" secondaryLabel="Paso 2" />
                  <ProgressStep label="Proyecto" secondaryLabel="Paso 3" />
                </ProgressIndicator>
              ) : (
                <ProgressIndicator
                  className="mt-4"
                  vertical={false}
                  currentIndex={1}
                  spaceEqually={true}
                >
                  <ProgressStep label="Tipo y cantidad" secondaryLabel="Paso 1" />
                  <ProgressStep label="Proyecto" secondaryLabel="Paso 2" />
                </ProgressIndicator>
              )}
            </Col>
          </Row>
          <Row className="buttons">
            <Col md="6" className="p-0">
              <Button
                renderIcon={ArrowLeft32}
                iconDescription="Icon Description"
                onClick={() => previous()}
                className="button-previous"
              >
                Anterior{' '}
              </Button>
            </Col>
            <Col md="6" className="p-0">
              <Button
                renderIcon={ArrowRight32}
                disabled={isDisabled}
                iconDescription="Icon Description"
                onClick={handleSubmit}
                className="button-next"
              >
                Finalizar{' '}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col className="review" md="5">
          <ResourceReview
            formData={formData}
            statePeriodicityName={statePeriodicityName}
            account={account}
          ></ResourceReview>
        </Col>
      </Row>
    </div>
  )
}
