import React, { useEffect, useState } from 'react'
import { CHECK_TYPE } from 'common/constants'
import { COLOR } from 'utils/constants/styles'
import { getIdsListed, randomId } from 'utils/DataHelpers'
import { ToggleArrow } from '../../atoms/Icons'

interface ISelectable {
  id: number | string
  name: string
  checked: boolean
}
/*  Todo include currentTab prop and re-render for projects section at by projects tab with radio buttons */
const DropdownSection = (
  {
    title,
    subTitle,
    checkType,
    itemList,
    section,
    dispatch,
    action,
    withCheckAll,
    currentTab
  } /* : IDropdownSectionProps */
) => {
  const [open, setOpen] = useState<boolean>(true)
  const [listed, setListed] = useState<ISelectable[]>([])
  const isCurrencySection = () => section === 'currency'
  const isProjectSection = () => section === 'projects'
  const withCheckAllOption = () => {
    return withCheckAll
  }

  const checkallCheckbox: ISelectable = {
    id: 'ca',
    name: 'Seleccionar todo',
    checked: true
  }

  const checkallRadio: ISelectable = {
    id: 'ca',
    name: 'TODAS',
    checked: true
  }

  const handleSelection = (id: number | string) => {
    /* it handles all items selection with 
    it triggers if checkall id is passed as param && all items are selected */
    if (id === 'ca') {
      setListed(() => {
        return listed?.map((i) => ({
          ...i,
          checked: true
        }))
      })

      const checkedItems = listed?.filter((i) => i.checked)
      const payload = getIdsListed(checkedItems).filter((i) => i !== 'ca')
      return dispatch(action(payload))
    } else {
        setListed((prev) => {
          return listed?.map((i, idx) => {
            switch (i.id) {
              case 'ca':
                return {
                  ...i,
                  checked: false
                }
              case id:
                return {
                  ...i,
                  checked: !prev[idx].checked
                }
              default:
                return i
            }
          })
        }) 
      const checkedItems = listed?.filter((i) => i.checked)
      const payload = getIdsListed(checkedItems).filter((i) => i !== 'ca')
      return dispatch(action(payload))
    }
  }

  const handleRadioSelection = (id, name) => {
    setListed(() => {
      return listed?.map((i) => ({
        ...i,
        checked: i.id === id ? true : false
      }))
    })
    const currencyPayload = id === 'ca' ? '' : name
    const payload = section === 'currency' ? currencyPayload : [id]
    return dispatch(action(payload))
  }

  const setName = (item: any) => {
    if (isCurrencySection()) return item.currency
    return item.name
  }

  const setId = (item: any, idx: number) => {
    if (withCheckAllOption() && !item.id) {
      return idx
    }
    return item.id
  }

  useEffect(() => {
    /* there is a valid array being drilled as prop */
    if ((itemList?.length && !listed.length) || (itemList?.length && section === "projects" && currentTab === 1)) {
      /*radio-button inputs set by checkType prop*/
      if (checkType === CHECK_TYPE.RADIO ) {
        setListed(() => {
          return itemList.map((i, idx) => ({
            id: setId(i, idx),
            name: setName(i),
            checked: !withCheckAll && idx === 0 ? true : false
          }))
        })
        /* push to the beginning of listed the checkall option for currency section */
        withCheckAll && setListed((prev) => [checkallRadio, ...prev])
        /*check-box inputs set by checkType prop*/
      } else {
        /* set list at state hook for UI render */
        setListed(() => {
          return itemList.map((i, idx) => ({
            id: setId(i, idx),
            name: setName(i),
            checked: true
          }))
        })
        /* push to the beginning of listed the checkall option */
        withCheckAll && setListed((prev) => [checkallCheckbox, ...prev])
      }
    }
  }, [itemList , checkType, withCheckAll ])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '10px'
        }}
      >
        <div
          style={{
            backgroundColor: '#eeee',
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
            height: '2px',
            width: '350px'
          }}
        />
      </div>
      <div style={{ paddingLeft: '10px' }} onClick={() => setOpen(!open)}>
        <ToggleArrow open={open} />
        <div style={{ fontWeight: 'bold', width: '200px', fontSize: '16px' }}>{title}</div>
        <div style={{ fontSize: '13px', paddingTop: '10px', marginBottom: '5px' }}>{subTitle}</div>
      </div>
      {open && (
        <div style={{ padding: '10px' }}>
          <div /* style={{ padding: '30px' }} */>
            {listed &&
              listed?.map((i) => (
                <div
                  key={randomId()}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1.5%'
                  }}
                >
                  <form>
                    <input
                      key={randomId()}
                      type={checkType === 'radio' ? 'radio' : 'checkbox'}
                      id={i.id as never}
                      name={checkType === 'radio' ? section : i.name}
                      value={i.name}
                      checked={i.checked}
                      radioGroup={section}
                      onChange={
                        checkType === 'radio'
                          ? () => handleRadioSelection(i.id, i.name)
                          : () => handleSelection(i.id)
                      }
                      style={{
                        color: '#4e76e7',
                        alignItems: 'center',
                        top: 0,
                        left: 0,
                        margin: '0.0625rem 0.5rem 0.125rem 0.125rem',
                        height: '1.125rem',
                        width: '1.125rem',
                        backgroundColor: '#eee'
                      }}
                    />
                    <label
                      key={randomId()}
                      style={{
                        color: i.checked ? '#4e76e7' : COLOR.IT_SPEND_GREY,
                        paddingLeft: '8px',
                        fontFamily: 'Segoe UI, sans-serif',
                        fontWeight: 3000,
                        alignItems: 'center',
                        overflow: 'hidden'
                      }}
                    >
                      {i.name}
                    </label>
                  </form>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export { DropdownSection }
