import React, { useEffect, useReducer } from 'react'
import { FilterContext, FiltersReducer } from './FiltersContext'
import { initialState } from './constants'
import { UseStoreFilters } from 'hooks/UseStoreFilters'
import { getDateDefaultFilters, getIdsListed } from 'utils/DataHelpers'
import {
  updateCurrencies,
  updateCategories,
  updateAccounts,
  updateProjects,
  updateFromDate,
  updateToDate,
  updateProject
} from 'context/filters/actions'

const FiltersProvider = ({ children, currentTab }) => {
  const [state, dispatch] = useReducer(FiltersReducer, initialState)
  const { accounts, currency, projects, categories } = UseStoreFilters()
  const { startDate, endDate } = getDateDefaultFilters()
  const value = { state, dispatch }

  useEffect(() => {
    if (accounts?.length) {
      const accountsIds = getIdsListed(accounts)
      dispatch(updateAccounts(accountsIds))
    }
    if (currency) {
      const currencyPayload = currency?.length > 1 ? '' : currency[0]?.currency
      dispatch(updateCurrencies(currencyPayload))
    }
    if (projects?.length) {
      const projectsIds = getIdsListed(projects)
      dispatch(updateProjects(currentTab === 0 ? projectsIds : [projectsIds[0]]))
      dispatch(updateProject(projectsIds[0]))
    }
    if (categories?.length) {
      const categoriesIds = getIdsListed(accounts)
      dispatch(updateCategories(categoriesIds))
    }

    dispatch(updateFromDate(startDate))
    dispatch(updateToDate(endDate))
  }, [accounts, currency, projects, categories, currentTab])

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
}

export { FiltersProvider }
