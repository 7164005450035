import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'

import '@carbon/charts/styles.css'
import { DonutChart } from '@carbon/charts-react'
import { colors, getRandomColor } from '../../utils/Functions'

export const DonutsCharts = ({ dataLeft, dataRight, titleLeft, titleRight }) => {
  const [reportTwo, setReportTwo] = useState([] as any)
  const [reportThree, setReportThree] = useState([] as any)

  const generalOptions = {
    title: '',
    resizable: true,
    donut: {
      center: {
        label: 'Total'
      },
      alignment: 'center' as any
    },
    color: {},
    legend: { alignment: 'center' as any },
    height: '400px'
  }

  const [optionsReportTwo, setOptionsReportTwo] = useState({
    ...generalOptions,
    title: titleLeft
  })

  const [optionsReportThree, setOptionsReportThree] = useState({
    ...generalOptions,
    title: titleRight
  })

  useEffect(() => {
    const objR = dataLeft.reduce(
      (o, key) => ({ ...o, [key.group]: colors[getRandomColor(0, colors.length)] }),
      {}
    )
    setOptionsReportTwo({
      ...optionsReportTwo,
      color: {
        scale: objR
      }
    })
    setReportTwo(dataLeft)
  }, [dataLeft])

  useEffect(() => {
    const objL = dataRight.reduce(
      (o, key) => ({ ...o, [key.group]: colors[getRandomColor(0, colors.length)] }),
      {}
    )
    setOptionsReportThree({
      ...optionsReportThree,
      color: {
        scale: objL
      }
    })
    setReportThree(dataRight)
  }, [dataRight])

  return (
    <Row data-test="component-donuts-charts" style={{ marginBottom: '30px' }}>
      <Col md="6">
        <DonutChart data={reportTwo} options={optionsReportTwo} />
      </Col>
      <Col md="6">
        <DonutChart data={reportThree} options={optionsReportThree} />
      </Col>
    </Row>
  )
}
