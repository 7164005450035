import { types } from '../types/types'

const initialState = {
  currency: [] as any
}

export const currencyReducer = (state = initialState, action) => {
  if (action.type === types.currencyLoaded) {
    return {
      ...state,
      currency: [...action.payload]
    }
  } else {
    return state
  }
}
