import React from 'react'
import { useSelector, useStore } from 'react-redux'
import { RootState } from '../reducers/rootReducer'
import { UseStoreFilters } from './UseStoreFilters'

const UseByDateReports = () => {
  const { accounts, currency, projects, categories } = UseStoreFilters()
  const {
    expensesProjectPerDay,
    expensesProjectPerMonth,
    unassignedExpenses,
    expensesAccount,
    expensesCategories,
    expensesAndPercentilProjects,
    totalAmounts
  }: any = useSelector((store: RootState) => store.reportsByDate)

  return {
    accounts,
    currency,
    projects,
    categories,
    expensesProjectPerDay,
    expensesProjectPerMonth,
    unassignedExpenses,
    expensesAccount,
    expensesCategories,
    expensesAndPercentilProjects,
    totalAmounts
  }
}

export { UseByDateReports }
