import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, Col, Row } from 'reactstrap'
import { TextInput, TextArea, Button, RadioButtonGroup, RadioButton } from 'carbon-components-react'
import { CirclePicker, SketchPicker } from 'react-color'
import { ChevronDown32 } from '@carbon/icons-react'
import { useDispatch } from 'react-redux'
import { projectStartAddNew, projectStartUpdating } from '../../../actions/projects'
import { ModalButtons } from '../../global/modalButtons'
import { checkInputValidity, validations } from '../../../utils/Validations'
import ClickOutHandler from 'react-clickout-handler'
import { colors, getRandomColor } from '../../../utils/Functions'

export const ProjectModal = ({ isOpenModal, toggle, project }) => {
  const dispatch = useDispatch()
  const [isOpenColors, setIsOpenColors] = useState(false)
  const companyId = localStorage.getItem('company_id')
  const defaultValues = { name: '', description: '', color: '', company_id: companyId }
  const [formValues, setFormValues] = useState(defaultValues)

  useEffect(() => {
    if (project && project.id) {
      setFormValues(project)
    } else {
      setFormValues(defaultValues)
    }
  }, [project && project.id])

  const handleChangeComplete = (color) => {
    setFormValues({
      ...formValues,
      color: color.hex
    })
    validations(null, {
      value: color.hex,
      required: true,
      id: 'color'
    })
  }

  const handleInputChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    })
    validations(event)
  }

  const randomColor = () => {
    const random = getRandomColor(0, colors.length)
    setFormValues({
      ...formValues,
      color: colors[random]
    })
    setIsOpenColors(!isOpenColors)
  }

  const handleSubmit = () => {
    const { name, description, color } = formValues
    validations(null, {
      value: name,
      required: true,
      id: 'name'
    })
    validations(null, {
      value: description,
      required: true,
      id: 'description'
    })
    validations(null, {
      value: color,
      required: true,
      id: 'color'
    })
    if (checkInputValidity() === 0) {
      if (project) {
        dispatch(projectStartUpdating(project.id, formValues))
      } else {
        dispatch(projectStartAddNew(formValues))
      }
      setFormValues(defaultValues)
      toggle()
    }
  }

  const handleClickOut = () => {
    setIsOpenColors(false)
  }

  return (
    <Modal data-test="component-project-modal" isOpen={isOpenModal} size="lg">
      <ModalHeader toggle={toggle}></ModalHeader>
      <h2 className="text-center">{project ? 'Editar proyecto' : 'Nuevo proyecto'}</h2>
      <div className="container">
        <Row className="mt-4">
          <Col md="1"></Col>
          <Col md="10">
            <TextInput
              placeholder="Nombre"
              name="name"
              id="name"
              labelText={''}
              value={formValues.name}
              onChange={handleInputChange}
            />
            <TextArea
              placeholder="Descripción"
              name="description"
              id="description"
              value={formValues.description}
              onChange={handleInputChange}
              cols={50}
              rows={4}
              labelText={false}
            />
            <ClickOutHandler onClickOut={handleClickOut}>
              <Button
                className="button-select-color"
                id="color"
                renderIcon={ChevronDown32}
                onClick={() => setIsOpenColors(!isOpenColors)}
              >
                <span
                  style={{ backgroundColor: formValues.color }}
                  className={formValues.color && 'dot add-modal'}
                ></span>
                <span>Color {formValues.color ? formValues.color : null}</span>
              </Button>
              {isOpenColors && (
                <div className="select-color">
                  <RadioButtonGroup onChange={() => randomColor()} name="radio-button-group">
                    <RadioButton labelText="Automático" value="radio-1" id="radio-1" />
                  </RadioButtonGroup>
                  <Row>
                    <Col md="6">
                      <p>Colores del tema</p>
                      <CirclePicker
                        colors={colors}
                        color={formValues.color}
                        onChangeComplete={handleChangeComplete}
                      />
                    </Col>
                    <Col md="6">
                      <SketchPicker
                        color={formValues.color}
                        onChangeComplete={handleChangeComplete}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </ClickOutHandler>
          </Col>
        </Row>
        <ModalButtons
          textPrev={'Cancelar'}
          actionPrev={toggle}
          textNext={project ? 'Guardar' : 'Crear proyecto'}
          actionNext={handleSubmit}
          icons={false}
        />
      </div>
    </Modal>
  )
}
