import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, Col, Row } from 'reactstrap'
import { TextInput, Select, SelectItem } from 'carbon-components-react'
import { useDispatch } from 'react-redux'
import { userStartUpdating, userStartAddNew } from '../../../actions/users'
import { ModalButtons } from '../../global/modalButtons'
import { checkInputValidity, validations } from '../../../utils/Validations'

export const UserModal = ({ isOpenModal, toggle, user, roles }) => {
  const dispatch = useDispatch()
  const defaultValues = { name: '', email: '', password: '', role_id: '' }
  const [formValues, setFormValues] = useState(defaultValues)
  const [message, setMessage] = useState([] as any)

  useEffect(() => {
    if (message === 0) {
      setMessage('')
    }
  }, [message])

  useEffect(() => {
    if (user && user.id) {
      setFormValues(user)
    } else {
      setFormValues(defaultValues)
    }
  }, [user && user.id])

  const handleInputChange = (event) => {
    if (event.target.name === 'password') {
      setMessage(validations(event))
    } else {
      validations(event)
    }

    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = () => {
    const { name, email, password, role_id } = formValues
    validations(null, {
      value: name,
      required: true,
      id: 'name'
    })
    validations(null, {
      value: email,
      required: true,
      id: 'email'
    })
    validations(null, {
      value: role_id,
      required: true,
      id: 'role_id'
    })

    if (!user) {
      validations(null, {
        value: password,
        required: true,
        id: 'password'
      })
    }
    if (checkInputValidity() === 0) {
      if (user) {
        dispatch(userStartUpdating(formValues, toggle))
      } else {
        dispatch(userStartAddNew(formValues, toggle))
      }
      setFormValues(defaultValues)
      toggle()
    }
  }

  return (
    <Modal data-test="component-user-modal" isOpen={isOpenModal} size="lg">
      <ModalHeader toggle={toggle}></ModalHeader>
      <h2 className="text-center">{user ? 'Editar usuario' : 'Nuevo usuario'}</h2>
      <div className="container">
        <Row className="mt-4">
          <Col md="1"></Col>
          <Col md="10">
            <TextInput
              placeholder="Nombre"
              name="name"
              id="name"
              labelText={''}
              value={formValues.name}
              onChange={handleInputChange}
            />
            <TextInput
              placeholder="Email"
              name="email"
              id="email"
              labelText={''}
              value={formValues.email}
              onChange={handleInputChange}
            />
            {!user && (
              <>
                <TextInput
                  placeholder="Contraseña"
                  name="password"
                  id="password"
                  labelText={''}
                  value={formValues.password}
                  onChange={handleInputChange}
                />
                {message && <span className="password-message">{message}</span>}
              </>
            )}
            <Select
              name="role_id"
              id="role_id"
              onChange={handleInputChange}
              defaultValue={formValues.role_id}
              labelText={false}
            >
              <SelectItem disabled hidden value="" text="Rol" />
              {roles &&
                roles.map((role) => (
                  <SelectItem
                    className="capitalize"
                    key={role.id}
                    value={role.id}
                    text={role.name}
                  />
                ))}
            </Select>
          </Col>
        </Row>
        <ModalButtons
          textPrev={'Cancelar'}
          actionPrev={toggle}
          textNext={user ? 'Guardar' : 'Crear usuario'}
          actionNext={handleSubmit}
          icons={false}
        />
      </div>
    </Modal>
  )
}
