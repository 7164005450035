import React from 'react'
import { useSelector, useStore } from 'react-redux'
import { RootState } from '../reducers/rootReducer'
import { UseStoreFilters } from './UseStoreFilters'

const UseByProjectReports = () => {
  const { accounts, currency, projects, categories } = UseStoreFilters()
  const {
    total,
    totalPeriodsPerDay,
    totalPeriodsPerMonth,
    resourceTypes,
    infrastructureType,
    assigned
  }: any = useSelector((store: RootState) => store.reportsByProject)

  return {
    accounts,
    currency,
    projects,
    categories,
    total,
    totalPeriodsPerDay,
    totalPeriodsPerMonth,
    resourceTypes,
    infrastructureType,
    assigned
  }
}

export { UseByProjectReports }
