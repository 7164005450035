import { types } from '../types/types'

const initialState = {
  accounts: [] as any,
  date: '',
  message: '',
  data: {}
}

export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.accountLoaded:
      return {
        ...state,
        accounts: [...action.payload],
        message: ''
      }

    case types.accountAddNew:
      return {
        ...state,
        accounts: [...state.accounts, action.payload],
        message: ''
      }

    case types.accountUpdated:
      return {
        ...state,
        accounts: state.accounts.map((e) => (e.id === action.payload.id ? action.payload : e)),
        message: ''
      }

    case types.accountDeleted:
      return {
        ...state,
        accounts: state.accounts.filter((e) => e.id !== action.payload.id),
        message: ''
      }

    case types.accountChecked:
      return {
        ...state,
        date: action.payload
      }

    case types.accountMessage:
      return {
        ...state,
        message: action.payload.body.message,
        data: action.payload.formData
      }

    default:
      return state
  }
}
