import { types } from '../types/types'
import { fetchWithToken, fecthFileWithToken } from '../helpers/fetch'
import Swal from 'sweetalert2'
import correct from '../assets/img/checkmark--outline.svg'
import {api} from '../services/api'

let file: any
export const setFile = (binaryFile) => {
  file = binaryFile
}
export const getFile = () => {
  return file
}

export const accountStartLoading = (type) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`accounts?type=${type}`, {}, 'GET')
      const body = await resp.json()
      dispatch(accountLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const allAccountStartLoading = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`accounts?isActive=all`, {}, 'GET')
      const body = await resp.json()
      dispatch(accountLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const activeAccountStartLoading = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`accounts`, {}, 'GET')
      const body = await resp.json()
      dispatch(accountLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const accountStartAddNew = (formData, toggle, setIsSubmitting?) => {
  return async (dispatch) => {
    try {
      const {status, data} = await api.accounts.addAcount(formData)
      if (status === 201) {
        Swal.fire({
          imageUrl: correct,
          title: 'Su cuenta ha sido añadida con éxito!',
          text: 'Se iniciará el proceso de sincronización de gastos',
          customClass: {
            image: 'image-success image-size'
          },
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            api.accounts.syncAccount(data.id,formData)
          }
        })
        await dispatch(accountAddNew(data))
        toggle()
      } else {
        if(setIsSubmitting) {setIsSubmitting(false)}
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const accountStartAddNewGoogle = (formData, toggle, file) => {
  return async (dispatch) => {
    try {
      const {status, data} = await api.accounts.addAcount(formData, file)
      if (status === 201) {
        Swal.fire({
          imageUrl: correct,
          title: 'Su cuenta ha sido añadida con éxito!',
          text: 'Se iniciará el proceso de sincronización de gastos',
          customClass: {
            image: 'image-success image-size'
          },
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            api.accounts.syncAccount(data.id,formData)
          }
        })
        await dispatch(accountAddNew(data))
        toggle()
      } 
    } catch (error) {
      console.log(error)
    }
  }
}

export const accountStartUpdating = (formData, toggle, file, setIsSubmitting?) => {
  return async (dispatch) => {
    try {
      let resp
      const url = `accounts/${formData.id}`
      if (formData.cloudType === 'GOOGLE') {
        resp = await api.accounts.updateAccount(formData, file)
      } else {
        resp = await api.accounts.updateAccount(formData)
      }
      if(resp.status == 200){
        await dispatch(accountUpdated(resp.data))
        toggle()
        Swal.fire({
          imageUrl: correct,
          title: 'Su cuenta ha sido actualizada con éxito!',
          customClass: {
            image: 'image-success image-size'
          }
        })
      }else{
        setIsSubmitting(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const accountStartDeleting = (id) => {
  return async (dispatch) => {
    try {
      const data: IData = {}
      if (id) Object.assign(data, { id })
      const resp = await fetchWithToken(`accounts/${data.id}`, data, 'DELETE')
      const body = await resp.json()
      await dispatch(accountDeleted(body))

      Swal.fire({
        imageUrl: correct,
        title: 'Su cuenta ha sido eliminada con éxito!',
        customClass: {
          image: 'image-success image-size'
        }
      })
    } catch (error) {
      return error
    }
  }
}

export const accountStartDeactivate = (id, getAll?) => {
  return async (dispatch) => {
    try {
      const {data} = await api.accounts.deactivateAccount(id)
      if (getAll) {
        await dispatch(accountUpdated(data))
      } else {
        await dispatch(accountDeleted(data))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const accountStartActivate = (id) => {
  return async (dispatch) => {
    try {
      const {data} = await api.accounts.activateAccount(id)
      await dispatch(accountUpdated(data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const accountStartChecking = (formData, next, setIsSubmitting, file) => {
  return async (dispatch) => {
    try {
      let resp
      if (formData.cloudType === 'GOOGLE') {
        resp = await fecthFileWithToken('accounts/check-account', file, formData, 'POST')
      } else {
        resp = await fetchWithToken('accounts/check-account', formData, 'POST')
      }
      const body = await resp.json()
      if (resp.status === 200) {
        await dispatch(accountCheck(body))
        next()
      } else {
        const mssg = await dispatch(accountMessage(body, formData))
        if (mssg) {
          setIsSubmitting(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const accountLoaded = (accounts) => {
  if (accounts.length) {
    return { type: types.accountLoaded, payload: accounts }
  }
  return { type: types.noAccount, payload: [] }
}


const accountAddNew = (body) => ({
  type: types.accountAddNew,
  payload: body
})

const accountUpdated = (body) => ({ type: types.accountUpdated, payload: body })

const accountDeleted = (body) => ({ type: types.accountDeleted, payload: body })

const accountCheck = (body) => ({
  type: types.accountChecked,
  payload: body
})

const accountMessage = (body, formData) => ({
  type: types.accountMessage,
  payload: { body, formData }
})
interface IData {
  id?: number | string
}

export {
  accountLoaded,
  accountAddNew,
  accountUpdated,
  accountDeleted,
  accountCheck,
  accountMessage
}
