import { types } from '../types/types'

const initialState = {
  total: [] as any,
  totalPeriodsPerMonth: [] as any,
  totalPeriodsPerDay: [] as any,
  accounts: [] as any,
  categories: [] as any,
  resourceTypes: [] as any,
  infrastructureType: [] as any,
  assigned: [] as any
}

export const dashboardByProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.byProjectTotalLoaded:
      return {
        ...state,
        total: action.payload
      }

    case types.byProjectTotalPeriodsPerMonthLoaded:
      return {
        ...state,
        totalPeriodsPerMonth: action.payload
      }

    case types.byProjectTotalPeriodsPerDayLoaded:
      return {
        ...state,
        totalPeriodsPerDay: action.payload
      }
    case types.byProjectAccountsLoaded:
      return {
        ...state,
        accounts: [...action.payload]
      }
    case types.byProjectCategoriesLoaded:
      return {
        ...state,
        categories: [...action.payload]
      }

    case types.byProjectResourceTypesLoaded:
      return {
        ...state,
        resourceTypes: [...action.payload]
      }

    case types.byProjectInfrastructureTypeLoaded:
      return {
        ...state,
        infrastructureType: [...action.payload]
      }

    case types.byProjectAssignedLoaded:
      return {
        ...state,
        assigned: [...action.payload]
      }

    default:
      return state
  }
}
