import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setResourceDragging } from '../../../actions/resource'
import { RootState } from '../../../reducers/rootReducer'

const draggingStyle = {
  opacity: 0.25
}

const Drag = (props) => {
  const dispatch = useDispatch()

  const { isResourceDragging } = useSelector((store: RootState) => store.resources)

  const [isDragging, setIsDragging] = useState(false)
  const startDrag = (ev) => {
    if (!isResourceDragging) {
      dispatch(setResourceDragging(true))
    }
    setIsDragging(true)
    ev.dataTransfer.setData('drag-item', JSON.stringify(props.dataItem))
  }

  const dragEnd = () => {
    dispatch(setResourceDragging(false))
    setIsDragging(false)
  }
  return (
    <div
      style={isDragging ? draggingStyle : {}}
      draggable
      onDragStart={startDrag}
      onDragEnd={dragEnd}
      className="cursor"
    >
      {props.children}
    </div>
  )
}

Drag.propTypes = {
  dataItem: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export default Drag
