import { types } from '../types/types'

const initialState = {
  unassignedExpenses: [] as any,
  expensesProjectPerMonth: [] as any,
  expensesProjectPerDay: [] as any,
  expensesAccount: [] as any,
  expensesCategories: [] as any,
  expensesAndPercentilProjects: [] as any,
  totalAmounts: [] as any
}

export const dashboardByDateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.byDateUnassignedExpensesLoaded:
      return {
        ...state,
        unassignedExpenses: [...action.payload]
      }

    case types.byDateExpensesProjectLoadedPerMonth:
      return {
        ...state,
        expensesProjectPerMonth: action.payload
      }

    case types.byDateExpensesProjectLoadedPerDay:
      return {
        ...state,
        expensesProjectPerDay: action.payload
      }

    case types.byDateExpensesAccountLoaded:
      return {
        ...state,
        expensesAccount: [...action.payload]
      }

    case types.byDateExpensesCategoriesLoaded:
      return {
        ...state,
        expensesCategories: [...action.payload]
      }

    case types.byDateExpensesAndPercentilProjectsLoaded:
      return {
        ...state,
        expensesAndPercentilProjects: [...action.payload]
      }

    case types.byDateTotalAmountsLoaded:
      return {
        ...state,
        totalAmounts: action.payload
      }

    default:
      return state
  }
}
