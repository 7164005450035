import { api } from './api'
import { clearToken, getRenewToken, getToken, setToken } from '../utils/Auth'
import { fireAlert } from '../utils/Alert'
import { ALERT_TYPE } from '../common/constants'


const redirect = (redirectUrl) => {
  window.location = redirectUrl
}

const redirectLogin = () => {
  setTimeout(() => {
    redirect('/login')
  }, 4000)
}

const signIn = async (payload): Promise<any> => {
  const { data } = await api.auth.signIn(payload)
  return data
}

const signUp = async (payload): Promise<any> => {
  const { data, status } = await api.auth.signUp(payload)
  if (status === 200) {
    fireAlert(ALERT_TYPE.USER_ADDED)
  }
  return data
}

const checkToken = async (): Promise<any> => {
  const email = localStorage.getItem('email')
  const payload = { email: email, access_token: getToken() }
  const { data, status } = await api.auth.checkToken(payload)
  if (!data.valid) {
    await renewToken()
  }
  return data
}

const renewToken = async (): Promise<any> => {
  const email = localStorage.getItem('email')
  const payload = {
    email: email,
    access_token: getToken(),
    renew_token: getRenewToken()
  }
  const { data, status } = await api.auth.renewToken(payload)

  if (status === 401) {
    localStorage.clear()
    clearToken()
  } else {
    await setToken(data.access_token) 
  }

  return data
}

const logout = (): Promise<any> => {
  return fireAlert(ALERT_TYPE.LOGOUT_CONFIRMATION)
}

const recoverPassword = async (payload): Promise<any> => {
  const { data, status } = await api.auth.recoverPassword(payload)

  if (status === 200) {
    fireAlert(ALERT_TYPE.RECOVER_PASSWORD).then(() => {
      redirectLogin()
    })
  }
  return data
}

const resetPassword = async (url, payload): Promise<any> => {
  const { data, status } = await api.auth.resetPassword(url, payload)

  if (status === 200) {
    fireAlert(ALERT_TYPE.PASSWORD_UPDATED).then(() => {
      redirectLogin()
    })
  }
  return data
}
export { signIn, signUp, checkToken, renewToken, recoverPassword, resetPassword, logout, redirect }
