import { types } from '../types/types'

const initialState = {
  resources: [] as any,
  isResourceDragging: false,
  selectedResource: {},
  resourceCategories: [],
  dates: {},
  periods: {},
  resource: {},
  projects:[]
}

export const resourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.resourceLoaded:
      return { ...state, ...(action.payload || []) }

    case types.noResources:
      return {
        ...state,
        resources: []
      }
    case types.isResourceDragging:
      return {
        ...state,
        isResourceDragging: action.isResourceDragging
      }
    case types.selectedResource:
      return {
        ...state,
        selectedResource: action.selectedResource
      }
    case types.categoriesLoaded:
      return {
        ...state,
        resourceCategories: [...action.payload]
      }

    case types.resourceUpdated:
      return {
        ...state,
        resources: state.resources.map((e) =>
          e.id === action.payload.id && e.period === action.payload.period ? action.payload : e
        )
      }

    case types.onPremiseAddNew:
      return {
        ...state,
        resources: [...state.resources, action.payload]
      }

    case types.datesLoaded:
      return {
        ...state,
        dates: action.payload
      }

    case types.resourcePeriodsLoaded:
      return {
        ...state,
        periods: action.payload
      }

    case types.resourcePeriodCostLoaded:
      return {
        ...state,
        resource: action.payload
      }

    case types.resourceDeleted:
      return {
        ...state,
        resources: state.resources.filter((e) => e.id !== action.payload.id)
      }

    case types.addResourcesProject:
      return {
        ...state,
        projects: action.payload
      }

    default:
      return state
  }
}
