import React, { useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import './DatePicker.scss'
import es from 'date-fns/locale/es'
registerLocale('es', es)
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux'
import { resourceStartLoading, datesLoaded } from '../../../actions/resource'
import { projectStartLoading } from '../../../actions/projects'
import { RootState } from '../../../reducers/rootReducer'
import { CalendarHeatMap32 } from '@carbon/icons-react'
import moment from 'moment'
import 'moment/locale/es'

export const AccountDatePicker = ({ accountId, filterCategory, filterProject }) => {
  const { dates }: any = useSelector((store: RootState) => store.resources)
  const fromEdit = dates.from && moment(dates.from).add(1, 'M')
  const [startDate, setStartDate] = useState(dates.from && new Date(fromEdit))
  const [endDate, setEndDate] = useState(dates.from && new Date(dates.to))
  const dispatch = useDispatch()

  const onChangeDate = (datesChange) => {
    const [start, end] = datesChange
    let to = ''
    setStartDate(start)
    setEndDate(end)
    const from = `${start.getFullYear()}-${start.getMonth() + 1}-01`
    if (end) {
      to = `${end.getFullYear()}-${end.getMonth() + 1}-01`
    } else {
      to = `${start.getFullYear()}-${start.getMonth() + 1}-01`
    }
    dispatch(resourceStartLoading(accountId, from, to, filterProject, filterCategory))
    dispatch(projectStartLoading(from, to))
    dispatch(datesLoaded(from, to))
  }

  return (
    <div className="content-datepicker" data-test="component-datepicker">
      <DatePicker
        selected={endDate ? endDate : startDate}
        onChange={onChangeDate}
        shouldCloseOnSelect={false}
        locale="es"
        showMonthYearPicker
        startDate={startDate}
        endDate={endDate}
        selectsRange
        customInput={
          <button onClick={() => 'open'} className="date-picker-custom">
            <span>
              {`${moment(startDate).format('MMM yyyy')} / ${moment(
                endDate ? endDate : startDate
              ).format('MMM yyyy')}`}{' '}
              <CalendarHeatMap32 />
            </span>
          </button>
        }
      />
    </div>
  )
}
