import React, { useState } from 'react'
import toggleSelected from '../../assets/img/toggle_selected.svg'
import toggleNotSelected from '../../assets/img/toggle_not_selected.svg'
/* import toggleNotSelectedBlue from '../../assets/img/toggle_ns_blue.svg' */
import toggleSelectedLeftBlue from '../../assets/img/icon-active-itspend-blue-left.svg'
import toggleSelectedRightBlue from '../../assets/img/icon-active-itspend-blue-right.svg'

export const ToggleArrow = ({ open }) => (
  <div
    style={{
      border: '1.5px solid grey',
      borderRight: 'none',
      borderBottom: 'none',
      height: '8px',
      width: '8px',
      transform: open ? 'rotate(45deg)' : 'rotate(225deg)',
      transitionDuration: '90ms',
      margin: '5px',
      float: 'right',
      marginRight: '10px'
    }}
  />
)

export const CurrencyIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={color} width="32" height="32" aria-hidden="true">
    <path d="M21 12v-2h-4V7h-2v3h-2a2.002 2.002 0 0 0-2 2v3a2.002 2.002 0 0 0 2 2h6v3h-8v2h4v3h2v-3h2a2.002 2.002 0 0 0 2-2v-3a2.002 2.002 0 0 0-2-2h-6v-3Z" />
    <path d="M16 4A12 12 0 1 1 4 16 12.035 12.035 0 0 1 16 4m0-2a14 14 0 1 0 14 14A14.041 14.041 0 0 0 16 2Z" />
  </svg>
)

export const TotalExpenseIcon = () => (
  <div
    style={{
      borderRadius: '50%',
      background: '#cfe0fe',
      height: '48px',
      width: '48px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <CurrencyIcon color={'#1166f8'} />
  </div>
)

export const UnassignedExpenseIcon = () => (
  <div
    style={{
      borderRadius: '50%',
      background: '#f3e0e0',
      height: '48px',
      width: '48px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <CurrencyIcon color={'#fb9785'} />
  </div>
)

export const ArrowDownIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 90"
      fill={color}
      width="42"
      height="42"
      aria-hidden="true"
    >
      <path d="M24.59 16.59 17 24.17V4h-2v20.17l-7.59-7.58L6 18l10 10 10-10-1.41-1.41z" />
    </svg>
  )
}

export const BasicToggleable = () => {
  const [active, setActive] = useState<boolean>(false)
  const styles = {
    WebkitTransition: 'opacity 1s ease-in-out',
    transition: 'opacity 4s ease-in-out',
    width: '35px'
  }

  return (
    <div>
      {!active ? (
        <img
          style={styles}
          onDragStart={() => false}
          onDrop={() => false}
          src={toggleNotSelected}
          onClick={() => setActive(true)}
        />
      ) : (
        <img
          style={styles}
          onDragStart={() => false}
          onDrop={() => false}
          src={toggleSelected}
          onClick={() => setActive(false)}
        />
      )}
    </div>
  )
}
export const DayMonthToggleable = ({ onClickFunction }) => {
  const [active, setActive] = useState<boolean>(false)
  const styles = {
    WebkitTransition: 'opacity 1s ease-in-out',
    transition: 'opacity 4s ease-in-out',
    width: '35px',
    marginTop: '3px'
  }

  return (
    <div>
      {!active ? (
        <img
          style={styles}
          onDragStart={() => false}
          onDrop={() => false}
          src={toggleSelectedLeftBlue}
          onClick={() => {
            onClickFunction()
            setActive(true)
          }}
        />
      ) : (
        <img
          style={styles}
          onDragStart={() => false}
          onDrop={() => false}
          src={toggleSelectedRightBlue}
          onClick={() => {
            onClickFunction()
            setActive(false)
          }}
        />
      )}
    </div>
  )
}

export const UpDownToggleable = ({ active, setActive }) => {
  const styles = {
    WebkitTransition: 'opacity 1s ease-in-out',
    transition: 'opacity 4s ease-in-out',
    width: '35px',
    transform: 'rotate(90deg)'
  }

  return (
    <div>
      {!active ? (
        <img
          style={styles}
          onDragStart={() => false}
          onDrop={() => false}
 /*          src={toggleNotSelectedBlue} */
          onClick={() => setActive(true)}
        />
      ) : (
        <img
          style={styles}
          onDragStart={() => false}
          onDrop={() => false}
          src={toggleSelected}
          onClick={() => setActive(false)}
        />
      )}
    </div>
  )
}

export const DeleteIcon = () => {
  return (
    <svg
      focusable="false"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      aria-hidden="true"
      className="trash"
    >
      <path d="M12 12H14V24H12zM18 12H20V24H18z"></path>
      <path d="M4 6V8H6V28a2 2 0 002 2H24a2 2 0 002-2V8h2V6zM8 28V8H24V28zM12 2H20V4H12z"></path>
    </svg>
  )
}

export const NotificationIcon = () => {
  return (
    <svg
      focusable="false"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      aria-hidden="true"
    >
      <path d="M28.7071,19.293,26,16.5859V13a10.0136,10.0136,0,0,0-9-9.9492V1H15V3.0508A10.0136,10.0136,0,0,0,6,13v3.5859L3.2929,19.293A1,1,0,0,0,3,20v3a1,1,0,0,0,1,1h7v.7768a5.152,5.152,0,0,0,4.5,5.1987A5.0057,5.0057,0,0,0,21,25V24h7a1,1,0,0,0,1-1V20A1,1,0,0,0,28.7071,19.293ZM19,25a3,3,0,0,1-6,0V24h6Zm8-3H5V20.4141L7.707,17.707A1,1,0,0,0,8,17V13a8,8,0,0,1,16,0v4a1,1,0,0,0,.293.707L27,20.4141Z"></path>
    </svg>
  )
}

export const EditIcon = ({ currentColor }) => {
  return (
    <svg
      focusable="false"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      fill={`${currentColor}`}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      aria-hidden="true"
    >
      <path d="M22,22v6H6V4H16V2H6A2,2,0,0,0,4,4V28a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V22Z"></path>
      <path d="M29.54,5.76l-3.3-3.3a1.6,1.6,0,0,0-2.24,0l-14,14V22h5.53l14-14a1.6,1.6,0,0,0,0-2.24ZM14.7,20H12V17.3l9.44-9.45,2.71,2.71ZM25.56,9.15,22.85,6.44l2.27-2.27,2.71,2.71Z"></path>
    </svg>
  )
}
