import Cookies from 'universal-cookie'
const cookies = new Cookies()
export const getToken = () => {
  const existToken = cookies.get('access_token')
  if (!existToken) return
  return existToken
}

export const getRenewToken = () => {
  const existToken = cookies.get('renew_token')
  return existToken ? existToken : ''
}

export const setToken = (token) => {
  cookies.remove('access_token')
  return cookies.set('access_token', token)
}

export const setBrowserData = (data) => {
  const userData = {
    token: data.token,
    renewToken: data.renew_token,
    email: data.email,
    companyId: data.company_id,
    rol: data.role
  }
  cookies.set('access_token', data.access_token)
  cookies.set('renew_token', data.renew_token)
  cookies.set('company_id', data.company_id)
  localStorage.setItem('email', data.email)
  localStorage.setItem('company_id', data.company_id)
  localStorage.setItem('rol', data.role)

  return userData
}

export const clearToken = () => {
  cookies.remove('access_token')
  cookies.remove('renew_token')
}

export const getLanguageFullName = (abbreviation: string | null) => {
  const abbr = abbreviation?.substring(0, 2)
  const matchLanguage = {
    en: 'English',
    es: 'Español',
    de: 'Deutsche',
    pt: 'Português'
  }
  if (abbr) return matchLanguage[abbr]
  return null
}

export const getRoleFullName = (abbreviation: string | null) => {
  const matchRoll = {
    ROLE_administrator: 'Administrador',
    ROLE_manager: 'Manager',
    ROLE_operator: 'Operador'
  }
  if (abbreviation) return matchRoll[abbreviation]
  return null
}

export const getUserData = () => {
  const userData = {
    companyId: Number(localStorage.getItem('company_id')),
    company: localStorage.getItem('company_name'),
    name: localStorage.getItem('user_name'),
    email: localStorage.getItem('email'),
    role: getRoleFullName(localStorage.getItem('rol')),
    language: getLanguageFullName(localStorage.getItem('language')),
    token: localStorage.getItem('access_token'),
    renewToken: localStorage.getItem('renew_token')
  }

  return userData
}

export const clearBrowserData = () => {
  localStorage.clear()
  cookies.remove('access_token', { path: '/' })
  cookies.remove('renew_token', { path: '/' })
  cookies.remove('company_id', { path: '/' })
}
