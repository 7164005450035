import { types } from '../types/types'

const initialState = {
  projects: [] as any
}

export const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.projectLoaded:
      return {
        ...state,
        projects: [...action.payload]
      }
    case types.projectAddNew:
      return {
        ...state,
        projects: [...state.projects, action.payload]
      }

    case types.projectUpdated:
      return {
        ...state,
        projects: state.projects.map((e) => (e.id === action.payload.id ? action.payload : e))
      }

    case types.projectDeleted:
      return {
        ...state,
        projects: state.projects.filter((e) => e.id !== action.payload.id)
      }

    default:
      return state
  }
}
