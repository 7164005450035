import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { checkInputValidity, validations } from '../../../utils/Validations'
import CreatableSelect from 'react-select/creatable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons'
import {
  TextInput,
  NumberInput,
  TextArea,
  ProgressIndicator,
  ProgressStep,
  Button
} from 'carbon-components-react'
import { ArrowRight32, Warning32 } from '@carbon/icons-react'
import Select from 'react-select'
import { currencies } from '../../../assets/currency.js'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'transparent'
    }
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '1rem'
  })
}

export const ResourceType = ({ setForm, formData, navigation, categories, type, title }) => {
  const { amount, name, category, description, alias, id, currency } = formData
  const { next } = navigation
  const listOptions = [] as any
  const listCurrency = [] as any

  useEffect(() => {
    categories
      .filter((categoryItem) => categoryItem.id)
      .map((item) =>
        listOptions.push({
          value: item.name,
          label: item.name,
          id: item.id,
          type: item.type,
          name: item.name
        })
      )
    currencies.forEach((item) =>
      listCurrency.push({
        value: item.code,
        label: `(${item.code}) ${item.name}`
      })
    )
  }, [listOptions])

  const handleSubmit = () => {
    if (type !== 'cloud') {
      validations(null, {
        value: amount,
        required: true,
        id: 'amount'
      })
      validations(null, {
        value: currency,
        required: true,
        id: 'currency'
      })
    }
    validations(null, {
      value: type === 'cloud' ? alias : name,
      required: true,
      id: type === 'cloud' ? 'alias' : 'name'
    })
    if (checkInputValidity() === 0) {
      next()
    }
  }

  const handleChange = (e, nameItem) => {
    let nameValue = {}

    if (nameItem === 'category') {
      const companyId = localStorage.getItem('company_id')

      nameValue = e
      validations(null, {
        value: e ? e.value : e,
        className: 'select-required',
        id: 'category'
      })
      if (e && !e.name) {
        nameValue = {
          name: e.value,
          type: type.toUpperCase(),
          company_id: companyId
        }
      }
    } else if (nameItem === 'currency') {
      nameValue = e ? e.value : null
      validations(null, {
        value: e ? e.value : e,
        className: 'select-required',
        id: 'currency'
      })
    } else {
      nameValue = e.target.value
      validations(e)
    }

    setForm({
      target: {
        name: nameItem,
        value: nameValue
      }
    })
  }

  const onChangeAlias = () => {
    setForm({
      target: {
        name: 'alias',
        value: name
      }
    })
  }

  return (
    <div className="container">
      <Row>
        <Col md="1"></Col>
        <Col md="10">
          <h2 className="text-center">{title}</h2>
          {type === 'cloud' ? (
            <Row className="mt-4">
              <Col md="11" className="pr-0">
                <TextInput
                  data-modal-primary-focus
                  placeholder="Título"
                  name="alias"
                  id="alias"
                  hideLabel={true}
                  labelText={''}
                  value={alias}
                  onChange={(e) => handleChange(e, 'alias')}
                  required
                />
              </Col>
              <Col md="1" className="pl-0">
                <Button className="btnSubmit" onClick={() => onChangeAlias()}>
                  <FontAwesomeIcon icon={faUndoAlt as IconProp} />
                </Button>
              </Col>
            </Row>
          ) : (
            <TextInput
              data-modal-primary-focus
              placeholder="Título"
              name="name"
              labelText={''}
              id="name"
              hideLabel={true}
              value={name}
              onChange={(e) => handleChange(e, 'name')}
              required
              className="mt-4"
            />
          )}

          {type !== 'cloud' && (
            <Row>
              <Col md="6">
                <NumberInput
                  min={0}
                  name="amount"
                  id="amount"
                  placeholder="Monto"
                  readOnly={type === 'cloud' ? true : false}
                  value={amount !== 0 ? amount : ''}
                  onChange={(e) => handleChange(e, 'amount')}
                />
              </Col>
              <Col md="6" className="pr-0">
                <Select
                  name="currency"
                  id="currency"
                  className="coin-select bx--text-input"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  options={listCurrency}
                  placeholder="Moneda"
                  onChange={(e) => handleChange(e, 'currency')}
                  styles={colourStyles}
                  value={
                    currency && {
                      value: currency,
                      label: currency
                    }
                  }
                />
              </Col>
            </Row>
          )}

          <CreatableSelect
            isClearable={true}
            onChange={(e) => handleChange(e, 'category')}
            options={listOptions}
            placeholder="Categoría"
            name="category"
            id="category"
            className="input-category bx--text-input"
            formatCreateLabel={(userInput) => `Crear categoría: ${userInput}`}
            styles={colourStyles}
            value={
              category && {
                value: category.name,
                label: category.name
              }
            }
            noOptionsMessage={() => `No hay categorías creadas`}
          />

          <TextArea
            placeholder="Descripción (opcional)"
            name="description"
            value={description}
            onChange={setForm}
            cols={50}
            rows={4}
            labelText={false}
          />
          {id && (
            <p className="message black">
              <Warning32 /> Si modificas uno de estos datos lo verás reflejado en todos los
              períodos.
            </p>
          )}
        </Col>
      </Row>
      <Row className="mt-3 progress-background">
        <Col md="1"></Col>
        <Col md="10">
          {type !== 'cloud' ? (
            <ProgressIndicator
              className="mt-4"
              vertical={false}
              currentIndex={0}
              spaceEqually={true}
            >
              <ProgressStep label="Tipo y cantidad" secondaryLabel="Paso 1" />
              <ProgressStep label="Periodicidad" secondaryLabel="Paso 2" />
              <ProgressStep label="Proyecto" secondaryLabel="Paso 3" />
            </ProgressIndicator>
          ) : (
            <ProgressIndicator
              className="mt-4"
              vertical={false}
              currentIndex={0}
              spaceEqually={true}
            >
              <ProgressStep label="Tipo y cantidad" secondaryLabel="Paso 1" />
              <ProgressStep label="Proyecto" secondaryLabel="Paso 2" />
            </ProgressIndicator>
          )}
        </Col>
      </Row>

      <Row className="buttons">
        <Col md="6"></Col>
        <Col md="6" className="p-0">
          <Button
            renderIcon={ArrowRight32}
            iconDescription="Icon Description"
            onClick={() => handleSubmit()}
          >
            Siguiente{' '}
          </Button>
        </Col>
      </Row>
    </div>
  )
}
