import { AxiosResponse } from 'axios'
import { GROUP_BY } from 'common/constants'
import { filtersAtURL } from 'utils/Functions'
import { IAccount } from '../common/models'
import { apiClient } from './ApiAxiosService'

export const api = {
  accounts: {
    getAccountsByType: (type): Promise<AxiosResponse> => {
      return apiClient.get(`accounts?type=${type}`)
    },
    getAllAccounts: (): Promise<AxiosResponse> => {
      return apiClient.get('accounts?isActive=all')
    },
    getActiveAccounts: (): Promise<AxiosResponse<IAccount[]>> => {
      return apiClient.get('accounts')
    },
    addAcount: (formData, file?): Promise<AxiosResponse> => {
      const body = new FormData()
      Object.entries(formData).forEach(([key]) => {
        body.append(key, formData[key])
      })
      if (file && file.name) {
        body.append('google_credential', file)
      }
      return apiClient.post('accounts', body)
    },
    updateAccount: (formData, file?): Promise<AxiosResponse> => {
      const body = new FormData()
      Object.entries(formData).forEach(([key]) => {
        body.append(key, formData[key])
      })
      if (file && file.name) {
        body.append('google_credential', file)
      }
      return apiClient.put(`accounts/${formData.id}`, body, file)
    },
    deleteAccount: (id): Promise<AxiosResponse> => {
      return apiClient.delete('accounts', id)
    },
    activateAccount: (data): Promise<AxiosResponse> => {
      return apiClient.put(`accounts/${data}/activate`)
    },
    deactivateAccount: (id): Promise<AxiosResponse> => {
      return apiClient.put(`accounts/${id}/deactivate`)
    },
    checkAccount: (file, formData): Promise<AxiosResponse> => {
      return apiClient.post('accounts/check-account', file, formData)
    },
    getResourcesByAccountAndPeriod: (accountId, from, to): Promise<AxiosResponse> => {
      return apiClient.get(`accounts/${accountId}/resources?from=${from}&to=${to}`)
    },
    getSubscriptionsByAccount: (accountId): Promise<AxiosResponse> => {
      return apiClient.get(`accounts/${accountId}/subscriptions`)
    },
    syncAccount: (accountId, formData): Promise<AxiosResponse> => {
      return apiClient.put(`accounts/${accountId}/sync`, formData)
    }
  },
  currency: {
    getCurrencies: (): Promise<AxiosResponse> => {
      return apiClient.get(`currencies`)
    }
  },
  projects: {
    getProjectsByPeriod: (from, to): Promise<AxiosResponse> => {
      return apiClient.get(`projects?from=${from}&to=${to}`)
    },
    getAllActiveProjects: (): Promise<AxiosResponse> => {
      return apiClient.get(`projects?isActive=all`)
    },
    getAllProjects: (): Promise<AxiosResponse> => {
      return apiClient.get('projects')
    },
    addProject: (formData): Promise<AxiosResponse> => {
      return apiClient.post('projects', formData)
    },
    updateProject: (id, formData): Promise<AxiosResponse> => {
      return apiClient.put(`projects/${id}`, formData)
    },
    activateProject: (id): Promise<AxiosResponse> => {
      return apiClient.put(`projects/${id}`)
    },
    deactivateProject: (id): Promise<AxiosResponse> => {
      return apiClient.put(`projects/${id}`)
    },
    deleteProject: (id): Promise<AxiosResponse> => {
      return apiClient.delete(`projects/${id}`)
    }
  },
  resources: {
    getResourceCategoriesByPeriod: (accountId, from, to, data): Promise<AxiosResponse> => {
      return apiClient.get(`resources/${accountId}?from=${from}&to=${to}`, data)
    },
    getPeriodCostById: (periodCostId): Promise<AxiosResponse> => {
      return apiClient.get(`resources/periodCostresources/${periodCostId}`)
    },
    updateResourceByPeriod: (from, to, resourceData): Promise<AxiosResponse> => {
      return apiClient.put(`resources?from=${from}&to=${to}`, resourceData)
    },
    updateResourceTypeById: (id): Promise<AxiosResponse> => {
      return apiClient.put(`resources/${id}`)
    },
    deleteResourceTypeById: (id): Promise<AxiosResponse> => {
      return apiClient.delete(`resources/${id}`)
    }
  },
  categories: {
    getAllResourceCategories: (): Promise<AxiosResponse> => {
      return apiClient.get('categories')
    },
    getResourceCategoriesByType: (type): Promise<AxiosResponse> => {
      return apiClient.get(`categories?type=${type}`)
    }
  },
  dashboardByDate: {
    getTotalAmounts: (filters): Promise<AxiosResponse> => {
      return apiClient.get(
        `reports/by-date/total-expenses?${filtersAtURL(filters)}`)
    },
    getUnassignedExpenses: (filters): Promise<AxiosResponse> => {
      return apiClient.get(`reports/by-date/unassigned-expenses?${filtersAtURL(filters)}`)
    },
    getProjectExpenses: (filters): Promise<AxiosResponse> => {
      return apiClient.get(`reports/by-date/project-expenses?${filtersAtURL(filters)}`)
    },
    getExpensesAcocunt: (filters): Promise<AxiosResponse> => {
      return apiClient.get(
        `reports/by-date/percentil-expenses?${filtersAtURL(filters)}&groupBy=${GROUP_BY.ACCOUNT}`,
        filters
      )
    },
    getExpensesCategories: (filters): Promise<AxiosResponse> => {
      return apiClient.get(
        `reports/by-date/percentil-expenses?${filtersAtURL(filters)}&groupBy=${GROUP_BY.CATEGORIES}`,
        filters
      )
    },
    getExpensesAndPercentilProjects: (filters): Promise<AxiosResponse> => {
      return apiClient.get(`reports/by-date/percentil-projects-expenses?${filtersAtURL(filters)}`)
    }
  },
  dashboardByProject: {
    getTotalAmounts: (filters): Promise<AxiosResponse> => {
      return apiClient.get(`reports/by-project/total?${filtersAtURL(filters)}`)
    },
    getPeriodsTotal: (filters): Promise<AxiosResponse> => {
      return apiClient.get(`reports/by-project/periods?${filtersAtURL(filters)}`)
    },
    getProjectAccounts: (filters): Promise<AxiosResponse> => {
      return apiClient.get(
        `reports/by-project/percentil-expenses?${filtersAtURL(filters)}&groupBy=${GROUP_BY.ACCOUNT}`,
        filters
      )
    },
    getProjectCategories: (filters): Promise<AxiosResponse> => {
      return apiClient.get(
        `reports/by-project/percentil-expenses?${filtersAtURL(filters)}&groupBy=${GROUP_BY.CATEGORIES}`,
        filters
      )
    },
    getProjectResourceTypes: (filters): Promise<AxiosResponse> => {
      return apiClient.get(`reports/by-project/resource-expenses?${filtersAtURL(filters)}`)
    },
    getProjectInfrastructureTypes: (filters): Promise<AxiosResponse> => {
      return apiClient.get(`reports/by-project/infrastructure-expenses?${filtersAtURL(filters)}`)
    },
    getAssignedExpenses: (filters): Promise<AxiosResponse> => {
      return apiClient.get(`reports/by-project/assigned-expenses?${filtersAtURL(filters)}`)
    }
  },
  auth: {
    signIn: (data): Promise<AxiosResponse> => {
      return apiClient.post('auth/signin', data)
    },
    signUp: (data): Promise<AxiosResponse> => {
      return apiClient.post('auth/signup', data)
    },
    checkToken: (data): Promise<AxiosResponse> => {
      return apiClient.post('auth/check', data)
    },
    renewToken: (data): Promise<AxiosResponse> => {
      return apiClient.post('auth/renew', data)
    },
    recoverPassword: (data): Promise<AxiosResponse> => {
      return apiClient.post('auth/password-recovery', data)
    },
    resetPassword: (token, data): Promise<AxiosResponse> => {
      return apiClient.post(`auth/password-reset${token}`, data)
    }
  },
  user: {
    getAllUsers: (companyId): Promise<AxiosResponse> => {
      return apiClient.get(`user/${companyId}/all`)
    },
    getRoles: (): Promise<AxiosResponse> => {
      return apiClient.get('roles')
    },
    getUser: (companyId): Promise<AxiosResponse> => {
      return apiClient.get(`user/${companyId}`)
    },
    updateUser: (formData): Promise<AxiosResponse> => {
      return apiClient.put('user', formData)
    },
    deleteUser: (formData): Promise<AxiosResponse> => {
      return apiClient.delete('user', formData)
    },
    activateUser: (data): Promise<AxiosResponse> => {
      return apiClient.put('user/activate', data)
    },
    deactivateUser: (data): Promise<AxiosResponse> => {
      return apiClient.put('user/deactivate', data)
    }
  },
  notification: {
    getActiveNotifications: (): Promise<AxiosResponse> => {
      return apiClient.get(`notifications?isActive=all`)
    }
  }
}
