import React, { createContext } from 'react'
import { initialValue, FILTER } from './constants'

const FilterContext = createContext(initialValue)

const FiltersReducer = (prevState, action) => {
  switch (action.type) {
    case FILTER.UPDATE_CURRENCIES:
      return { ...prevState, currency: action.payload }
    case FILTER.UPDATE_ACCOUNTS:
      return { ...prevState, accounts: action.payload }
    case FILTER.UPDATE_FROM_DATE:
      return { ...prevState, from: action.payload }
    case FILTER.UPDATE_TO_DATE:
      return { ...prevState, to: action.payload }
    case FILTER.UPDATE_PROJECTS:
      return { ...prevState, projects: action.payload }
    case FILTER.UPDATE_CATEGORIES:
      return { ...prevState, categories: action.payload }
      case FILTER.UPDATE_PROJECT:
      return { ...prevState, project: action.payload }
  }
}

const filterAction = {
  updateCurrencies: (payload) => ({
    type: FILTER.UPDATE_CURRENCIES,
    payload: payload
  }),
  updateCategories: (payload) => ({
    type: FILTER.UPDATE_CATEGORIES,
    payload: payload
  }),
  updateAccounts: (payload) => ({ type: FILTER.UPDATE_ACCOUNTS, payload: payload }),
  updateProjects: (payload) => ({
    type: FILTER.UPDATE_PROJECTS,
    payload: payload
  }),
  updateFromDate: (payload) => ({ type: FILTER.UPDATE_FROM_DATE, payload: payload }),
  updateToDate: (payload) => ({ type: FILTER.UPDATE_TO_DATE, payload: payload }),
  updateProject: (payload) => ({
    type: FILTER.UPDATE_PROJECT,
    payload: payload
  }),
}

export { FilterContext, FiltersReducer, filterAction }
