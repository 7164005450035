import React, { useEffect, useRef, useState } from 'react'
import { NotificationActionButton, InlineNotification } from 'carbon-components-react'

export interface INotification {
  id: number
  notification: any[]
  date: string
  cleared: boolean
}

export interface IProps {
  notifications: INotification[]
  deactivateNotification: (id: string | number) => void
  openNotification: boolean
}

const NotificationContainer = ({ notifications, deactivateNotification }: IProps) => {
  const [notificationsList, setNotificationsList] = useState<INotification[]>([])
  const ref = useRef(null)
  const formatDate = (rawDate: string) => {
    const year = rawDate.substring(0, 4)
    const month = rawDate.substring(5, 7)
    const date = rawDate.substring(8, 10)
    return `${date}/${month}/${year}`
  }

  useEffect(() => {
    if (notifications && notifications.length) {
      setNotificationsList(() => {
        return notifications.map(
          (n) =>
            ({
              id: n.id,
              notification: n.notification,
              date: n.date,
              cleared: false
            } as INotification)
        )
      })
    }
  }, [])

  const handleDeletion = (id: number) => {
    setNotificationsList((prev) =>
      notificationsList?.map((i, idx) => {
        if (id === i.id)
          return {
            ...i,
            id: i.id,
            notification: i.notification,
            date: i.date,
            cleared: !prev[idx].cleared
          }
        return i
      })
    )
    setTimeout(() => deactivateNotification(id), 100)
  }

  return (
    <div
      style={{
        background: '#fff0',
        padding: '10px',
        borderRadius: '10px',
        maxHeight: '240px',
        overflowY: 'scroll'
      }}
    >
      {notificationsList.length &&
        notificationsList?.map((item, i) => (
          <InlineNotification
            ref={ref}
            key={i}
            timeout={0}
            kind="info"
            title={item.notification}
            style={item.cleared ? { transition: 'all 1s ease-out', opacity: 0.4 } : {}}
            caption="Caption (caption)"
            hideCloseButton={true}
            actions={
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <NotificationActionButton
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100px'
                  }}
                  onClick={() => handleDeletion(item.id)}
                >
                  Aceptar
                </NotificationActionButton>
                <p
                  style={{
                    color: 'grey',
                    fontSize: '12px',
                    textAlign: 'center',
                    paddingBottom: '4px'
                  }}
                >
                  {formatDate(item.date)}
                </p>
              </div>
            }
          />
        ))}
    </div>
  )
}

export { NotificationContainer }
