export const currencies = [
  {
    code: 'USD',
    name: 'US Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: 'US$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'US$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'ARS',
    name: 'Argentine Peso',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'EUR',
    name: 'Euro',
    fractionSize: 2,
    symbol: {
      grapheme: '€',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '€',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'AED',
    name: 'UAE Dirham',
    fractionSize: 2,
    symbol: {
      grapheme: '.د.إ',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: null
  },
  {
    code: 'AFN',
    name: 'Afghani',
    fractionSize: 2,
    symbol: {
      grapheme: '؋',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '؋',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'ALL',
    name: 'Lek',
    fractionSize: 2,
    symbol: {
      grapheme: 'L',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Lek',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'AMD',
    name: 'Armenian Dram',
    fractionSize: 2,
    symbol: {
      grapheme: 'դր.',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'դր.',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'ANG',
    name: 'Netherlands Antillean Guilder',
    fractionSize: 2,
    symbol: {
      grapheme: 'ƒ',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'NAƒ',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'AOA',
    name: 'Kwanza',
    fractionSize: 2,
    symbol: {
      grapheme: 'Kz',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },

  {
    code: 'AUD',
    name: 'Australian Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'A$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'AWG',
    name: 'Aruban Florin',
    fractionSize: 2,
    symbol: {
      grapheme: 'ƒ',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Afl',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'AZN',
    name: 'Azerbaijanian Manat',
    fractionSize: 2,
    symbol: {
      grapheme: '₼',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₼',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'BAM',
    name: 'Convertible Mark',
    fractionSize: 2,
    symbol: {
      grapheme: 'KM',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'KM',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'BBD',
    name: 'Barbados Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'BDT',
    name: 'Taka',
    fractionSize: 2,
    symbol: {
      grapheme: '৳',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'BGN',
    name: 'Bulgarian Lev',
    fractionSize: 2,
    symbol: {
      grapheme: 'лв',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'лв',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'BHD',
    name: 'Bahraini Dinar',
    fractionSize: 3,
    symbol: {
      grapheme: '.د.ب',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '.د.ب',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'BIF',
    name: 'Burundi Franc',
    fractionSize: 0,
    symbol: {
      grapheme: 'FBu',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'BMD',
    name: 'Bermudian Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'BD$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'BND',
    name: 'Brunei Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'BOB',
    name: 'Boliviano',
    fractionSize: 2,
    symbol: {
      grapheme: 'Bs.',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Bs.',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'BOV',
    name: 'Mvdol',
    fractionSize: 2,
    symbol: {
      grapheme: 'Bov',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'BRL',
    name: 'Brazilian Real',
    fractionSize: 2,
    symbol: {
      grapheme: 'R$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'R$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'BSD',
    name: 'Bahamian Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'BTN',
    name: 'Ngultrum',
    fractionSize: 2,
    symbol: {
      grapheme: 'Nu.',
      template: '$ 1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'BWP',
    name: 'Pula',
    fractionSize: 2,
    symbol: {
      grapheme: 'P',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'P',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'BYN',
    name: 'Belarussian Ruble',
    fractionSize: 2,
    symbol: {
      grapheme: 'p.',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'р.',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'BYR',
    name: 'Belarussian Ruble',
    fractionSize: 0,
    symbol: {
      grapheme: 'p.',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'р.',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'BZD',
    name: 'Belize Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: 'BZ$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'BZ$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'CA$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'CDF',
    name: 'Congolese Franc',
    fractionSize: 2,
    symbol: {
      grapheme: 'FC',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'CHE',
    name: 'WIR Euro',
    fractionSize: 2,
    symbol: {
      grapheme: 'CHE',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'CHF',
    name: 'Swiss Franc',
    fractionSize: 2,
    symbol: {
      grapheme: 'fr.',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'CHW',
    name: 'WIR Franc',
    fractionSize: 2,
    symbol: {
      grapheme: 'CHW',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'CLF',
    name: 'Unidad de Fomento',
    fractionSize: 4,
    symbol: {
      grapheme: 'UF',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'CLP',
    name: 'Chilean Peso',
    fractionSize: 0,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'CNY',
    name: 'Yuan Renminbi',
    fractionSize: 2,
    symbol: {
      grapheme: '元',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '元',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'COP',
    name: 'Colombian Peso',
    fractionSize: 0,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'COU',
    name: 'Unidad de Valor Real',
    fractionSize: 2,
    symbol: {
      grapheme: 'COU',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'CRC',
    name: 'Cost Rican Colon',
    fractionSize: 2,
    symbol: {
      grapheme: '₡',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₡',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'CUC',
    name: 'Peso Convertible',
    fractionSize: 2,
    symbol: {
      grapheme: 'CUC',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'CUP',
    name: 'Cuban Peso',
    fractionSize: 2,
    symbol: {
      grapheme: '$MN',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '$MN',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'CVE',
    name: 'Cabo Verde Escudo',
    fractionSize: 2,
    symbol: {
      grapheme: 'esc',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'CZK',
    name: 'Czech Koruna',
    fractionSize: 2,
    symbol: {
      grapheme: 'Kč',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Kč',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'DJF',
    name: 'Djibouti Franc',
    fractionSize: 0,
    symbol: {
      grapheme: 'Fdj',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'DKK',
    name: 'Danish Krone',
    fractionSize: 2,
    symbol: {
      grapheme: 'kr',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'DOP',
    name: 'Dominican Peso',
    fractionSize: 2,
    symbol: {
      grapheme: 'RD$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'RD$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'DZD',
    name: 'Algerian Dinar',
    fractionSize: 2,
    symbol: {
      grapheme: '.د.ج',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '.د.ج',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'EEK',
    name: 'Estonian Kroon',
    fractionSize: 2,
    symbol: {
      grapheme: 'kr',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'EGP',
    name: 'Egyptian Pound',
    fractionSize: 2,
    symbol: {
      grapheme: '£',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '.ج.م',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'ERN',
    name: 'Nakfa',
    fractionSize: 2,
    symbol: {
      grapheme: 'Nkf',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'ETB',
    name: 'Ethiopian Birr',
    fractionSize: 2,
    symbol: {
      grapheme: 'Br',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },

  {
    code: 'FJD',
    name: 'Fiji Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'FJ$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'FKP',
    name: 'Falkland Islands Pound',
    fractionSize: 2,
    symbol: {
      grapheme: '£',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'GBP',
    name: 'Pound Sterling',
    fractionSize: 2,
    symbol: {
      grapheme: '£',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '£',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'GEL',
    name: 'Lari',
    fractionSize: 2,
    symbol: {
      grapheme: 'GEL',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'GGP',
    name: 'Guernsey Pound',
    fractionSize: 2,
    symbol: {
      grapheme: '£',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'GHC',
    name: 'Ghanaian Cedi',
    fractionSize: 2,
    symbol: {
      grapheme: '¢',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '¢',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'GHS',
    name: 'Ghan Cedi',
    fractionSize: 2,
    symbol: {
      grapheme: 'GH₵',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'GIP',
    name: 'Gibraltar Pound',
    fractionSize: 2,
    symbol: {
      grapheme: '£',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'GMD',
    name: 'Dalasi',
    fractionSize: 2,
    symbol: {
      grapheme: 'D',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'GNF',
    name: 'Guine Franc',
    fractionSize: 0,
    symbol: {
      grapheme: 'GFr',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'GTQ',
    name: 'Quetzal',
    fractionSize: 2,
    symbol: {
      grapheme: 'Q',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Q',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'GYD',
    name: 'Guyan Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'GY$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'HKD',
    name: 'Hong Kong Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'HK$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'HNL',
    name: 'Lempira',
    fractionSize: 2,
    symbol: {
      grapheme: 'L',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'L',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'HRK',
    name: 'Croatian Kuna',
    fractionSize: 2,
    symbol: {
      grapheme: 'kn',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'kn',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'HTG',
    name: 'Gourde',
    fractionSize: 2,
    symbol: {
      grapheme: 'G',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'HUF',
    name: 'Forint',
    fractionSize: 0,
    symbol: {
      grapheme: 'Ft',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Ft',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'IDR',
    name: 'Rupiah',
    fractionSize: 2,
    symbol: {
      grapheme: 'Rp',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Rp',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'ILS',
    name: 'New Israeli Sheqel',
    fractionSize: 2,
    symbol: {
      grapheme: '₪',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₪',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'IMP',
    name: 'Manx Pound',
    fractionSize: 2,
    symbol: {
      grapheme: '£',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'INR',
    name: 'Indian Rupee',
    fractionSize: 2,
    symbol: {
      grapheme: '₹',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₹',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'IQD',
    name: 'Iraqi Dinar',
    fractionSize: 3,
    symbol: {
      grapheme: '.د.ع',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '.د.ع',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'IRR',
    name: 'Iranian Rial',
    fractionSize: 0,
    symbol: {
      grapheme: '﷼',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '﷼',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'ISK',
    name: 'Iceland Krona',
    fractionSize: 2,
    symbol: {
      grapheme: 'kr',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'JEP',
    name: 'Jersey Pound',
    fractionSize: 2,
    symbol: {
      grapheme: '£',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'JMD',
    name: 'Jamaican Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: 'J$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'J$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'JOD',
    name: 'Jordanian Dinar',
    fractionSize: 3,
    symbol: {
      grapheme: '.د.إ',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: null
  },
  {
    code: 'JPY',
    name: 'Yen',
    fractionSize: 0,
    symbol: {
      grapheme: '¥',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '¥',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'KES',
    name: 'Kenyan Shilling',
    fractionSize: 2,
    symbol: {
      grapheme: 'KSh',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'KSh',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'KGS',
    name: 'Som',
    fractionSize: 2,
    symbol: {
      grapheme: 'сом',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'сом',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'KHR',
    name: 'Riel',
    fractionSize: 2,
    symbol: {
      grapheme: '៛',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '៛',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'KMF',
    name: 'Comoro Franc',
    fractionSize: 0,
    symbol: {
      grapheme: 'CF',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'KPW',
    name: 'North Korean Won',
    fractionSize: 0,
    symbol: {
      grapheme: '₩',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'KRW',
    name: 'Won',
    fractionSize: 0,
    symbol: {
      grapheme: '₩',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₩',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'KWD',
    name: 'Kuwaiti Dinar',
    fractionSize: 3,
    symbol: {
      grapheme: '.د.ك',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '.د.ك',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'KYD',
    name: 'Cayman Islands Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'CI$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'KZT',
    name: 'Tenge',
    fractionSize: 2,
    symbol: {
      grapheme: '₸',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₸',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'LAK',
    name: 'Kip',
    fractionSize: 2,
    symbol: {
      grapheme: '₭',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₭',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'LBP',
    name: 'Lebanese Pound',
    fractionSize: 2,
    symbol: {
      grapheme: '£',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '.ل.ل',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'LKR',
    name: 'Sri Lank Rupee',
    fractionSize: 2,
    symbol: {
      grapheme: '₨',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'LRD',
    name: 'Liberian Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'L$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'LSL',
    name: 'Loti',
    fractionSize: 2,
    symbol: {
      grapheme: 'LSL',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'LTL',
    name: 'Lithuanian Litas',
    fractionSize: 2,
    symbol: {
      grapheme: 'Lt',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Lt',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'LVL',
    name: 'Latvian Lats',
    fractionSize: 2,
    symbol: {
      grapheme: 'Ls',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Ls',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'LYD',
    name: 'Libyan Dinar',
    fractionSize: 3,
    symbol: {
      grapheme: '.د.ل',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '.د.ل',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'MAD',
    name: 'Moroccan Dirham',
    fractionSize: 2,
    symbol: {
      grapheme: '.د.م',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '.د.م',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'MDL',
    name: 'Moldovan Leu',
    fractionSize: 2,
    symbol: {
      grapheme: 'lei',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'MGA',
    name: 'Malagasy ariary',
    fractionSize: 1,
    symbol: {
      grapheme: 'Ar',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'MKD',
    name: 'Denar',
    fractionSize: 2,
    symbol: {
      grapheme: 'ден',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'ден',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'MMK',
    name: 'Kyat',
    fractionSize: 2,
    symbol: {
      grapheme: 'K',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'MNT',
    name: 'Tugrik',
    fractionSize: 2,
    symbol: {
      grapheme: '₮',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₮',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'MOP',
    name: 'Pataca',
    fractionSize: 2,
    symbol: {
      grapheme: 'MOP$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'MRO',
    name: 'Ouguiya',
    fractionSize: 2,
    symbol: {
      grapheme: 'ouguiya',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'MUR',
    name: 'Mauritius Rupee',
    fractionSize: 2,
    symbol: {
      grapheme: '₨',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'MVR',
    name: 'Rufiyaa',
    fractionSize: 2,
    symbol: {
      grapheme: 'MVR',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'MWK',
    name: 'Kwacha',
    fractionSize: 2,
    symbol: {
      grapheme: 'MK',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'MXV',
    name: 'Mexican Unidad de Inversion (UDI)',
    fractionSize: 2,
    symbol: {
      grapheme: 'UDI',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'MYR',
    name: 'Malaysian Ringgit',
    fractionSize: 2,
    symbol: {
      grapheme: 'RM',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'RM',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'MZN',
    name: 'Mozambique Metical',
    fractionSize: 2,
    symbol: {
      grapheme: 'MT',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'MT',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'NAD',
    name: 'Namibi Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'N$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'NGN',
    name: 'Naira',
    fractionSize: 2,
    symbol: {
      grapheme: '₦',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₦',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'NIO',
    name: 'Cordob Oro',
    fractionSize: 2,
    symbol: {
      grapheme: 'C$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'C$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'NOK',
    name: 'Norwegian Krone',
    fractionSize: 2,
    symbol: {
      grapheme: 'kr',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'NPR',
    name: 'Nepalese Rupee',
    fractionSize: 2,
    symbol: {
      grapheme: '₨',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'NZ$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'OMR',
    name: 'Rial Omani',
    fractionSize: 3,
    symbol: {
      grapheme: '﷼',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '.ر.ع',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'PAB',
    name: 'Balboa',
    fractionSize: 2,
    symbol: {
      grapheme: 'B/.',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'B/.',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'PEN',
    name: 'Nuevo Sol',
    fractionSize: 2,
    symbol: {
      grapheme: 'S/',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'S/',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'PGK',
    name: 'Kina',
    fractionSize: 2,
    symbol: {
      grapheme: 'K',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'PHP',
    name: 'Philippine Peso',
    fractionSize: 2,
    symbol: {
      grapheme: '₱',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₱',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'PKR',
    name: 'Pakistan Rupee',
    fractionSize: 2,
    symbol: {
      grapheme: '₨',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'PLN',
    name: 'Zloty',
    fractionSize: 2,
    symbol: {
      grapheme: 'zł',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'zł',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'PYG',
    name: 'Guarani',
    fractionSize: 0,
    symbol: {
      grapheme: 'Gs',
      template: '1$',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Gs',
      template: '1$',
      rtl: false
    }
  },
  {
    code: 'QAR',
    name: 'Qatari Rial',
    fractionSize: 2,
    symbol: {
      grapheme: '﷼',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '.ر.ق',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'RON',
    name: 'New Romanian Leu',
    fractionSize: 2,
    symbol: {
      grapheme: 'lei',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'lei',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'RSD',
    name: 'Serbian Dinar',
    fractionSize: 2,
    symbol: {
      grapheme: 'Дин.',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Дин.',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'RUB',
    name: 'Russian Ruble',
    fractionSize: 2,
    symbol: {
      grapheme: '₽',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₽',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'RUR',
    name: 'Russian Ruble',
    fractionSize: 2,
    symbol: {
      grapheme: '₽',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₽',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'RWF',
    name: 'Rwand Franc',
    fractionSize: 0,
    symbol: {
      grapheme: 'R₣',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'SAR',
    name: 'Saudi Riyal',
    fractionSize: 2,
    symbol: {
      grapheme: '﷼',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '.ر.س',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'SBD',
    name: 'Solomon Islands Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'SI$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'SCR',
    name: 'Seychelles Rupee',
    fractionSize: 2,
    symbol: {
      grapheme: '₨',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'SDG',
    name: 'Sudanese Pound',
    fractionSize: 2,
    symbol: {
      grapheme: 'SDG',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'SEK',
    name: 'Swedish Krona',
    fractionSize: 2,
    symbol: {
      grapheme: 'kr',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'SGD',
    name: 'Singapore Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'S$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'SHP',
    name: 'Saint Helen Pound',
    fractionSize: 2,
    symbol: {
      grapheme: '£',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'SLL',
    name: 'Leone',
    fractionSize: 2,
    symbol: {
      grapheme: 'Le',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'SOS',
    name: 'Somali Shilling',
    fractionSize: 2,
    symbol: {
      grapheme: 'S',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'S',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'SRD',
    name: 'Surinam Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'SSP',
    name: 'South Sudanese Pound',
    fractionSize: 2,
    symbol: {
      grapheme: 'SS£',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'STD',
    name: 'Dobra',
    fractionSize: 2,
    symbol: {
      grapheme: 'Db',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'SVC',
    name: 'El Salvador Colon',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'C',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'SYP',
    name: 'Syrian Pound',
    fractionSize: 2,
    symbol: {
      grapheme: '£',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '.ل.س',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'SZL',
    name: 'Lilangeni',
    fractionSize: 2,
    symbol: {
      grapheme: 'L',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'THB',
    name: 'Baht',
    fractionSize: 2,
    symbol: {
      grapheme: '฿',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '฿',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'TJS',
    name: 'Somoni',
    fractionSize: 2,
    symbol: {
      grapheme: 'SM',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'TMT',
    name: 'Turkmenistan New Manat',
    fractionSize: 2,
    symbol: {
      grapheme: 'T',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'TND',
    name: 'Tunisian Dinar',
    fractionSize: 3,
    symbol: {
      grapheme: '.د.ت',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '.د.ت',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'TOP',
    name: 'Pa’anga',
    fractionSize: 2,
    symbol: {
      grapheme: 'T$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'TRL',
    name: 'Turkish Lira',
    fractionSize: 2,
    symbol: {
      grapheme: '₤',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'TRY',
    name: 'Turkish Lira',
    fractionSize: 2,
    symbol: {
      grapheme: '₺',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₺',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'TTD',
    name: 'Trinidad and Tobago Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: 'TT$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'TT$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'TWD',
    name: 'New Taiwan Dollar',
    fractionSize: 0,
    symbol: {
      grapheme: 'NT$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'NT$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'TZS',
    name: 'Tanzanian Shilling',
    fractionSize: 0,
    symbol: {
      grapheme: 'TSh',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'TSh',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'UAH',
    name: 'Hryvnia',
    fractionSize: 2,
    symbol: {
      grapheme: '₴',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₴',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'UGX',
    name: 'Ugand Shilling',
    fractionSize: 0,
    symbol: {
      grapheme: 'USh',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'USh',
      template: '$1',
      rtl: false
    }
  },

  {
    code: 'USN',
    name: 'US Dollar (Next day)',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'UYI',
    name: 'Uruguay Peso en Unidades Indexadas (URUIURUI)',
    fractionSize: 0,
    symbol: {
      grapheme: '$U',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'UYU',
    name: 'Peso Uruguayo',
    fractionSize: 0,
    symbol: {
      grapheme: '$U',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '$U',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'UZS',
    name: 'Uzbekistan Sum',
    fractionSize: 2,
    symbol: {
      grapheme: 'so’m',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'so’m',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'VEF',
    name: 'Bolivar',
    fractionSize: 2,
    symbol: {
      grapheme: 'Bs',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Bs',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'VES',
    name: 'Bolivar',
    fractionSize: 2,
    symbol: {
      grapheme: 'Bs',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Bs',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'VND',
    name: 'Dong',
    fractionSize: 0,
    symbol: {
      grapheme: '₫',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₫',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'VUV',
    name: 'Vatu',
    fractionSize: 0,
    symbol: {
      grapheme: 'VT',
      template: '1$',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'WST',
    name: 'Tala',
    fractionSize: 2,
    symbol: {
      grapheme: 'WS$',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'XAF',
    name: 'CFA Franc BEAC',
    fractionSize: 0,
    symbol: {
      grapheme: 'FCFA',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'FCFA',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'XCD',
    name: 'East Caribbean Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: '$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'EC$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'XDR',
    name: 'SDR (Special Drawing Right)',
    fractionSize: 0,
    symbol: {
      grapheme: 'SDR',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'XOF',
    name: 'CFA Franc BCEAO',
    fractionSize: 0,
    symbol: {
      grapheme: 'CFA',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'CFA',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'XPF',
    name: 'CFP Franc',
    fractionSize: 0,
    symbol: {
      grapheme: '₣',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'XSU',
    name: 'Sucre',
    fractionSize: 0,
    symbol: {
      grapheme: 'XSU',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'XUA',
    name: 'ADB Unit of Account',
    fractionSize: 0,
    symbol: {
      grapheme: 'XUA',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'YER',
    name: 'Yemeni Rial',
    fractionSize: 2,
    symbol: {
      grapheme: '﷼',
      template: '1 $',
      rtl: true
    },
    uniqSymbol: {
      grapheme: '.ر.ي',
      template: '1 $',
      rtl: true
    }
  },
  {
    code: 'ZAR',
    name: 'Rand',
    fractionSize: 2,
    symbol: {
      grapheme: 'R',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'R',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'ZMW',
    name: 'Zambian Kwacha',
    fractionSize: 2,
    symbol: {
      grapheme: 'K',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'ZWD',
    name: 'Zimbabwe Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: 'Z$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Z$',
      template: '$1',
      rtl: false
    }
  },
  {
    code: 'ZWL',
    name: 'Zimbabwe Dollar',
    fractionSize: 2,
    symbol: {
      grapheme: 'Z$',
      template: '$1',
      rtl: false
    },
    uniqSymbol: null
  },
  {
    code: 'BTC',
    name: 'BTC',
    fractionSize: 4,
    symbol: {
      grapheme: '₿',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: '₿',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'ETH',
    name: 'ETH',
    fractionSize: 4,
    symbol: {
      grapheme: 'Ξ',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Ξ',
      template: '1 $',
      rtl: false
    }
  },
  {
    code: 'LTC',
    name: 'LTC',
    fractionSize: 4,
    symbol: {
      grapheme: 'Ł',
      template: '1 $',
      rtl: false
    },
    uniqSymbol: {
      grapheme: 'Ł',
      template: '1 $',
      rtl: false
    }
  }
]
