import React, { useState } from 'react'
import { TextInput, Button } from 'carbon-components-react'
import { checkInputValidity, validations } from '../../utils/Validations'
import { useDispatch } from 'react-redux'
import { startPasswordReset } from '../../actions/auth'

export const NewPassword = () => {
  const dispatch = useDispatch()
  const defaultValues = { password: '', confirmPassword: '' }
  const [formValues, setFormValues] = useState(defaultValues)

  const handleChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value
    })
  }

  const handleLogin = (e) => {
    e.preventDefault()
    validations(null, {
      value: formValues.password,
      required: true,
      id: 'password'
    })
    validations(null, {
      value: formValues.confirmPassword,
      required: true,
      id: 'confirmPassword'
    })
    if (checkInputValidity() === 0 && formValues.password === formValues.confirmPassword) {
      const url = window.location.search
      dispatch(startPasswordReset(url, formValues))
    }
  }

  return (
    <div data-test="component-new-password">
      <h1 className="text-center margin-bottom">Restablecer tu contraseña </h1>
      <TextInput
        required
        type="password"
        className="form-control"
        placeholder="Nueva contraseña"
        name="password"
        id="password"
        value={formValues.password}
        onChange={handleChange}
        labelText={''}
      />
      <TextInput
        required
        type="password"
        className="form-control"
        placeholder="Confirmar contraseña"
        name="confirmPassword"
        id="confirmPassword"
        value={formValues.confirmPassword}
        onChange={handleChange}
        labelText={''}
      />
      <Button onClick={(e) => handleLogin(e)}>Guardar</Button>
    </div>
  )
}
