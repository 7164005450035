export const setInvalid = (id) => {
  const el = document.querySelectorAll(`#${String(id)}`)

  if (el !== undefined && el[0] !== undefined && el[0].nextElementSibling)
    el[0].nextElementSibling.classList.remove('d-none')

  if (el[0].classList) {
    el[0].classList.remove('is-valid')
    el[0].classList.add('form-control')
    el[0].classList.add('is-invalid')
  }
}

const setValid = (id) => {
  const el = document.querySelectorAll(`#${String(id)}`)
  if (el !== undefined && el[0] !== undefined && el[0].nextElementSibling)
    el[0].nextElementSibling.classList.remove('d-none')

  if (el[0].classList) {
    el[0].classList.add('form-control')
    el[0].classList.remove('is-invalid')
    el[0].classList.add('is-valid')
  }
}

export const setNoneValidation = (id) => {
  const el = document.querySelectorAll(`#${String(id)}`)
  if (el !== undefined && el[0] !== undefined && el[0].nextElementSibling)
    el[0].nextElementSibling.classList.remove('d-none')

  if (el[0].classList) {
    el[0].classList.add('form-control')
    el[0].classList.remove('is-invalid')
    el[0].classList.remove('is-valid')
  }
}

export const checkInputValidity = () => {
  let invalidInput = 0
  document.querySelectorAll('.form-control').forEach((element) => {
    if (element.classList.contains('is-invalid')) {
      invalidInput++
    }
  })
  return invalidInput
}

export const validations = (event, input?) => {
  let validate = 0
  let value: any = ''
  let required = false
  let className = ''
  let type = ''
  let id = ''

  if (event) {
    value = event.target.value
    required = event.target.required ? true : false
    className = event.target.className
    type = event.target.type
    id = event.target.id
  } else {
    value = input.value
    required = input.required ? input.required : required
    className = input.className ? input.className : className
    type = input.type ? input.type : type
    id = input.id ? input.id : id
  }

  const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const phoneRex = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/
  const phonecharacteristicRex = /[0-9]{2,4}/
  const phoneNumberRex = /[0-9]{6,8}/
  const numberRegex = /^[0-9\b]+$/
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/

  if (
    required &&
    (value === '' || value === null || value === 0 || value === '0' || value === undefined)
  ) {
    validate++
    setInvalid(id)
  }

  if (
    className.includes('select-required') &&
    (typeof value === 'undefined' || Number(value) === 0)
  ) {
    validate++
    setInvalid(id)
  }
  if (type === 'email' && !emailRex.test(value)) {
    validate++
    setInvalid(id)
  }
  if (type === 'number' && !numberRegex.test(value)) {
    validate++
    setInvalid(id)
  }

  if (className.includes('phoneNumber') && !phoneRex.test(value)) {
    validate++
    setInvalid(id)
  }

  if (id === 'password' && !passwordRegex.test(value)) {
    setInvalid(id)
    return 'La contraseña debe contener al menos 8 caracteres, incluyendo letras mayúsculas, minúsculas y números.'
  }

  if (className.includes('phonecharacteristic') && !phonecharacteristicRex.test(value)) {
    validate++
    setInvalid(id)
  }

  if (className.includes('phoneN') && !phoneNumberRex.test(value)) {
    validate++
    setInvalid(id)
  }

  if (validate === 0) {
    setValid(id)
  }
  return validate
}
