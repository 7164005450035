import React, { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
/* import es from 'date-fns/locale/es'
registerLocale('es', es) */
import 'react-datepicker/dist/react-datepicker.css'
import { IDropdownDatePickerProps } from '../../../common/models'
import { Font } from '../../../common/styles'
import moment from 'moment'
import { COLOR } from 'utils/constants/styles'
import { setMonth } from 'date-fns'
import { formatDateForDispatch } from 'utils/DataHelpers'

const DropdownDatePicker = ({ title, subTitle, dispatch, updateEnd, updateStart }): JSX.Element => {
  const [periodStart, setPeriodStart] = useState<Date>()
  const [periodEnd, setPeriodEnd] = useState<Date>()
  const [minDate, setMinDate] = useState<Date>()

  const [open, setOpen] = useState<boolean>(true)
  const DatePickerStyles = () => {
    return (
      <style>
        {`.react-datepicker-popper{
        box-shadow: 1px 1px 1px gray;
        border-radius: 5px;
        background-color: white;
      }
      .react-datepicker-year-header{
        font-family:'Red Hat Display', sans-serif;
        font-weight: bold;
      }
      .react-datepicker__header{
        background: ${COLOR.IT_SPEND_GREY}
      }
      .react-datepicker__triangle{
        background: white !important;
      }
      .react-datepicker__month-2{
        padding: 4px;
      }
      `}
      </style>
    )
  }

  useEffect(() => {
    setPeriodStart(new Date(moment(new Date()).subtract(5, 'months').toDate()))
    setPeriodEnd(new Date())
    setMinDate(new Date(moment(new Date()).subtract(5, 'years').toDate()))
  }, [])

  return (
    <div>
      <div style={{ paddingLeft: '10px' }} onClick={() => setOpen(!open)}>
        <div
          style={{
            fontFamily: Font.sans_serif,
            border: '1.5px solid grey',
            borderRight: 'none',
            borderBottom: 'none',
            height: '8px',
            width: '8px',
            transform: open ? 'rotate(45deg)' : 'rotate(225deg)',
            transitionDuration: '90ms',
            margin: '5px',
            float: 'right',
            marginRight: '10px'
          }}
        />
        <div style={{ fontSize: '16px', fontWeight: 'bold', width: '200px' }}>{title}</div>
        <div style={{ fontSize: '13px' }}>{subTitle}</div>
      </div>
      {open && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px'
          }}
        >
          <div style={{ margin: '10px' }}>
            {/* START DATE INPUT */}
            <DatePickerStyles />
            <DatePicker
              selected={periodStart}
              onChange={(date: Date) => {
                setPeriodStart(date)
                dispatch(updateStart(formatDateForDispatch(date)))
              }}
              selectsEnd
              startDate={''}
              endDate={periodEnd}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              locale="es"
              minDate={minDate}
              maxDate={periodEnd}
            />
          </div>
          <div style={{ margin: '10px' }}>
            {/* END DATE INPUT */}
            <DatePickerStyles />
            <DatePicker
              selected={periodEnd}
              onChange={(date: Date) => {
                setPeriodEnd(date)
                dispatch(updateEnd(formatDateForDispatch(date)))
              }}
              selectsEnd
              startDate={''}
              endDate={periodEnd}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              locale="es"
              minDate={minDate}
              maxDate={periodEnd}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export { DropdownDatePicker }
