import { types } from '../types/types'

const initialState = {
  checking: true
}

export const authReducer = (state = initialState, action) => {
  if (action.type === types.authLogin) {
    return {
      ...state,
      ...action.payload,
      checking: false
    }
  } else {
    return state
  }
}
