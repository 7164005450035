import { types } from '../types/types'
import { fetchWithToken } from '../helpers/fetch'

export const notificationStartLoading = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`notifications?isActive=1`, {}, 'GET')
      const body = await resp.json()
      body.length && dispatch(notificationLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

interface IData {
  id?: number | string
}

export const singleNotificationDeactivate = (id) => {
  return async (dispatch) => {
    try {
      const data: IData = {}
      if (id) Object.assign(data, { id })
      const resp = await fetchWithToken(`notifications/${id}/deactivate`, data, 'PUT')
      if (resp.status === 200) {
        console.log('notification successfully deleted')
        dispatch(notificationStartLoading())
      }
      /* const body = await resp.json() */
    } catch (error) {
      console.log(error)
    }
  }
}

const notificationLoaded = (body) => {
  return {
    type: types.notificationLoaded,
    payload: body
  }
}

export { notificationLoaded }
