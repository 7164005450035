import React, { useState, useEffect } from 'react'
import { Row, Col, Table } from 'reactstrap'
import { ChevronUp32, ChevronDown32 } from '@carbon/icons-react'

import '@carbon/charts/styles.css'
import moment from 'moment'

export const TableResult = ({ data }) => {
  const [reportFive, setReportFive] = useState(data)

  useEffect(() => {
    setReportFive(data)
  }, [data])

  const showMore = (i) => {
    const list = [...reportFive]
    let item = {}

    item = {
      ...list[i]
    }
    item = {
      ...item,
      isOpen: !list[i].isOpen
    }
    list[i] = item

    setReportFive(list)
  }

  return (
    <div data-test="component-table-result">
      {reportFive.length > 0 && (
        <Row className="white-background color-border pr-0 pl-0">
          <Col md="12">
            <h4 className="table-title">Gastos sin asignar</h4>
            <Table striped>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Cuenta</th>
                  <th>Período</th>
                  <th>Monto</th>
                  <th>Monto SA</th>
                  <th>Moneda</th>
                  <th>NPP</th>
                  <th>Proyectos</th>
                </tr>
              </thead>
              <tbody>
                {reportFive.map((item, i) => (
                  <tr key={i}>
                    <td>{item.name}</td>
                    <td>{item.account}</td>
                    <td>{moment(item.period).format('MM/YY')}</td>
                    <td>{Number(item.amount)}</td>
                    <td>{Number(item.unassigned_amount)}</td>
                    <td>{item.currency}</td>
                    <td>{item.NPP}%</td>
                    <td>
                      {item.projects &&
                        JSON.parse(item.projects)
                          .slice(0, 1)
                          .map((project, indexP) => (
                            <span key={`project-${indexP}`}>
                              <span
                                className="tag-project"
                                style={{ backgroundColor: project.color }}
                              >
                                {project.name}
                              </span>
                              <span
                                className="project-percentage main"
                                style={{ color: project.color, borderColor: project.color }}
                              >
                                {project.quota}%
                              </span>
                            </span>
                          ))}
                      {item.projects && JSON.parse(item.projects).length > 1 && (
                        <a className="ml-3 tag-more" onClick={() => showMore(i)}>
                          <span>
                            + {JSON.parse(item.projects).length - 1}
                            {item.isOpen ? <ChevronUp32 /> : <ChevronDown32 />}
                          </span>
                        </a>
                      )}
                      {item.projects && (
                        <div className="tag-show">
                          {item.isOpen &&
                            JSON.parse(item.projects)
                              .slice(1, JSON.parse(item.projects).length)
                              .map((project, ip) => (
                                <span key={`${ip}`}>
                                  <span
                                    className="tag-project"
                                    style={{ backgroundColor: project.color }}
                                  >
                                    {project.name}
                                  </span>
                                  <span
                                    className="project-percentage"
                                    style={{ color: project.color, borderColor: project.color }}
                                  >
                                    {project.quota}%
                                  </span>
                                </span>
                              ))}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </div>
  )
}
