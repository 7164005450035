import React, { useEffect, useState } from 'react'
import { useForm, useStep } from 'react-hooks-helper'
import { AccountStepOne } from './accountStepOne'
import { AccountStepTwo } from './accountStepTwo'

const steps = [
  {
    id: 'dataAccount'
  },
  {
    id: 'dateAccount'
  }
]

export const AccountSteps = ({ toggle, account, isOpenModal }) => {
  const companyId = localStorage.getItem('company_id')

  const accounTypeCond = account && account.type === 'ONPREMISE' ? account.type : 'CLOUD'
  const accountTypeCloud = account && account.type !== 'ONPREMISE' ? account.type : 'ONPREMISE'
  const defaultValues = {
    name: '',
    accountType: account ? accounTypeCond : '',
    cloudType: account ? accountTypeCloud : '',
    aws_access_key: '',
    aws_secret_key: '',
    aws_region: '',
    azure_client_secret: '',
    azure_client_id: '',
    azure_tenant_id: '',
    google_credential: '',
    google_dataset: '',
    ibm_cloud_id: '',
    ibm_api_key: '',
    company_id: companyId,
    dateFrom: '',
    from: '',
    type: ''
  }

  const [formValues, setFormValues] = useForm(defaultValues)

  useEffect(() => {
    if (account && account.id) {
      Object.entries(account).forEach(([key, value]) => {
        setFormValues({
          target: {
            name: key,
            value: value
          }
        })
      })
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
  }, [account && account.id])

  const [isEdit, setIsEdit] = useState(false)

  const { step, navigation } = useStep({
    initialStep: 0,
    steps
  })

  const { id } = step

  const props = {
    formValues,
    setFormValues,
    navigation,
    toggle,
    isEdit,
    defaultValues,
    isOpenModal
  }

  switch (id) {
    case 'dataAccount':
      return <AccountStepOne {...props} />
    case 'dateAccount':
      return <AccountStepTwo {...props} />
    default:
      return null
  }
}
