import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { HeaderResource } from './HeaderResource'
import './Resource.scss'
import ListServiceResource from './ListServiceResource'
import ListProjectResource from './ListProjectResource'
import { useDispatch, useSelector } from 'react-redux'
import { resourceStartLoading, resourcePeriodCost } from '../../actions/resource'
import { projectStartLoading } from '../../actions/projects'
import { accountStartLoading } from '../../actions/account'
import moment from 'moment'
import { ResourceModal } from './resourceModal/resourceModal'
import { RootState } from '../../reducers/rootReducer'
import { Sidebar } from '../global/sidebar'
import { DeleteModal } from '../global/confirmationModal/deleteModal'
import { store } from '../../store/store'
import { Footer } from '../global/footer'

export const Resource = () => {
  const dispatch = useDispatch()
  const resource = store.getState().resources.resource
  const type = window.location.pathname.slice(1)

  /* selectors*/
  const { accounts }: any = useSelector((storeA: RootState) => storeA.accounts)
  const { account, dates }: any = useSelector((storeR: RootState) => storeR.resources)

  /* states */
  const [stateAccount, setStateAccount] = useState({})
  const [currentDate] = useState(moment().format('YYYY-MM-01'))
  const [stateFromDrag, setStateFromDrag] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [displayedSelected, setDisplayedSelected] = useState<any[]>()
  const [serviceState, setserviceState] = useState([])
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const [formState, setFormState] = useState({})

  useEffect(() => {
    dispatch(projectStartLoading(currentDate, currentDate))
    dispatch(accountStartLoading(type))
  }, [dispatch,type])

  useEffect(() => {
    if (accounts.length > 0) {
      dispatch(resourceStartLoading(accounts[0].id, currentDate, currentDate))
    }
  }, [accounts])

  useEffect(() => {
    if (accounts?.length) setDisplayedSelected(accounts[0].name)
  }, [accounts])

  useEffect(() => {
    if (account) {
      setStateAccount(account)
    }
  }, [account])

  useEffect(() => {
    if (resource.id) {
      setserviceState(resource)
    }
  }, [resource])

  const changeValue = (accountItem) => {
    dispatch(resourceStartLoading(accountItem.selectedItem.id, dates.from, dates.to))
    setDisplayedSelected(accountItem.selectedItem.name)
  }

  const toggle = (e, service, from) => {
    if (service) {
      if (service.id && !service.name) {
        dispatch(resourcePeriodCost(service.id))
      } else {
        setserviceState(service)
      }
    } else {
      setserviceState([])
    }
    setStateFromDrag(from ? true : false)
    setIsOpenModal(!isOpenModal)
  }

  const toggleModalDelete = (data) => {
    setIsOpenModalDelete(!isOpenModalDelete)
    setFormState(data)
  }

  return (
    <div data-test="component-resource">
      <Sidebar />
      <div className="container mb-5">
        <Row id="page-wrap">
          <Col className="border-col-right pr-0" md="12">
            <Col className="border-col-right pr-0" md="10">
                <HeaderResource
                  account={stateAccount}
                  changeValue={changeValue}
                  toggle={toggle}
                  displayedSelected={displayedSelected}
                />
            </Col>
            <Col className="border-col-right pr-0 float-left" md="10">
              <ListServiceResource
                currentDate={currentDate}
                toggle={toggle}
                account={stateAccount}
                toggleModalDelete={toggleModalDelete}
              />
             </Col>
             <Col md="2" className="float-right">
              <ListProjectResource toggle={toggle} />
            </Col>
          </Col>
          <ResourceModal
            isOpenModal={isOpenModal}
            toggle={toggle}
            service={serviceState}
            account={stateAccount}
            fromDrag={stateFromDrag}
          />
          <DeleteModal
            isOpenModalDelete={isOpenModalDelete}
            toggleModalDelete={toggleModalDelete}
            data={formState}
          />
        </Row>
      </div>
      <Footer />
    </div>
  )
}
