import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { TrashCan32, RequestQuote32 } from '@carbon/icons-react'
import { DeleteModal } from '../global/confirmationDeleteModal/confirmationDeleteModal'
import { useDispatch } from 'react-redux'
import { userStartDeleting } from '../../actions/users'
import ActiveIcon from '../../assets/img/icon-active-itspend.svg'
import InactiveIcon from '../../assets/img/icon-active-itspend-grey.svg'

export const ListUser = ({ users, toggle, activeUser, deactiveUser, roles }) => {
  const dispatch = useDispatch()
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [idDelete, setIdDelete] = useState(0)

  const toggleDelete = (id) => {
    if (id) {
      setIdDelete(id)
    }
    setIsOpenModalDelete(!isOpenModalDelete)
  }

  useEffect(() => {
    if (confirmDelete) {
      dispatch(userStartDeleting(idDelete))
    }
    setConfirmDelete(false)
  }, [confirmDelete])
  return (
    <div data-test="component-list-user">
      <Row>
        <Col md="2">
          <p className="table-title">Nombre</p>
        </Col>
        <Col md="4">
          <p className="table-title">Email</p>
        </Col>
        <Col md="4">
          <p className="table-title">Rol</p>
        </Col>
        <Col md="2">
          <p className="table-title">Acciones</p>
        </Col>
      </Row>

      {users &&
        users.map((user, i) => (
          <div key={i}>
            <Row className={`border-row ${user.is_active === 0 && 'disabled-color'}`}>
              <Col className="center-div" md="2">
                <p>{user.name}</p>
              </Col>
              <Col md="4">
                <p>{user.email}</p>
              </Col>
              <Col md="4">
                {roles
                  .filter((x) => x.id === user.role_id)
                  .map((item, index) => (
                    <p className="capitalize" key={index}>
                      {item.name}
                    </p>
                  ))}
              </Col>
              <Col className="center-div icon-action" md="2">
                <RequestQuote32 onClick={() => toggle(user)} />
                {user.is_active === 1 ? (
                  <img src={ActiveIcon} alt="ActiveIcon" onClick={() => deactiveUser(user.id)} />
                ) : (
                  <img src={InactiveIcon} alt="InactiveIcon" onClick={() => activeUser(user.id)} />
                )}
                <TrashCan32 className="trash" onClick={() => toggleDelete(user.id)} />
              </Col>
            </Row>
          </div>
        ))}
      <DeleteModal
        isOpenModalDelete={isOpenModalDelete}
        toggleDelete={toggleDelete}
        text={'este usuario'}
        setConfirmDelete={setConfirmDelete}
      ></DeleteModal>
    </div>
  )
}
