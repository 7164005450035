import React, { useState, useEffect } from 'react'
import DropTarget from './dragAndDrop/DropTarget'
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/rootReducer'
import {
  ExpandableTile,
  TileAboveTheFoldContent,
  TileBelowTheFoldContent
} from 'carbon-components-react'
import { Row, Col } from 'reactstrap'

export const ListProjectResource = ({ toggle }) => {
  const projects: any = useSelector((store: RootState) => store.resources.projects)
  const [items, setItems] = React.useState([] as any)
  const [projectState, setItemsprojectState] = useState(projects.projects)
  
  useEffect(() => {
    setItemsprojectState(projects)
  }, [projects])

  const itemDropped = (projectsItems) => {
    setItems([...items, { projectsItems }])
    setItemsprojectState(projectsItems)
  }

  return (
    <div data-test="component-list-project-resource" className="list-project">
      <h2 className="mt-3 mb-4">Proyectos</h2>
      {projectState?.length > 0 &&
        projectState
          .filter((project) => project.id)
          .map((project) => (
            <DropTarget
              id={project.id}
              key={`project-${project.id}`}
              onItemDropped={itemDropped}
              projects={projectState}
              toggle={toggle}
            >
              <div
                className="project-div drag-drop-container"
                style={{ backgroundColor: project.color }}
              >
                <div className="project-header">
                  <span className="project-circle" style={{ color: project.color }}>
                    {project.name.charAt(0)}
                  </span>
                  <p className="project-name">{project.name}</p>
                </div>
                <p className="project-number">{project.quantityTotal} gastos asociados</p>
                <div className="project-line"></div>
                {project.quantityTotal !== 0 && (
                  <div>
                    <ExpandableTile
                      tileCollapsedIconText="Ver detalle"
                      tileExpandedIconText="Ocultar detalle"
                    >
                      <TileAboveTheFoldContent>
                        <Row>
                          <Col md="4">
                            <p>Montos</p>
                          </Col>
                          <Col md="8">
                            <p className="project-seemore"></p>
                          </Col>
                        </Row>
                      </TileAboveTheFoldContent>
                      <TileBelowTheFoldContent>
                        {Object.keys(project?.amounts).map((amount, i) => (
                          <Row key={i}>
                            <Col md="2">{<p>{project.amounts[amount].code}</p>}</Col>
                            <Col md="2">
                              <p>{parseFloat(project.amounts[amount].amount).toFixed(2)}</p>
                            </Col>
                          </Row>
                        ))}

                        <div className="project-line"></div>
                        <p className="project-period">En el periodo seleccionado</p>
                      </TileBelowTheFoldContent>
                    </ExpandableTile>
                  </div>
                )}
              </div>
            </DropTarget>
          ))}
    </div>
  )
}

export default ListProjectResource
