import React from 'react'
import { TextInput } from 'carbon-components-react'

export const AzureAccountModal = ({ formValues, handleInputChange, isEdit }) => {
  return (
    <div data-test="component-azure-account-modal">
      <TextInput
        placeholder="Azure Client Secret"
        name="azure_client_secret"
        id="azure_client_secret"
        labelText="Azure Client Secret"
        value={formValues.azure_client_secret}
        onChange={handleInputChange}
      />
      <TextInput
        placeholder="Azure Client ID"
        name="azure_client_id"
        id="azure_client_id"
        labelText="Azure Client ID"
        value={formValues.azure_client_id}
        onChange={handleInputChange}
        disabled={isEdit}
      />
      <TextInput
        placeholder="Azure Tenant ID"
        name="azure_tenant_id"
        id="azure_tenant_id"
        labelText="Azure Tenant ID"
        value={formValues.azure_tenant_id}
        onChange={handleInputChange}
        disabled={isEdit}
      />
    </div>
  )
}
