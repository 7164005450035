import React from 'react'
import { TextInput } from 'carbon-components-react'

export const AWSAccountModal = ({ formValues, handleInputChange }) => {
  return (
    <div data-test="component-aws-account-modal">
      <TextInput
        placeholder="AWS Access Key"
        name="aws_access_key"
        id="aws_access_key"
        labelText="AWS Access Key"
        value={formValues.aws_access_key}
        onChange={handleInputChange}
      />
      <TextInput
        placeholder="AWS Secret Key"
        name="aws_secret_key"
        id="aws_secret_key"
        labelText="AWS Secret Key"
        value={formValues.aws_secret_key}
        onChange={handleInputChange}
      />
      <TextInput
        placeholder="AWS Region"
        name="aws_region"
        id="aws_region"
        labelText="AWS Region"
        value={formValues.aws_region}
        onChange={handleInputChange}
      />
    </div>
  )
}
