import React, { useState, useEffect } from 'react'
import { Col } from 'reactstrap'
import { PieChart } from '@carbon/charts-react'
import '@carbon/charts/styles.css'

export const PieCharts = ({ data }) => {
  const [reportThree, setReportThree] = useState([
    {
      group: 'Gastos únicos',
      value: 0
    },
    {
      group: 'Gastos recurrentes',
      value: 0
    }
  ] as any)

  const [optionsReport] = useState({
    title: 'Historial de gastos únicos vs. recurrentes',
    resizable: true,
    legend: {
      alignment: 'center'
    },
    pie: {
      alignment: 'center'
    },
    height: '400px',
    color: {
      scale: { 'Gastos únicos': '#1166f8', 'Gastos recurrentes': '#6929c4' }
    }
  } as any)

  useEffect(() => {
    setReportThree(data)
  }, [data])

  return (
    <Col data-test="component-pie-charts" md="6">
      <PieChart data={reportThree} options={optionsReport}></PieChart>
    </Col>
  )
}
