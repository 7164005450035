import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { LoginMain } from '../modules/login/containers/LoginMain'
import { Resource } from '../components/resource/Resource'
import { PublicRoute } from './PublicRoute'
import { PrivateRoute } from './PrivateRoute'
import { Project } from '../components/projects/Project'
import { Account } from '../components/accounts/Account'
import { User } from '../components/users/User'

import { RootState } from '../reducers/rootReducer'

import { startChecking } from '../actions/auth'
import { Dashboards } from 'modules/dashboard/containers/Dashboards'
import { ROUTE } from './Routes'
import { isPublicRoute, loggedIn } from 'utils/Functions'

export const AppRouter = () => {
  const dispatch = useDispatch()
  const { email }: any = useSelector((store: RootState) => store.auth)
  useEffect(() => {
    if (!isPublicRoute(window.location.pathname as ROUTE)) {
      dispatch(startChecking())
    }
  }, [])

  return (
    <Router>
      <Switch>
        <PublicRoute
          exact
          path="/login"
          component={LoginMain}
          isAuthenticated={!!email}
          isLoginMain={true}
        />
        <PublicRoute
          exact
          path="/password-recovery"
          component={LoginMain}
          isAuthenticated={!!email}
          isForgotPassword={true}
        />
        <PublicRoute
          exact
          path="/password-reset"
          component={LoginMain}
          isAuthenticated={!!email}
          isNewPassword={true}
        />
        <PublicRoute
          exact
          path="/register"
          component={LoginMain}
          isAuthenticated={!!email}
          isRegister={true}
        />

        <PrivateRoute
          exact
          path="/onpremise"
          component={Resource}
          roles={['ROLE_administrator', 'ROLE_manager', 'ROLE_operator']}
        />
        <PrivateRoute
          exact
          path="/cloud"
          component={Resource}
          roles={['ROLE_administrator', 'ROLE_manager', 'ROLE_operator']}
        />
        <PrivateRoute
          exact
          path="/projects"
          component={Project}
          roles={['ROLE_administrator', 'ROLE_manager']}
        />
        <PrivateRoute
          exact
          path="/accounts"
          component={Account}
          roles={['ROLE_administrator', 'ROLE_manager']}
        />
        <PrivateRoute exact path="/users" component={User} roles={['ROLE_administrator']} />
        <PrivateRoute
          exact
          path="/dashboard/date"
          component={Dashboards}
          roles={['ROLE_administrator', 'ROLE_manager', 'ROLE_operator']}
        />
        <PrivateRoute
          exact
          path="/dashboard/projects"
          component={Dashboards}
          roles={['ROLE_administrator', 'ROLE_manager', 'ROLE_operator']}
        />
        <Redirect to={loggedIn() ? ROUTE.DASHBOARD_BY_DATE : ROUTE.LOGIN} />
      </Switch>
    </Router>
  )
}
