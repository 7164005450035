import React from 'react'
import { Col, Row } from 'reactstrap'
import './global.scss'

export const Footer = () => {
  return (
    // <div className="footer logged">
    //   <Row>
    //     <Col md="6">
    //       <p className="text-left">Copyright IT SPEND</p>
    //     </Col>
    //     <Col md="6">
    //       <p>Powered by POSSUMUS KOIRON</p>
    //     </Col>
    //   </Row>
    // </div>

    <footer data-test="component-footer">
      <Row className="logged mr-0">
        <Col md="6">
          <p className="text-left">Copyright IT SPEND</p>
        </Col>
        <Col md="6">
          <p>Powered by POSSUMUS KOIRON</p>
        </Col>
      </Row>
    </footer>
  )
}

export default Footer
