import React, { useEffect, useState } from 'react'
import { Col, Row, NavbarText, Navbar } from 'reactstrap'
import { Button, FormGroup, Checkbox } from 'carbon-components-react'

import './Account.scss'
import { Sidebar } from '../global/sidebar'
import { Add16 } from '@carbon/icons-react'
import { ListAccount } from './ListAccount'
import {
  allAccountStartLoading,
  activeAccountStartLoading,
  accountStartActivate,
  accountStartDeactivate
} from '../../actions/account'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../reducers/rootReducer'
import { AccountModal } from './accountModal/accountModal'
import { Footer } from '../global/footer'

export const Account = () => {
  const dispatch = useDispatch()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [account, setAccount] = useState()
  const [getAll, setGetAll] = useState(false)

  const companyId = localStorage.getItem('company_id')

  useEffect(() => {
    dispatch(activeAccountStartLoading())
  }, [dispatch])

  const { accounts }: any = useSelector((store: RootState) => store.accounts)

  const toggle = (accountItem?) => {
    setIsOpenModal(!isOpenModal)
    setAccount(accountItem ? accountItem : null)
  }

  const getAllAccounts = (getAllItems) => {
    if (getAllItems) {
      dispatch(allAccountStartLoading())
    } else {
      dispatch(activeAccountStartLoading())
    }
    setGetAll(getAllItems)
  }

  const activeAccount = (id) => {
    dispatch(accountStartActivate(id))
  }

  const deactiveAccount = (id) => {
    dispatch(accountStartDeactivate(id, getAll))
  }

  return (
    <div data-test="component-account">
      <Sidebar />
      <div className="container mb-5">
        <Row>
          <Col className="border-col-right">
            <div className="list">
              <Navbar expand="md">
                <Row>
                  <Col className="center-div pl-0" md="2">
                    <NavbarText>
                      <h2>Mis cuentas</h2>
                    </NavbarText>
                  </Col>
                  <Col md="6"></Col>

                  <Col className="center-div pl-1" md="2">
                    <Button
                      renderIcon={Add16}
                      iconDescription="Icon Description"
                      onClick={() => toggle()}
                      className="add-btn"
                    >
                      Añadir cuenta
                    </Button>
                  </Col>
                  <Col md="2">
                    <FormGroup legendText={false} className="mt-3 mb-0">
                      <Checkbox
                        onChange={(e) => getAllAccounts(e)}
                        labelText="Mostrar desactivados"
                        id="getAll"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Navbar>
              <div className="margin-list account-list">
                <ListAccount
                  accounts={accounts}
                  toggle={toggle}
                  activeAccount={activeAccount}
                  deactiveAccount={deactiveAccount}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <AccountModal isOpenModal={isOpenModal} toggle={toggle} account={account}></AccountModal>
      <Footer />
    </div>
  )
}
