import { useSelector } from 'react-redux'
import { RootState } from '../reducers/rootReducer'

const UseStoreFilters = () => {
  const accounts = useSelector((store: RootState) => store.accounts?.accounts)
  const currency = useSelector((store: RootState) => store.currency?.currency)
  const projects = useSelector((store: RootState) => store.projects?.projects)
  const categories = useSelector((store: RootState) => store.resources?.resourceCategories)
  const checkFiltersReady = () =>
    accounts?.length && currency?.length && projects?.length && categories?.length

  return { accounts, currency, projects, categories, checkFiltersReady }
}

export { UseStoreFilters }
