import { types } from '../types/types'
import { fetchWithToken } from '../helpers/fetch'

export const currencyStartLoading = (companyId) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`currency/${companyId}`, {}, 'GET')
      const body = await resp.json()
      dispatch(currencyLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

const currencyLoaded = (body) => {
  return {
    type: types.currencyLoaded,
    payload: body
  }
}

export { currencyLoaded }
