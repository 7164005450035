import { api } from './api'

/* Dashboard By Date */
export const getUnassignedExpenses = async (payload): Promise<any> => {
  const { data } = await api.dashboardByDate.getUnassignedExpenses(payload)
  return data
}

export const getProjectExpenses = async (payload): Promise<any> => {
  const { data } = await api.dashboardByDate.getProjectExpenses(payload)
  return data
}

export const getExpensesAcocunt = async (payload): Promise<any> => {
  const { data } = await api.dashboardByDate.getExpensesAcocunt(payload)
  return data
}

export const getExpensesCategories = async (payload): Promise<any> => {
  const { data } = await api.dashboardByDate.getExpensesCategories(payload)
  return data
}

export const getExpensesAndPercentilProjects = async (payload): Promise<any> => {
  const { data } = await api.dashboardByDate.getExpensesAndPercentilProjects(payload)
  return data
}

export const getTotalAmountsByDate = async (payload): Promise<any> => {
  const { data } = await api.dashboardByDate.getTotalAmounts(payload)
  return data
}

//Dashboard by Projects
export const getTotalAmountsByProject = async (payload): Promise<any> => {
  const { data } = await api.dashboardByProject.getTotalAmounts(payload)
  return data
}

export const getPeriodsTotal = async (payload): Promise<any> => {
  const { data } = await api.dashboardByProject.getPeriodsTotal(payload)
  return data
}

export const getProjectAccounts = async (payload): Promise<any> => {
  const { data } = await api.dashboardByProject.getProjectAccounts(payload)
  return data
}

export const getProjectCategories = async (payload): Promise<any> => {
  const { data } = await api.dashboardByProject.getProjectCategories(payload)
  return data
}

export const getProjectResourceTypes = async (payload): Promise<any> => {
  const { data } = await api.dashboardByProject.getProjectResourceTypes(payload)
  return data
}

export const getProjectInfrastructureTypes = async (payload): Promise<any> => {
  const { data } = await api.dashboardByProject.getProjectInfrastructureTypes(payload)
  return data
}

export const getAssignedExpenses = async (payload): Promise<any> => {
  const { data } = await api.dashboardByProject.getAssignedExpenses(payload)
  return data
}
