import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import { LineChart } from '@carbon/charts-react'
import '@carbon/charts/styles.css'
import { TimeToggle } from '../../atoms/TimeToggle'
import { COLOR } from '../../../utils/constants/styles'

export const LineCharts = ({ data, setPerDay, perDay }) => {
  const [reportOne, setReportOne] = useState([] as any)
  const colors = [COLOR.IT_SPEND_GREY, COLOR.IT_SPEND_BLUE]
  const [optionsReport, setOptionsReport] = useState({
    title: 'Gastos por proyectos en un rango de períodos',
    axes: {
      bottom: {
        title: 'Rango de fechas seleccionadas',
        mapsTo: 'date',
        scaleType: 'time' as any
      },
      left: {
        mapsTo: 'value',
        title: 'Dinero invertido en el proyecto',
        scaleType: 'linear' as any
      }
    },
    color: {},
    timeScale: {
      addSpaceOnEdges: 0
    },
    points: {
      radius: 2
    },
    tooltip: {
      groupLabel: 'Proyecto'
    },
    height: '400px'
  } as any)

  useEffect(() => {
    const obj = data.reduce((o, key) => ({ ...o, [key.group]: key.color }), {})
    setOptionsReport({
      ...optionsReport,
      color: {
        scale: obj
      }
    })
    setReportOne(data)
  }, [data])

  return (
    <Row data-test="component-line-charts" className="mb-5">
      <Col md="12">
        <TimeToggle colors={colors} perDay={perDay} setPerDay={setPerDay} />
        <LineChart data={reportOne} options={optionsReport} />
      </Col>
    </Row>
  )
}
