import React from 'react'

export const ResourceReview = ({ formData, statePeriodicityName, account }) => {
  const { amount, category, alias, name, description, cost_type } = formData

  return (
    <div data-test="component-resource-review" className="mb-4">
      <h5 className="mt-0">Resumen</h5>
      <p className="title">Monto: {'$' + amount}</p>
      <p className="title">Categoría: {category.name}</p>
      <p className="title">Título: {alias ? alias : name}</p>
      {description && (
        <div>
          <p className="title">Descripción: {description}</p>
        </div>
      )}
      <p className="title">
        Tipo de gasto:
        {cost_type === 'RECURRENT' ? 'Recurrente' : cost_type === 'NOT RECURRENT' && 'Único'}
      </p>
      {cost_type === 'RECURRENT' && account.type === 'ONPREMISE' && (
        <p className="title">Periodicidad: {statePeriodicityName}</p>
      )}
    </div>
  )
}
