import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { TrashCan32, RequestQuote32 } from '@carbon/icons-react'
import { DeleteModal } from '../global/confirmationDeleteModal/confirmationDeleteModal'
import { useDispatch } from 'react-redux'
import { accountStartDeleting } from '../../actions/account'
import ActiveIcon from '../../assets/img/icon-active-itspend.svg'
import InactiveIcon from '../../assets/img/icon-active-itspend-grey.svg'

export const ListAccount = ({ accounts, toggle, activeAccount, deactiveAccount }) => {
  const dispatch = useDispatch()
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [idDelete, setIdDelete] = useState(0)

  const toggleDelete = (id) => {
    if (id) {
      setIdDelete(id)
    }
    setIsOpenModalDelete(!isOpenModalDelete)
  }

  useEffect(() => {
    if (confirmDelete) {
      dispatch(accountStartDeleting(idDelete))
    }
    setConfirmDelete(false)
  }, [confirmDelete])
  return (
    <div data-test="component-list-account">
      <Row>
        <Col md="2">
          <p className="table-title">Nombre</p>
        </Col>
        <Col md="8">
          <p className="table-title">Tipo</p>
        </Col>

        <Col md="2">
          <p className="table-title">Acciones</p>
        </Col>
      </Row>

      {accounts &&
        accounts.map((account, i) => (
          <div key={i}>
            <Row className={`border-row ${account.is_active === 0 && 'disabled-color'}`}>
              <Col className="center-div" md="2">
                <p>{account.name}</p>
              </Col>
              <Col md="8">
                <p>{account.type}</p>
              </Col>
              <Col className="center-div icon-action" md="2">
                <RequestQuote32 onClick={() => toggle(account)} />

                {account.is_active === 1 ? (
                  <img
                    src={ActiveIcon}
                    alt="ActiveIcon"
                    onClick={() => deactiveAccount(account.id)}
                  />
                ) : (
                  <img
                    src={InactiveIcon}
                    alt="InactiveIcon"
                    onClick={() => activeAccount(account.id)}
                  />
                )}

                <TrashCan32 className="trash" onClick={() => toggleDelete(account.id)} />
              </Col>
            </Row>
          </div>
        ))}
      <DeleteModal
        isOpenModalDelete={isOpenModalDelete}
        toggleDelete={toggleDelete}
        text={'esta cuenta'}
        setConfirmDelete={setConfirmDelete}
      ></DeleteModal>
    </div>
  )
}
