import moment from 'moment'
import { DEFAULT_PERIOD_EXTENSION } from '../common/constants'

export const getIdsListed = (body: any): string[] => {
  const idsArray: string[] = []

  body?.forEach((item) => idsArray.push(item.id))

  return idsArray
}

export const getCurrencyData = (body: any): string[] => {
  const correnciesArray: string[] = []
  body?.forEach((item) => correnciesArray.push(item.currency))
  return correnciesArray
}

export const filtersAvailable = (filters) => {
  if (filters)
    if (
      filters?.companyId?.length &&
      filters?.currency?.length &&
      filters?.projects?.length &&
      filters?.categories?.length &&
      filters?.accounts?.length
    ) {
      return true
    }
  return false
}

export const singleProjectSelected = (filters) => {
  if (filters)
    if (filters?.projects?.length === 1) {
      return true
    }
  return false
}

export const getDateDefaultFilters = () => {
  const today = new Date()
  const endDate = today.toISOString().substring(0, 8) + '01'
  const startDate = moment(today).subtract(5, 'months').toISOString().substring(0, 8) + '01'
  return { startDate, endDate }
}

export const formatDateForDispatch = (date) => {
  return date.toISOString().substring(0, 8) + '01'
}

export const randomId = () => {
  return Math.floor((1 + Math.random()) * 0x100000000)
    .toString(16)
    .substring(1)
}
