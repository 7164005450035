import { types } from '../types/types'
import { fetchWithToken } from '../helpers/fetch'
import Swal from 'sweetalert2'
import correct from '../assets/img/checkmark--outline.svg'

export const projectStartLoading = (from, to) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`projects?from=${from}&to=${to}`, {}, 'GET')
      const body = await resp.json()
      dispatch(projectLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const allProjectStartLoading = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`projects?isActive=all`, {}, 'GET')
      const body = await resp.json()
      dispatch(projectLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const activeProjectStartLoading = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken('projects', {}, 'GET')
      const body = await resp.json()
      dispatch(projectLoaded(body))
    } catch (error) {
      console.log(error)
    }
  }
}

export const projectStartAddNew = (formData) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken('projects', formData, 'POST')
      const body = await resp.json()
      if (resp.status === 201) {
        await dispatch(projectAddNew(body))
        Swal.fire({
          imageUrl: correct,
          title: 'Su proyecto ha sido añadido con éxito!',
          customClass: {
            image: 'image-success image-size'
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const projectStartUpdating = (id, formData) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`projects/${id}`, formData, 'PUT')
      const body = await resp.json()
      if (resp.status === 200) {
        await dispatch(projectUpdated(body))
        Swal.fire({
          imageUrl: correct,
          title: 'Su proyecto ha sido actualizado con éxito!',
          customClass: {
            image: 'image-success image-size'
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const projectStartDeactivate = (id, getAll?) => {
  return async (dispatch) => {
    try {
      const data: IData = {}
      if (id) Object.assign(data, { id })
      const resp = await fetchWithToken(`projects/${id}/deactivate`, data, 'PUT')
      const body = await resp.json()
      if (getAll) {
        await dispatch(projectUpdated(body))
      } else {
        await dispatch(projectDeleted(body))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const projectStartDeleting = (id) => {
  return async (dispatch) => {
    try {
      const data: IData = {}
      if (id) Object.assign(data, { id })
      const resp = await fetchWithToken(`projects/${id}`, data, 'DELETE')
      const body = await resp.json()

      if (resp.status === 200) {
        await dispatch(projectDeleted(body))
        Swal.fire({
          imageUrl: correct,
          title: 'Su proyecto ha sido eliminado con éxito!',
          customClass: {
            image: 'image-success image-size'
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const projectStartActivate = (id) => {
  return async (dispatch) => {
    try {
      const data: IData = {}
      if (id) Object.assign(data, { id })
      const resp = await fetchWithToken(`projects/${id}/activate`, data, 'PUT')
      const body = await resp.json()
      await dispatch(projectUpdated(body))
    } catch (error) {
      console.log(error)
    }
  }
}

const projectLoaded = (projects) => {
  if (projects.length) {
    return { type: types.projectLoaded, payload: projects }
  }
  return { type: types.noProject, payload: [] }
}

const projectAddNew = (body) => ({
  type: types.projectAddNew,
  payload: body
})

const projectUpdated = (body) => ({ type: types.projectUpdated, payload: body })

const projectDeleted = (body) => ({ type: types.projectDeleted, payload: body })

interface IData {
  id?: number | string
}

export { projectLoaded, projectAddNew, projectUpdated, projectDeleted }
