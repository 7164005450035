import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import { OnPremise } from './onpremise/onPremise'
import { OnCloud } from './oncloud/onCloud'
import { RootState } from '../../../reducers/rootReducer'
import { useSelector } from 'react-redux'

export const ResourceModal = ({ isOpenModal, toggle, service, account, fromDrag }) => {
  const { resourceCategories }: any = useSelector((store: RootState) => store.resources)

  return (
    <Modal data-test="component-resource-modal" isOpen={isOpenModal} size="lg">
      <ModalHeader toggle={toggle}></ModalHeader>
      {account.type === 'ONPREMISE' ? (
        <OnPremise
          toggle={toggle}
          service={service}
          categories={resourceCategories}
          fromDrag={fromDrag}
        ></OnPremise>
      ) : (
        <OnCloud
          toggle={toggle}
          service={service}
          categories={resourceCategories}
          fromDrag={fromDrag}
        ></OnCloud>
      )}
    </Modal>
  )
}
