import React, { useEffect, useState } from 'react'
import { Col } from 'reactstrap'
import { MeterChart } from '@carbon/charts-react'
import '@carbon/charts/styles.css'

export const MeterCharts = ({ data }) => {
  const [reportFour, setReportFour] = useState([
    {
      group: 'CLOUD',
      value: 0
    }
  ] as any)
  const [reportFive, setReportFive] = useState([
    {
      group: 'ONPREMISE',
      value: 0
    }
  ] as any)

  const [generalOptions] = useState({
    title: '',
    meter: {
      peak: 0
    },
    height: '100px',
    color: {}
  })

  const [optionsReportFour, setOptionsReportFour] = useState({
    ...generalOptions
  })

  const [optionsReportFive, setOptionsReportFive] = useState({
    ...generalOptions
  })

  useEffect(() => {
    if (data.length > 0) {
      setReportFour([data[1]])
      setReportFive([data[0]])
      setOptionsReportFour({
        ...generalOptions,
        color: {
          scale: { CLOUD: '#1166f8' }
        }
      } as any)
      setOptionsReportFive({
        ...generalOptions,
        color: {
          scale: { ONPREMISE: '#6929c4' }
        }
      } as any)
    }
  }, [data])

  return (
    <Col data-test="component-meter-charts" md="6">
      <div className="bx--chart-holder">
        <h5>Historial de gastos segmentado por tipo de infraestructura</h5>
        <MeterChart data={reportFour} options={optionsReportFour}></MeterChart>
        <MeterChart data={reportFive} options={optionsReportFive}></MeterChart>
      </div>
    </Col>
  )
}
