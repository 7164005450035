import React, { useEffect } from 'react'
import { useForm, useStep } from 'react-hooks-helper'
import { OnPremisePeriod } from './onPremisePeriod'
import '../resourceModal.scss'
import { useDispatch, useSelector } from 'react-redux'
import { onPremiseStartAddNew } from '../../../../actions/onPremise'
import { resourceStartUpdating } from '../../../../actions/resource'
import { RootState } from '../../../../reducers/rootReducer'

import { ResourceProject } from '../resourceProject'
import { ResourceType } from '../resourceType'
import moment from 'moment'

const steps = [
  {
    id: 'typeQuantity'
  },
  {
    id: 'period'
  },
  {
    id: 'project'
  }
]

export const OnPremise = ({ toggle, service, categories, fromDrag }) => {
  const dispatch = useDispatch()
  const { dates, account }: any = useSelector((store: RootState) => store.resources)
  const [statePeriodicityName, setStatePeriodicityName] = React.useState([] as any)
  const title = service.id ? 'Editar gasto On Premise' : 'Añadir gasto On Premise'
  const defaultData = {
    account_id: account.id,
    alias: '',
    amount: 0,
    category: '',
    cost_type:
      moment(dates.from).format('YYYY-MM-01') !== moment().format('YYYY-MM-01')
        ? 'NOT RECURRENT'
        : '',
    description: '',
    name: '',
    // period_cost: '',
    // periodicity: '',
    projects: '',
    period: dates.from,
    currency: 'USD'
  }

  useEffect(() => {
    if (service.id) {
      Object.entries(service).forEach(([key, value]) => {
        setForm({
          target: {
            name: key,
            value: value
          }
        })
      })

      const sixMonths =
        service.frecuency_number === 6
          ? 'Semestral'
          : service.frecuency_number === 1 && service.frecuency_string === 'years' && 'Anual'
      const threeMonths = service.frecuency_number === 3 ? 'Trimestral' : sixMonths
      const actualState =
        service.frecuency_number === 1 && service.frecuency_string === 'months'
          ? 'Mensual'
          : threeMonths

      setStatePeriodicityName(actualState)
    }
  }, [service])

  const [formData, setForm] = useForm(defaultData)

  const toStep = fromDrag === true ? 2 : 0
  const { step, navigation } = useStep({
    initialStep: service ? toStep : 0,
    steps
  })
  const { id } = step

  const onSubmitForm = () => {
    if (formData.id) {
      dispatch(resourceStartUpdating(formData, dates.from, dates.to))
      delete formData['period']
    } else {
      dispatch(onPremiseStartAddNew(formData, dates.from, dates.to))
    }
    toggle()
  }
  const props = {
    formData,
    setForm,
    navigation,
    toggle,
    onSubmitForm,
    type: 'onPremise',
    title
  }

  switch (id) {
    case 'typeQuantity':
      return <ResourceType {...props} categories={categories} />
    case 'period':
      return (
        <OnPremisePeriod
          {...props}
          statePeriodicityName={statePeriodicityName}
          setStatePeriodicityName={setStatePeriodicityName}
        />
      )
    case 'project':
      return (
        <ResourceProject {...props} statePeriodicityName={statePeriodicityName} account={account} />
      )
    default:
      return null
  }
}
