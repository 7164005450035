import { fetchWithoutToken } from '../../../helpers/fetch'
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'
import sendEmail from '../../../assets/img/send-email.svg'
import errorImg from '../../../assets/img/warning.svg'
import correct from '../../../assets/img/checkmark--outline.svg'
import { login } from 'actions'
import { redirectLogin } from 'actions/auth'
import { redirect } from 'utils/Functions'
import { signIn } from 'services'
import { setBrowserData } from 'utils/Auth'
import { ROUTE } from 'common/constants'

const cookies = new Cookies()

const authenticateUser = (loginData) => {
  return signIn(loginData)
    .then((body) => setBrowserData(body))
    .then(() => window.location.assign(ROUTE.REPORT_BY_DATE))
}

const startLogin = (data) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithoutToken('auth/signin', data, 'POST')
      const body = await resp.json()

      if (resp.status === 200) {
        console.log(resp)
        cookies.set('access_token', body.access_token)
        cookies.set('renew_token', body.renew_token)
        localStorage.setItem('language', body.language)
        localStorage.setItem('user_name', body.username)
        localStorage.setItem('company_name', body.company_name)
        localStorage.setItem('email', body.email)
        localStorage.setItem('company_id', body.company_id)
        localStorage.setItem('rol', body.role)

        dispatch(login(body))
      }
    } catch (error) {
      return error
      // console.log(error)
    }
  }
}

const startSignUp = (data) => {
  return async () => {
    try {
      const resp = await fetchWithoutToken('auth/signup', data, 'POST')
      // const body = await resp.json()
      if (resp.status === 200) {
        Swal.fire({
          imageUrl: correct,
          title: 'Usuario agregado correctamente.',
          text: 'Se redireccionará en un momento.',
          customClass: {
            image: 'image-success image-size'
          }
        }).then(() => {
          redirectLogin()
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const startChecking = () => {
  const email = localStorage.getItem('email')

  return async (dispatch) => {
    try {
      const resp = await fetchWithoutToken(
        'auth/check',
        { email: email, access_token: cookies.get('access_token') },
        'POST'
      )
      const status = resp.status
      if (status === 401) {
        dispatch(startRenew())
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const startRenew = () => {
  const email = localStorage.getItem('email')

  return async () => {
    try {
      const resp = await fetchWithoutToken(
        'auth/renew',
        {
          email: email,
          access_token: cookies.get('access_token'),
          renew_token: cookies.get('renew_token')
        },
        'POST'
      )
      const body = await resp.json()
      const status = resp.status
      if (status === 401) {
        localStorage.clear()
        cookies.remove('access_token')
        cookies.remove('renew_token')
        redirect('/login')
      } else {
        cookies.set('access_token', body.access_token)
        redirect('/dashboard/date')
      }
    } catch (error) {
      console.log(error)
    }
  }
}
const startLogout = () => {
  return () => {
    Swal.fire({
      imageUrl: errorImg,
      title: '¿Estás seguro que quieres salir?',
      customClass: {
        image: 'image-error'
      },
      confirmButtonText: 'Si, quiero salir',
      footer: '<a href="/dashboard/date">Volver al Inicio</a>',
      preConfirm: () => {
        localStorage.clear()
        cookies.remove('access_token')
        cookies.remove('renew_token')
        redirect('/login')
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }
}

const startPasswordRecovery = (data) => {
  return async () => {
    try {
      const resp = await fetchWithoutToken('auth/password-recovery', data, 'POST')
      // const body = await resp.json()
      if (resp.status === 200) {
        Swal.fire({
          imageUrl: sendEmail,
          title: 'Email enviado con éxito!',
          text: 'Revisa tu bandeja de entrada.',
          customClass: {
            image: 'image-success'
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const startPasswordReset = (url, data) => {
  return async () => {
    try {
      const resp = await fetchWithoutToken(`auth/password-reset${url}`, data, 'PUT')
      // const body = await resp.json()
      if (resp.status === 200) {
        Swal.fire({
          imageUrl: correct,
          title: 'Contraseña actualizada correctamente.',
          text: 'Se redireccionará en un momento.',
          customClass: {
            image: 'image-success image-size'
          }
        }).then(() => {
          redirectLogin()
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export {
  authenticateUser,
  startLogin,
  startSignUp,
  startChecking,
  startRenew,
  startLogout,
  startPasswordRecovery,
  startPasswordReset
}
