import React from 'react'
import { DayMonthToggleable } from '../../components/atoms/Icons'

const TimeToggle = ({ colors, perDay, setPerDay }) => {
  return (
    <div
      style={{
        background: '#ffff',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '80%',
        marginTop: '10px',
        borderRadius: '10px',
        padding: '10px',
        zIndex: 2,
        position: 'absolute'
      }}
    >
      <p
        style={{
          paddingRight: '5px',
          font: 'inherit',
          fontWeight: perDay ? 10 : 'bold',
          color: perDay ? colors[0] : colors[1]
        }}
      >
        MES
      </p>
      <DayMonthToggleable onClickFunction={() => setPerDay(!perDay)} />
      <p
        style={{
          paddingLeft: '5px',
          font: 'inherit',
          fontWeight: perDay ? 'bold' : 10,
          color: perDay ? colors[1] : colors[0]
        }}
      >
        DIA
      </p>
    </div>
  )
}

export { TimeToggle }
