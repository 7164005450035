import Cookies from 'universal-cookie'

const cookies = new Cookies()
const baseUrl = process.env.REACT_APP_API_URL

const fetchWithoutToken = (endpoint, data, method = 'GET') => {
  const url = baseUrl ? `${baseUrl}/${endpoint}` : `${endpoint}`

  return fetch(url, {
    method,
    headers: {
      'Content-type': 'application/json',
      'accept-language': 'es_ES'
    },
    body: JSON.stringify(data)
  })
}

const fetchWithToken = (endpoint, data, method = 'GET') => {
  const url = baseUrl ? `${baseUrl}/${endpoint}` : `${endpoint}`
  const token = cookies.get('access_token')

  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        'x-access-token': token,
        'accept-language': 'es_ES'
      }
    })
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-type': 'application/json',
        'x-access-token': token,
        'accept-language': 'es_ES'
      },
      body: JSON.stringify(data)
    })
  }
}

const fecthFileWithToken = (endpoint, fileData, data, method = 'GET') => {
  const formData = new FormData()
  Object.entries(data).forEach(([key]) => {
    formData.append(key, data[key])
  })
  if (fileData && fileData.name) {
    formData.append('google_credential', fileData)
  }
  const url = `${baseUrl}/${endpoint}`
  const token = cookies.get('access_token')
  return fetch(url, {
    headers: {
      Accept: 'application/json',
      'x-access-token': token,
      'accept-language': 'es_ES'
    },
    method,
    body: formData
  })
}

export { fetchWithoutToken, fetchWithToken, fecthFileWithToken }
